import gql from "graphql-tag";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      expires_in
      email
      role
      id
    }
  }
`;

export const GET_ALL_STORIES = gql`
  query getAllStory($offset: Int, $limit: Int, $search: String) {
    userStory(
      where: {
        story: {
          _or: [
            { name: { _ilike: $search } }
            { description: { _ilike: $search } }
            {
              storyCharacters: {
                character: {
                  _or: [
                    { firstname: { _ilike: $search } }
                    { lastname: { _ilike: $search } }
                    { description: { _ilike: $search } }
                  ]
                }
              }
            }
            {
              storyItems: {
                item: {
                  _or: [
                    { name: { _ilike: $search } }
                    { description: { _ilike: $search } }
                  ]
                }
              }
            }
            {
              storyEvents: {
                event: {
                  _or: [
                    { name: { _ilike: $search } }
                    { description: { _ilike: $search } }
                  ]
                }
              }
            }
            {
              storyLocations: {
                location: {
                  _or: [
                    { name: { _ilike: $search } }
                    { description: { _ilike: $search } }
                  ]
                }
              }
            }
          ]
          isExpiry: { _eq: false }
        }
      }
      offset: $offset
      limit: $limit
      order_by: { story: { updated_at: desc } }
    ) {
      story {
        id
        description
        name
        updated_at
        logo
        isExpiry
      }
    }
    userStory_aggregate(
      where: {
        story: {
          _or: [
            { name: { _ilike: $search } }
            { description: { _ilike: $search } }
            {
              storyCharacters: {
                character: {
                  _or: [
                    { firstname: { _ilike: $search } }
                    { lastname: { _ilike: $search } }
                    { description: { _ilike: $search } }
                  ]
                }
              }
            }
            {
              storyItems: {
                item: {
                  _or: [
                    { name: { _ilike: $search } }
                    { description: { _ilike: $search } }
                  ]
                }
              }
            }
            {
              storyEvents: {
                event: {
                  _or: [
                    { name: { _ilike: $search } }
                    { description: { _ilike: $search } }
                  ]
                }
              }
            }
            {
              storyLocations: {
                location: {
                  _or: [
                    { name: { _ilike: $search } }
                    { description: { _ilike: $search } }
                  ]
                }
              }
            }
          ]
          isExpiry: { _eq: false }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    totalStories: userStory_aggregate(
      where: { story: { isExpiry: { _eq: false } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_STORIES_COUNT = gql`
  query userStory_aggregate {
    userStory_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_STORIES_ENTITIES_COUNTS = gql`
  query StoryEntitiesCount($storyId: [Int!]) {
    storyCharacters_aggregate(
      where: { storyId: { _in: $storyId }, story: {} }
    ) {
      characters: aggregate {
        count(columns: characterId)
      }
    }
    storyEvents_aggregate(where: { storyId: { _in: $storyId } }) {
      events: aggregate {
        count(columns: eventId)
      }
    }
    storyItems_aggregate(where: { storyId: { _in: $storyId } }) {
      items: aggregate {
        count(columns: itemId)
      }
    }
    storyLocations_aggregate(where: { storyId: { _in: $storyId } }) {
      locations: aggregate {
        count(columns: locationId)
      }
    }
  }
`;

export const GET_EACH_STORIES_ENTITIES_COUNTS = gql`
  query getEachStoryCount {
    userStory {
      story {
        id
        storyCharacters_aggregate {
          characters: aggregate {
            count(columns: characterId)
          }
        }
        storyEvents_aggregate {
          events: aggregate {
            count(columns: eventId)
          }
        }
        storyItems_aggregate {
          items: aggregate {
            count(columns: itemId)
          }
        }
        storyLocations_aggregate {
          locations: aggregate {
            count(columns: locationId)
          }
        }
      }
    }
  }
`;

export const GET_ALL_HISTORIES = gql`
  query getAllHistory(
    $offset: Int
    $limit: Int
    $entity_type: String
    $id: Int
  ) {
    history(
      offset: $offset
      limit: $limit
      where: { entity_id: { _eq: $id }, entity_type: { _eq: $entity_type } }
      order_by: { created_at: desc }
    ) {
      description
      firstname
      id
      lastname
      name
      updated_at
      logo
    }
    history_aggregate(
      where: { entity_type: { _eq: $entity_type }, entity_id: { _eq: $id } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_TIMELINES = gql`
  query getAllTimeline($id: Int!) {
    story(where: { id: { _eq: $id } }) {
      name
      id
      storyTimelines(
        order_by: { timeline: { from_year: desc, from_month: asc } }
      ) {
        timeline {
          to_year
          to_month
          to_day
          to_time
          from_year
          from_month
          from_day
          from_time
          id
          associations {
            character {
              firstname
              lastname
              id
            }
            event {
              name
              id
            }
            item {
              name
              id
            }
            location {
              name
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getUserDetails {
    users {
      avatarUrl
      createdAt
      displayName
      id
      email
      userSubscription {
        subscriptionId
        isCancel
      }
      userProviders {
        providerId
      }
    }
  }
`;

export const GET_STORY = gql`
  query getStory($id: Int!) {
    story(where: { id: { _eq: $id } }) {
      name
      description
      logo
      id
      storyCharacters {
        character {
          description
          firstname
          lastname
          id
          logo
        }
      }
      storyEvents {
        event {
          description
          id
          name
          logo
        }
      }
      storyItems {
        item {
          id
          description
          name
          logo
        }
      }
      storyLocations {
        location {
          id
          description
          name
          logo
        }
      }
      storyTimelines {
        timeline {
          from_day
          from_month
          from_time
          from_year
          id
          to_day
          to_month
          to_time
          to_year
        }
      }
    }
  }
`;

export const GET_STORY_COMPLETE_VIEW = gql`
  query getStoryDetail($id: Int!) {
    story(where: { id: { _eq: $id } }) {
      name
      description
      storyCharacters {
        character {
          description
          firstname
          lastname
          logo
          associations(where: { characterId: { _is_null: false } }) {
            event {
              name
            }
            location {
              name
            }
            item {
              name
            }
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
            }
          }
        }
      }
      storyEvents {
        event {
          description
          name
          logo
        }
      }
      storyItems {
        item {
          description
          name
          logo
        }
      }
      storyLocations {
        location {
          description
          name
          logo
        }
      }
    }
  }
`;

export const DELETE_STORY = gql`
  mutation delete_story_by_pk($id: Int!) {
    delete_story_by_pk(id: $id) {
      name
      id
    }
  }
`;

export const DELETE_HISTORY = gql`
  mutation delete_history_by_pk($id: uuid!) {
    delete_history_by_pk(id: $id) {
      name
    }
  }
`;

export const GET_STORY_ENTITIES = gql`
  query getStoryEntities($id: Int!) {
    story(where: { id: { _eq: $id } }) {
      storyCharacters {
        character {
          firstname
          lastname
          id
          logo
        }
      }
      storyEvents {
        event {
          id
          name
          logo
        }
      }
      storyItems {
        item {
          id
          name
          logo
        }
      }
      storyLocations {
        location {
          id
          name
          logo
        }
      }
      storyTimelines {
        timeline {
          to_year
          to_month
          to_day
          to_time
          from_year
          from_month
          from_day
          from_time
          id
        }
      }
    }
  }
`;

export const GET_LOCATION_DETAIL = gql`
  query getLocation($id: Int!) {
    location(where: { id: { _eq: $id } }) {
      name
      description
      logo
      id
      associations {
        event {
          name
          description
          id
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
        character {
          id
          description
          firstname
          lastname
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
        item {
          id
          name
          description
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
            event {
              name
            }
            character {
              firstname
              lastname
            }
          }
        }
        timeline {
          to_year
          to_month
          to_day
          to_time
          from_year
          from_month
          from_day
          from_time
          type
          id
        }
      }
    }
  }
`;

export const GET_CHARACTER_DETAIL = gql`
  query getCharacter($id: Int!) {
    character(where: { id: { _eq: $id } }) {
      firstname
      lastname
      logo
      description
      id
      associations {
        location {
          name
          description
          id
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
        event {
          id
          description
          name
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
        timeline {
          to_year
          to_month
          to_day
          to_time
          from_year
          from_month
          from_day
          from_time
          type
          id
        }
        item {
          id
          name
          description
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_EVENT_DETAIL = gql`
  query getEvent($id: Int!) {
    event(where: { id: { _eq: $id } }) {
      name
      description
      logo
      id
      associations {
        location {
          name
          description
          id
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
        timeline {
          to_year
          to_month
          to_day
          to_time
          from_year
          from_month
          from_day
          from_time
          type
          id
        }
        character {
          id
          description
          firstname
          lastname
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
        item {
          id
          name
          description
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_ITEM_DETAIL = gql`
  query getItem($id: Int!) {
    item(where: { id: { _eq: $id } }) {
      name
      description
      logo
      id
      associations {
        event {
          name
          description
          id
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
        character {
          id
          description
          firstname
          lastname
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
        timeline {
          to_year
          to_month
          to_day
          to_time
          from_year
          from_month
          from_day
          from_time
          type
          id
        }
        location {
          id
          name
          description
          logo
          associations(where: { timelineId: { _is_null: false } }) {
            timeline {
              to_year
              to_month
              to_day
              to_time
              from_year
              from_month
              from_day
              from_time
              type
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_TIMELINE_LIST = gql`
  query getTimelinesByStoryId($id: Int!) {
    story(where: { id: { _eq: $id } }) {
      storyTimeline {
        timeline {
          name
          id
        }
      }
    }
  }
`;

export const ADD_STORY = gql`
  mutation add_story($name: String, $description: String, $logo: uuid) {
    insert_story_one(
      object: {
        name: $name
        description: $description
        logo: $logo
        userStories: { data: {} }
      }
    ) {
      id
    }
  }
`;

export const ADD_HISTORY = gql`
  mutation add_history(
    $name: String
    $description: String
    $logo: uuid
    $entity_type: String
    $entity_id: Int!
    $firstname: String
    $lastname: String
  ) {
    insert_history_one(
      object: {
        name: $name
        entity_type: $entity_type
        logo: $logo
        description: $description
        entity_id: $entity_id
        firstname: $firstname
        lastname: $lastname
      }
    ) {
      id
    }
  }
`;

export const GET_STORY_DETAIL_BY_ID = gql`
  query story_by_pk($id: Int!) {
    story_by_pk(id: $id) {
      description
      name
      id
      logo
      updated_at
    }
  }
`;

export const UPDATE_STORY_HISTORY_BY_ID = gql`
  mutation update_story_history_by_pk(
    $id: Int!
    $name: String!
    $description: String
    $logo: uuid
    $entity_type: String
    $id1: uuid!
  ) {
    update_story_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, logo: $logo }
    ) {
      name
      id
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        name: $name
        entity_type: $entity_type
      }
    ) {
      id
    }
    delete_history_by_pk(id: $id1) {
      name
      id
    }
  }
`;

export const UPDATE_LOCATION_HISTORY_BY_ID = gql`
  mutation update_location_history_by_pk(
    $id: Int!
    $name: String!
    $description: String
    $logo: uuid
    $entity_type: String
    $id1: uuid!
  ) {
    update_location_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, logo: $logo }
    ) {
      name
      id
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        name: $name
        entity_type: $entity_type
      }
    ) {
      id
    }
    delete_history_by_pk(id: $id1) {
      name
    }
  }
`;

export const UPDATE_EVENT_HISTORY_BY_ID = gql`
  mutation update_event_history_by_pk(
    $id: Int!
    $name: String!
    $description: String
    $logo: uuid
    $entity_type: String
    $id1: uuid!
  ) {
    update_event_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, logo: $logo }
    ) {
      name
      id
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        name: $name
        entity_type: $entity_type
      }
    ) {
      id
    }
    delete_history_by_pk(id: $id1) {
      name
    }
  }
`;

export const UPDATE_ITEM_HISTORY_BY_ID = gql`
  mutation update_item_history_by_pk(
    $id: Int!
    $name: String!
    $description: String
    $logo: uuid
    $entity_type: String
    $id1: uuid!
  ) {
    update_item_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, logo: $logo }
    ) {
      name
      id
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        name: $name
        entity_type: $entity_type
      }
    ) {
      id
    }
    delete_history_by_pk(id: $id1) {
      name
    }
  }
`;

export const UPDATE_CHARACTER_HISTORY_BY_ID = gql`
  mutation update_character_history_by_pk(
    $id: Int!
    $firstname: String!
    $lastname: String
    $description: String
    $logo: uuid
    $entity_type: String
    $id1: uuid!
  ) {
    update_character_by_pk(
      pk_columns: { id: $id }
      _set: {
        firstname: $firstname
        lastname: $lastname
        description: $description
        logo: $logo
      }
    ) {
      firstname
      lastname
      id
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        firstname: $firstname
        lastname: $lastname
        entity_type: $entity_type
      }
    ) {
      id
    }
    delete_history_by_pk(id: $id1) {
      firstname
    }
  }
`;

export const UPDATE_STORY_DETAIL_BY_ID = gql`
  mutation update_story_detail_by_pk(
    $id: Int!
    $name: String!
    $description: String
    $logo: uuid
    $entity_type: String
  ) {
    update_story_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, logo: $logo }
    ) {
      name
      id
      logo
      description
      updated_at
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        name: $name
        entity_type: $entity_type
      }
    ) {
      id
    }
  }
`;

export const UPDATE_STORY_LOGO = gql`
  mutation update_story_logo($id: Int!, $logo: uuid) {
    update_story_by_pk(pk_columns: { id: $id }, _set: { logo: $logo }) {
      id
      logo
    }
  }
`;

export const REGISTER = gql`
  mutation register(
    $email: String!
    $firstname: String!
    $lastname: String
    $password: String!
  ) {
    register(
      user: {
        email: $email
        firstname: $firstname
        lastname: $lastname
        password: $password
      }
    ) {
      status
    }
  }
`;

export const GET_CHARACTER_DETAIL_BY_ID = gql`
  query character_detail_by_pk($id: Int!) {
    character_by_pk(id: $id) {
      description
      firstname
      lastname
      id
      logo
    }
  }
`;

export const GET_LOCATION_DETAIL_BY_ID = gql`
  query location_detail_by_pk($id: Int!) {
    location_by_pk(id: $id) {
      description
      name
      id
      logo
    }
  }
`;

export const GET_EVENT_DETAIL_BY_ID = gql`
  query event_detail_by_pk($id: Int!) {
    event_by_pk(id: $id) {
      description
      name
      id
      logo
    }
  }
`;

export const GET_ITEM_DETAIL_BY_ID = gql`
  query item_detail_by_pk($id: Int!) {
    item_by_pk(id: $id) {
      description
      name
      id
      logo
    }
  }
`;

export const ADD_CHARACTER = gql`
  mutation add_character(
    $firstname: String
    $lastname: String
    $description: String
    $logo: uuid
    $storyId: Int
    $locationId: Int
    $eventId: Int
    $itemId: Int
    $timelineId: Int
  ) {
    insert_character_one(
      object: {
        description: $description
        firstname: $firstname
        lastname: $lastname
        logo: $logo
        storyCharacters: { data: { storyId: $storyId } }
        associations: {
          data: {
            locationId: $locationId
            eventId: $eventId
            itemId: $itemId
            storyId: $storyId
            timelineId: $timelineId
          }
        }
      }
    ) {
      id
    }
  }
`;

export const ADD_EVENT = gql`
  mutation add_event(
    $name: String
    $description: String
    $storyId: Int!
    $locationId: Int
    $characterId: Int
    $itemId: Int
    $logo: uuid
    $timelineId: Int
  ) {
    insert_event_one(
      object: {
        description: $description
        name: $name
        logo: $logo
        storyEvents: { data: { storyId: $storyId } }
        associations: {
          data: {
            characterId: $characterId
            locationId: $locationId
            itemId: $itemId
            storyId: $storyId
            timelineId: $timelineId
          }
        }
      }
    ) {
      id
    }
  }
`;

export const ADD_ITEM = gql`
  mutation add_item(
    $name: String
    $description: String
    $storyId: Int!
    $locationId: Int
    $characterId: Int
    $eventId: Int
    $logo: uuid
    $timelineId: Int
  ) {
    insert_item_one(
      object: {
        description: $description
        name: $name
        logo: $logo
        storyItems: { data: { storyId: $storyId } }
        associations: {
          data: {
            characterId: $characterId
            eventId: $eventId
            locationId: $locationId
            storyId: $storyId
            timelineId: $timelineId
          }
        }
      }
    ) {
      id
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation add_location(
    $name: String
    $description: String
    $storyId: Int!
    $itemId: Int
    $characterId: Int
    $eventId: Int
    $logo: uuid
    $timelineId: Int
  ) {
    insert_location_one(
      object: {
        description: $description
        name: $name
        logo: $logo
        storyLocations: { data: { storyId: $storyId } }
        associations: {
          data: {
            characterId: $characterId
            eventId: $eventId
            itemId: $itemId
            storyId: $storyId
            timelineId: $timelineId
          }
        }
      }
    ) {
      id
    }
  }
`;

export const ADD_ENTITY_TIMELINE = gql`
  mutation add_timeline(
    $to_year: String
    $to_day: String
    $to_month: String
    $to_time: String
    $from_year: String
    $from_day: String
    $from_month: String
    $from_time: String
    $type: String
    $storyId: Int!
  ) {
    insert_timeline_one(
      object: {
        to_year: $to_year
        to_day: $to_day
        to_month: $to_month
        to_time: $to_time
        from_year: $from_year
        from_day: $from_day
        from_month: $from_month
        from_time: $from_time
        type: $type
        storyTimelines: { data: { storyId: $storyId } }
      }
    ) {
      id
    }
  }
`;

export const ADD_TIMELINE = gql`
  mutation add_timeline(
    $to_year: String
    $to_day: String
    $to_month: String
    $to_time: String
    $from_year: String
    $from_day: String
    $from_month: String
    $from_time: String
    $type: String
    $storyId: Int!
    $characterId: Int
    $eventId: Int
    $itemId: Int
    $locationId: Int
  ) {
    insert_timeline_one(
      object: {
        to_year: $to_year
        to_day: $to_day
        to_month: $to_month
        to_time: $to_time
        from_year: $from_year
        from_day: $from_day
        from_month: $from_month
        from_time: $from_time
        type: $type
        storyTimelines: { data: { storyId: $storyId } }
        associations: {
          data: {
            characterId: $characterId
            eventId: $eventId
            itemId: $itemId
            locationId: $locationId
            storyId: $storyId
          }
        }
      }
    ) {
      id
    }
  }
`;

export const ADD_ASSOCIATION = gql`
  mutation insert_association(
    $characterId: Int
    $locationId: Int
    $timelineId: Int
    $eventId: Int
    $itemId: Int
    $storyId: Int
  ) {
    insert_association(
      objects: {
        characterId: $characterId
        locationId: $locationId
        timelineId: $timelineId
        eventId: $eventId
        itemId: $itemId
        storyId: $storyId
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CHARACTER_DETAIL_BY_ID = gql`
  mutation update_character_detail_by_pk(
    $id: Int!
    $firstname: String!
    $description: String
    $lastname: String
    $logo: uuid
    $entity_type: String
  ) {
    update_character_by_pk(
      pk_columns: { id: $id }
      _set: {
        firstname: $firstname
        description: $description
        lastname: $lastname
        logo: $logo
      }
    ) {
      firstname
      id
      lastname
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        firstname: $firstname
        lastname: $lastname
        entity_type: $entity_type
      }
    ) {
      id
    }
  }
`;

export const UPDATE_LOCATION_DETAIL_BY_ID = gql`
  mutation update_location_detail_by_pk(
    $id: Int!
    $name: String!
    $description: String
    $logo: uuid
    $entity_type: String
  ) {
    update_location_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, logo: $logo }
    ) {
      name
      id
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        name: $name
        entity_type: $entity_type
      }
    ) {
      id
    }
  }
`;

export const UPDATE_EVENT_DETAIL_BY_ID = gql`
  mutation update_event_detail_by_pk(
    $id: Int!
    $name: String!
    $description: String
    $logo: uuid
    $entity_type: String
  ) {
    update_event_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, logo: $logo }
    ) {
      name
      id
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        name: $name
        entity_type: $entity_type
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ITEM_DETAIL_BY_ID = gql`
  mutation update_item_detail_by_pk(
    $id: Int!
    $name: String!
    $description: String
    $logo: uuid
    $entity_type: String
  ) {
    update_item_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, logo: $logo }
    ) {
      name
      id
      logo
      description
    }
    insert_history_one(
      object: {
        description: $description
        entity_id: $id
        logo: $logo
        name: $name
        entity_type: $entity_type
      }
    ) {
      id
    }
  }
`;

export const LOGINM = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      user {
        name
        userId
        profileImage
        address {
          lat
          lng
        }
        lastActiveTime
        slackUserId
        email
        phoneNumber
        publisher {
          title
        }
        isContractsPending
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation change_password(
    $email: String!
    $old_password: String!
    $new_password: String!
  ) {
    change_password(
      email: $email
      old_password: $old_password
      new_password: $new_password
    ) {
      status
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation send_reset_password_link($email: String!) {
    send_reset_password_link(email: $email) {
      status
      message
    }
  }
`;
export const FILE_UPLOADS = gql`
  mutation generateUrl($fileName: String!, $fileType: String!) {
    generateUrl(fileName: $fileName, fileType: $fileType)
  }
`;

export const RESET_PASSWORD = gql`
  mutation reset_password($token: String!, $new_password: String!) {
    reset_password(new_password: $new_password, token: $token) {
      status
      message
    }
  }
`;

export const DELETE_STORY_CHARACTER_ASSOCIATION = gql`
  mutation delete_storyCharacters($storyId: Int, $characterId: Int) {
    delete_storyCharacters(
      where: { storyId: { _eq: $storyId }, characterId: { _eq: $characterId } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_STORY_EVENT_ASSOCIATION = gql`
  mutation delete_storyEvents($storyId: Int, $eventId: Int) {
    delete_storyEvents(
      where: { storyId: { _eq: $storyId }, eventId: { _eq: $eventId } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_STORY_LOCATION_ASSOCIATION = gql`
  mutation delete_storyLocations($storyId: Int, $locationId: Int) {
    delete_storyLocations(
      where: { storyId: { _eq: $storyId }, locationId: { _eq: $locationId } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_STORY_ITEM_ASSOCIATION = gql`
  mutation delete_storyItems($storyId: Int, $itemId: Int) {
    delete_storyItems(
      where: { storyId: { _eq: $storyId }, itemId: { _eq: $itemId } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_STORY_TIMELINE_ASSOCIATION = gql`
  mutation delete_storyTimeline($storyId: Int, $timelineId: Int) {
    delete_storyTimeline(
      where: { storyId: { _eq: $storyId }, timelineId: { _eq: $timelineId } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_CHARACTER = gql`
  mutation delete_character($storyId: Int, $characterId: Int) {
    delete_association(where: { characterId: { _eq: $characterId } }) {
      affected_rows
    }
    delete_storyCharacters(
      where: { storyId: { _eq: $storyId }, characterId: { _eq: $characterId } }
    ) {
      affected_rows
    }
    delete_character(where: { id: { _eq: $characterId } }) {
      affected_rows
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation delete_event($storyId: Int, $eventId: Int) {
    delete_association(where: { eventId: { _eq: $eventId } }) {
      affected_rows
    }
    delete_storyEvents(
      where: { storyId: { _eq: $storyId }, eventId: { _eq: $eventId } }
    ) {
      affected_rows
    }
    delete_event(where: { id: { _eq: $eventId } }) {
      affected_rows
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation delete_location($storyId: Int, $locationId: Int) {
    delete_association(where: { locationId: { _eq: $locationId } }) {
      affected_rows
    }
    delete_storyLocations(
      where: { storyId: { _eq: $storyId }, locationId: { _eq: $locationId } }
    ) {
      affected_rows
    }
    delete_location(where: { id: { _eq: $locationId } }) {
      affected_rows
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation delete_item($storyId: Int, $itemId: Int) {
    delete_association(where: { itemId: { _eq: $itemId } }) {
      affected_rows
    }
    delete_storyItems(
      where: { storyId: { _eq: $storyId }, itemId: { _eq: $itemId } }
    ) {
      affected_rows
    }
    delete_item(where: { id: { _eq: $itemId } }) {
      affected_rows
    }
  }
`;

export const DELETE_CHARACTER_EVENT_ASSOCIATION = gql`
  mutation update_association($characterId: Int, $eventId: Int) {
    update_association(
      where: { characterId: { _eq: $characterId }, eventId: { _eq: $eventId } }
      _set: { eventId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        characterId: { _eq: $characterId }
        _and: [
          { eventId: { _is_null: true } }
          { locationId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_CHARACTER_LOCATION_ASSOCIATION = gql`
  mutation update_association($characterId: Int, $locationId: Int) {
    update_association(
      where: {
        characterId: { _eq: $characterId }
        locationId: { _eq: $locationId }
      }
      _set: { locationId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        characterId: { _eq: $characterId }
        _and: [
          { eventId: { _is_null: true } }
          { locationId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_CHARACTER_ITEM_ASSOCIATION = gql`
  mutation update_association($characterId: Int, $itemId: Int) {
    update_association(
      where: { characterId: { _eq: $characterId }, itemId: { _eq: $itemId } }
      _set: { itemId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        characterId: { _eq: $characterId }
        _and: [
          { eventId: { _is_null: true } }
          { locationId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_LOCATION_EVENT_ASSOCIATION = gql`
  mutation update_association($locationId: Int, $eventId: Int) {
    update_association(
      where: { locationId: { _eq: $locationId }, eventId: { _eq: $eventId } }
      _set: { eventId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        locationId: { _eq: $locationId }
        _and: [
          { eventId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_LOCATION_CHARACTER_ASSOCIATION = gql`
  mutation update_association($locationId: Int, $characterId: Int) {
    update_association(
      where: {
        locationId: { _eq: $locationId }
        characterId: { _eq: $characterId }
      }
      _set: { characterId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        locationId: { _eq: $locationId }
        _and: [
          { eventId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_LOCATION_ITEM_ASSOCIATION = gql`
  mutation update_association($locationId: Int, $itemId: Int) {
    update_association(
      where: { locationId: { _eq: $locationId }, itemId: { _eq: $itemId } }
      _set: { itemId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        locationId: { _eq: $locationId }
        _and: [
          { eventId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_EVENT_LOCATION_ASSOCIATION = gql`
  mutation update_association($locationId: Int, $eventId: Int) {
    update_association(
      where: { eventId: { _eq: $eventId }, locationId: { _eq: $locationId } }
      _set: { locationId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        eventId: { _eq: $eventId }
        _and: [
          { locationId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_EVENT_CHARACTER_ASSOCIATION = gql`
  mutation update_association($eventId: Int, $characterId: Int) {
    update_association(
      where: { eventId: { _eq: $eventId }, characterId: { _eq: $characterId } }
      _set: { characterId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        eventId: { _eq: $eventId }
        _and: [
          { locationId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_EVENT_ITEM_ASSOCIATION = gql`
  mutation update_association($eventId: Int, $itemId: Int) {
    update_association(
      where: { eventId: { _eq: $eventId }, itemId: { _eq: $itemId } }
      _set: { itemId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        eventId: { _eq: $eventId }
        _and: [
          { locationId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { itemId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_ITEM_EVENT_ASSOCIATION = gql`
  mutation update_association($itemId: Int, $eventId: Int) {
    update_association(
      where: { itemId: { _eq: $itemId }, eventId: { _eq: $eventId } }
      _set: { eventId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        itemId: { _eq: $itemId }
        _and: [
          { locationId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { eventId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_ITEM_CHARACTER_ASSOCIATION = gql`
  mutation update_association($itemId: Int, $characterId: Int) {
    update_association(
      where: { itemId: { _eq: $itemId }, characterId: { _eq: $characterId } }
      _set: { characterId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        itemId: { _eq: $itemId }
        _and: [
          { locationId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { eventId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_ITEM_LOCATION_ASSOCIATION = gql`
  mutation update_association($itemId: Int, $locationId: Int) {
    update_association(
      where: { itemId: { _eq: $itemId }, locationId: { _eq: $locationId } }
      _set: { locationId: null }
    ) {
      affected_rows
    }
    delete_association(
      where: {
        itemId: { _eq: $itemId }
        _and: [
          { locationId: { _is_null: true } }
          { characterId: { _is_null: true } }
          { eventId: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;
