import React from 'react';

export function CustIcon(props) {
	const addClassName = props.className === undefined ? '' : props.className;
	const { removeMedia, index } = props;
	switch (props.type) {
		case 'delete':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName}`}>
					<svg width="1em" height="1em" fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<g>
							<path d="m424 64h-88v-16c0-26.467-21.533-48-48-48h-64c-26.467 0-48 21.533-48 48v16h-88c-22.056 0-40 17.944-40 40v56c0 8.836 7.164 16 16 16h8.744l13.823 290.283c1.221 25.636 22.281 45.717 47.945 45.717h242.976c25.665 0 46.725-20.081 47.945-45.717l13.823-290.283h8.744c8.836 0 16-7.164 16-16v-56c0-22.056-17.944-40-40-40zm-216-16c0-8.822 7.178-16 16-16h64c8.822 0 16 7.178 16 16v16h-96zm-128 56c0-4.411 3.589-8 8-8h336c4.411 0 8 3.589 8 8v40c-4.931 0-331.567 0-352 0zm313.469 360.761c-.407 8.545-7.427 15.239-15.981 15.239h-242.976c-8.555 0-15.575-6.694-15.981-15.239l-13.751-288.761h302.44z" />
							<path d="m256 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z" />
							<path d="m336 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z" />
							<path d="m176 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z" />
						</g>
					</svg>
				</i>
			);
		case 'marker':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						version="1.1"
						xlink="http://www.w3.org/1999/xlink"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 53 70">
						<path
							d="M26.114 0C40.537 0 52.229 8.554 52.229 26.114C52.229 43.674 26.114 69.579 26.114 69.579C26.114 69.579 0 43.576 0 26.114C0 8.652 11.692 0 26.114 0L26.114 0Z"
							fill="#EEEEEE"
							stroke="none"
						/>
					</svg>
				</i>
			);
		case 'voice':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						version="1.1"
						xlink="http://www.w3.org/1999/xlink"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 13.782 17.836">
						<path d="M7.876,0V13.836H9.845V0ZM3.938,1.73V12.107H5.907V1.73Zm7.876,1.73v6.918h1.969V3.459ZM0,5.189V8.648H1.969V5.189Z" />
					</svg>
				</i>
			);
		case 'image':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						version="1.1"
						xlink="http://www.w3.org/1999/xlink"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 17.017 14.503">
						<path
							id="Icon_metro-image"
							data-name="Icon metro-image"
							d="M18.522,4.892l0,0V17.322l0,0H3.636l0,0V4.894l0,0Zm0-1.036H3.634A1.053,1.053,0,0,0,2.571,4.892V17.323a1.053,1.053,0,0,0,1.064,1.036h14.89a1.053,1.053,0,0,0,1.064-1.036V4.892a1.053,1.053,0,0,0-1.064-1.036ZM16.4,7.482a1.6,1.6,0,1,1-1.6-1.554A1.575,1.575,0,0,1,16.4,7.482Zm1.064,8.806H4.7V14.216L8.42,8l4.254,5.18h1.064l3.722-3.108Z"
							transform="translate(-2.571 -3.856)"
							fill="#898a8f"
						/>
					</svg>
				</i>
			);
		case 'video':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						version="1.1"
						xlink="http://www.w3.org/1999/xlink"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 21.25 13.89">
						<path d="M21,1.9a.49.49,0,0,0-.52,0L14.81,6V2.34A2.34,2.34,0,0,0,12.47,0H2.34A2.34,2.34,0,0,0,0,2.34v9.21a2.34,2.34,0,0,0,2.34,2.34H12.47a2.34,2.34,0,0,0,2.34-2.34V7.91l5.65,4a.52.52,0,0,0,.29.09A.46.46,0,0,0,21,12a.48.48,0,0,0,.27-.44V2.34A.48.48,0,0,0,21,1.9Zm-7.17,9.65a1.34,1.34,0,0,1-1.34,1.34H2.34A1.34,1.34,0,0,1,1,11.55V2.34A1.34,1.34,0,0,1,2.34,1H12.47a1.34,1.34,0,0,1,1.34,1.34Zm6.44-1L15.17,6.94l5.08-3.63Z" />
					</svg>
				</i>
			);

		case 'clock':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12.51"
						height="12.48"
						viewBox="0 0 12.51 12.48">
						<path
							id="Path_2022"
							data-name="Path 2022"
							d="M1.831-10.034a6.027,6.027,0,0,1,4.424-1.831,6.027,6.027,0,0,1,4.424,1.831A6.01,6.01,0,0,1,12.51-5.625a6.01,6.01,0,0,1-1.831,4.409A6.027,6.027,0,0,1,6.255.615,6.027,6.027,0,0,1,1.831-1.216,6.01,6.01,0,0,1,0-5.625,6.01,6.01,0,0,1,1.831-10.034ZM2.725-2.08A4.812,4.812,0,0,0,6.255-.615,4.812,4.812,0,0,0,9.785-2.08,4.829,4.829,0,0,0,11.25-5.625,4.829,4.829,0,0,0,9.785-9.17a4.812,4.812,0,0,0-3.53-1.465A4.812,4.812,0,0,0,2.725-9.17,4.829,4.829,0,0,0,1.26-5.625,4.829,4.829,0,0,0,2.725-2.08ZM6.563-8.76v3.281l2.813,1.67-.469.791L5.625-5.01V-8.76Z"
							transform="translate(0 11.865)"
							fill="#666"
						/>
					</svg>
				</i>
			);

		case 'gps':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 50.97 50.25">
						<path d="M50.69.28A1,1,0,0,0,49.6.09L.55,17.56a.94.94,0,0,0-.54,1,1,1,0,0,0,.8.8l21.45,9.26L31,49.43a1,1,0,0,0,.8.81h.16a1,1,0,0,0,.85-.54L50.87,1.36A.93.93,0,0,0,50.69.28Z" />
					</svg>
				</i>
			);

		case 'date':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512.11">
						<path d="M432,40H406V20a20,20,0,0,0-40,0V40H275V20a20,20,0,0,0-40,0V40H145V20a20,20,0,0,0-40,0V40H80A80.09,80.09,0,0,0,0,120V432a80.09,80.09,0,0,0,80,80H233a20,20,0,0,0,0-40H80a40,40,0,0,1-40-40V120A40,40,0,0,1,80,80h25v20a20,20,0,0,0,40,0V80h90v20a20,20,0,0,0,40,0V80h91v20a20,20,0,0,0,40,0V80h26a40,40,0,0,1,40,40V234a20,20,0,0,0,40,0V120A80.09,80.09,0,0,0,432,40Z" />
						<path d="M416.64,376.84h-9v-21a20,20,0,0,0-40,0v41a20,20,0,0,0,20,20h29a20,20,0,0,0,0-40Z" />
						<path d="M391,512.11A120.54,120.54,0,1,1,511.54,391.57,120.67,120.67,0,0,1,391,512.11ZM391,313a78.62,78.62,0,1,0,78.62,78.62A78.71,78.71,0,0,0,391,313Z" />
						<circle cx="299" cy="210" r="20" />
						<circle cx="386" cy="210" r="20" />
						<circle cx="125" cy="210" r="20" />
						<circle cx="125" cy="297" r="20" />
						<circle cx="125" cy="384" r="20" />
						<circle cx="212" cy="210" r="20" />
						<circle cx="212" cy="297" r="20" />
						<circle cx="212" cy="384" r="20" />
					</svg>
				</i>
			);

		case 'upload':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512">
						<path d="M412.907,214.08C398.4,140.693,333.653,85.333,256,85.333c-61.653,0-115.093,34.987-141.867,86.08 C50.027,178.347,0,232.64,0,298.667c0,70.72,57.28,128,128,128h277.333C464.213,426.667,512,378.88,512,320 C512,263.68,468.16,218.027,412.907,214.08z M298.667,277.333v85.333h-85.333v-85.333h-64L256,170.667l106.667,106.667H298.667z" />
					</svg>
				</i>
			);

		case 'close':
			return (
				<i
					onClick={() => removeMedia(index)}
					className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512">
						<path d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M359.54,329.374 c4.167,4.165,4.167,10.919,0,15.085L344.46,359.54c-4.167,4.165-10.919,4.165-15.086,0L256,286.167l-73.374,73.374 c-4.167,4.165-10.919,4.165-15.086,0l-15.081-15.082c-4.167-4.165-4.167-10.919,0-15.085l73.374-73.375l-73.374-73.374 c-4.167-4.165-4.167-10.919,0-15.085l15.081-15.082c4.167-4.165,10.919-4.165,15.086,0L256,225.832l73.374-73.374 c4.167-4.165,10.919-4.165,15.086,0l15.081,15.082c4.167,4.165,4.167,10.919,0,15.085l-73.374,73.374L359.54,329.374z" />
					</svg>
				</i>
			);
		case 'play':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						width="1em"
						height="1em"
						fill="currentcolor"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 142.448 142.448">
						<path d="M142.411,68.9C141.216,31.48,110.968,1.233,73.549,0.038c-20.361-0.646-39.41,7.104-53.488,21.639 C6.527,35.65-0.584,54.071,0.038,73.549c1.194,37.419,31.442,67.667,68.861,68.861c0.779,0.025,1.551,0.037,2.325,0.037 c19.454,0,37.624-7.698,51.163-21.676C135.921,106.799,143.033,88.377,142.411,68.9z M111.613,110.336 c-10.688,11.035-25.032,17.112-40.389,17.112c-0.614,0-1.228-0.01-1.847-0.029c-29.532-0.943-53.404-24.815-54.348-54.348 c-0.491-15.382,5.122-29.928,15.806-40.958c10.688-11.035,25.032-17.112,40.389-17.112c0.614,0,1.228,0.01,1.847,0.029 c29.532,0.943,53.404,24.815,54.348,54.348C127.91,84.76,122.296,99.306,111.613,110.336z" />
						<path d="M94.585,67.086L63.001,44.44c-3.369-2.416-8.059-0.008-8.059,4.138v45.293 c0,4.146,4.69,6.554,8.059,4.138l31.583-22.647C97.418,73.331,97.418,69.118,94.585,67.086z" />
					</svg>
				</i>
			);
		case 'edit':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
						<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
						<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
					</svg>
				</i>
			);
		case 'eye':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg width="1em" height="1em" fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<g>
							<path d="M51.8,25.1C47.1,15.6,37.3,9,26,9S4.9,15.6,0.2,25.1c-0.3,0.6-0.3,1.3,0,1.8C4.9,36.4,14.7,43,26,43
  s21.1-6.6,25.8-16.1C52.1,26.3,52.1,25.7,51.8,25.1z M26,37c-6.1,0-11-4.9-11-11s4.9-11,11-11s11,4.9,11,11S32.1,37,26,37z" />
							<path d="M26,19c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S29.9,19,26,19z" />
						</g>
					</svg>
				</i>
			);
		case 'dots':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg width="1em" height="1em" fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
						<path fill="currentColor" d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z">
						</path>
					</svg>
				</i>
			);
		case 'unlink':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg width="1em" height="1em" fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<path d="M13.5 11.5c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l3.068-3.068c0.507-0.507 0.786-1.18 0.786-1.896s-0.279-1.39-0.786-1.896c-1.046-1.046-2.747-1.046-3.793 0l-3.068 3.068c-0.195 0.195-0.512 0.195-0.707 0s-0.195-0.512 0-0.707l3.068-3.068c1.436-1.436 3.772-1.436 5.207 0 0.695 0.695 1.078 1.62 1.078 2.604s-0.383 1.908-1.078 2.604l-3.068 3.068c-0.098 0.098-0.226 0.147-0.354 0.147z">
						</path>
						<path d="M4.682 19c-0.983 0-1.908-0.383-2.604-1.078-1.436-1.436-1.436-3.772 0-5.207l3.068-3.068c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-3.068 3.068c-1.046 1.046-1.046 2.747 0 3.793 0.507 0.507 1.18 0.786 1.896 0.786s1.39-0.279 1.896-0.786l3.068-3.068c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-3.068 3.068c-0.695 0.695-1.62 1.078-2.604 1.078z">
						</path>
						<path d="M5.5 7c-0.128 0-0.256-0.049-0.354-0.146l-2-2c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l2 2c0.195 0.195 0.195 0.512 0 0.707-0.098 0.098-0.226 0.146-0.354 0.146z">
						</path>
						<path d="M7.5 6c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v3c0 0.276-0.224 0.5-0.5 0.5z">
						</path>
						<path d="M4.5 9h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
						</path>
						<path d="M15.5 17c-0.128 0-0.256-0.049-0.354-0.146l-2-2c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l2 2c0.195 0.195 0.195 0.512 0 0.707-0.098 0.098-0.226 0.146-0.354 0.146z">
						</path>
						<path d="M17.5 13h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
						</path>
						<path d="M11.5 19c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v3c0 0.276-0.224 0.5-0.5 0.5z">
						</path>
					</svg>
				</i>
			);
		case 'link':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
						<path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
						<path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
					</svg>
				</i>
			);

		case 'upload-alt':
			return (
				<i className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
						<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
						<polyline points="17 8 12 3 7 8"></polyline>
						<line x1="12" y1="3" x2="12" y2="15"></line>
					</svg>
				</i>
			);
		case 'logo':
			return (
				<i
					onClick={() => removeMedia(index)}
					className={`custIcon custIcon-${props.type} ${addClassName} `}>
					<svg
						height="26px"
						fill="currentcolor"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 862.01 117.74">
						<path
							fill="#2b2b2b"
							d="M306.85,29.79a5.27,5.27,0,0,1,1.47,3.91,8.5,8.5,0,0,1-.65,3.42l-25.39,76.15a6.1,6.1,0,0,1-5.86,4.07,6.67,6.67,0,0,1-3.82-1.14,5,5,0,0,1-2.2-2.93L250.87,48.54,231.35,113.3a5.52,5.52,0,0,1-2.28,3,6.34,6.34,0,0,1-3.58,1.06,5.9,5.9,0,0,1-3.67-1.14,7.27,7.27,0,0,1-2.36-2.93L194.08,37.15a14.05,14.05,0,0,1-.49-3.42c0-3.58,1.73-5.37,5.21-5.37,3,0,5.1,1.57,6.18,4.72l20.83,63.46L245,33.24a7.18,7.18,0,0,1,2.2-3.58,6.78,6.78,0,0,1,8,0,7,7,0,0,1,2.2,3.58l18.72,63L296.78,33.1q1.64-4.73,6-4.72A5.53,5.53,0,0,1,306.85,29.79Z"
						/>
						<path
							fill="#2b2b2b"
							d="M335.65,114a37.33,37.33,0,0,1-15-9.6,6,6,0,0,1-1.62-3.74,6.18,6.18,0,0,1,2.6-4.88,6.78,6.78,0,0,1,4.07-1.47,6,6,0,0,1,4.72,2.28,24.74,24.74,0,0,0,10.33,7A39.57,39.57,0,0,0,355,106q11.38,0,17.09-3.9A12.47,12.47,0,0,0,378,91.79q0-5.85-5.7-9.76T354.2,76.2q-16-2.58-23.84-9t-7.9-15.54q0-11.88,9.2-18.39t23-6.51a44.5,44.5,0,0,1,18.74,3.63,32.92,32.92,0,0,1,12.85,10.17,7.46,7.46,0,0,1,1.31,3.75A5,5,0,0,1,385,48.7a5.19,5.19,0,0,1-3.09.81,7.38,7.38,0,0,1-5.69-2.44q-8-9.1-21.81-9.11-9,0-14.32,3.66t-5.42,9.17a10.84,10.84,0,0,0,5,9.35q5.06,3.51,17.91,5.62,17.4,2.94,24.89,9.51A21,21,0,0,1,390,91.66,21.31,21.31,0,0,1,385.52,105a29,29,0,0,1-12.45,9.12A47.1,47.1,0,0,1,355,117.37,55.11,55.11,0,0,1,335.65,114Z"
						/>
						<path
							fill="#2b2b2b"
							d="M449.69,106.63a6.58,6.58,0,0,1,1.62,4.56,5.7,5.7,0,0,1-1.95,4.39,6.9,6.9,0,0,1-4.88,1.79h-3.42A29,29,0,0,1,414.94,102a33.11,33.11,0,0,1-3.82-16V42.52H400.54A5.56,5.56,0,0,1,396.39,41a5.14,5.14,0,0,1-1.54-3.82,5.44,5.44,0,0,1,1.54-4,5.64,5.64,0,0,1,4.15-1.55h10.58V6.39a6.38,6.38,0,0,1,1.71-4.6A5.94,5.94,0,0,1,417.3,0a6.13,6.13,0,0,1,6.35,5.91,3.23,3.23,0,0,1,0,.44V31.61H442a5.64,5.64,0,0,1,4.15,1.55,5.44,5.44,0,0,1,1.54,4A5.14,5.14,0,0,1,446.15,41,5.59,5.59,0,0,1,442,42.52H423.65V86q0,8.3,4.88,13.58a16.26,16.26,0,0,0,12.53,5.29h4.4A5.52,5.52,0,0,1,449.69,106.63Z"
						/>
						<path
							fill="#2b2b2b"
							d="M520.87,29.17q4,1.8,4,5a5.07,5.07,0,0,1-.16,1.46,5,5,0,0,1-2,3.09,7.36,7.36,0,0,1-3.82.82c-1,0-2.66-.11-5-.33a36.33,36.33,0,0,0-3.9-.16q-12.21,0-19.94,6.51a21.11,21.11,0,0,0-7.73,16.92v48.71a6.19,6.19,0,1,1-12.36.71c0-.24,0-.47,0-.71V34.54a6.19,6.19,0,1,1,12.36-.7c0,.24,0,.47,0,.7V41.7a32.44,32.44,0,0,1,12-10.58,34.37,34.37,0,0,1,15.79-3.74Q516.89,27.38,520.87,29.17Z"
						/>
						<path
							fill="#2b2b2b"
							d="M692.54,32.67A43.32,43.32,0,0,1,708.81,49a45.7,45.7,0,0,1,5.94,23.1v38.24a6,6,0,0,1-1.75,4.42,6.17,6.17,0,0,1-4.56,1.79,6.3,6.3,0,0,1-4.55-1.71,5.86,5.86,0,0,1-1.79-4.48v-9.76a40.19,40.19,0,0,1-14.49,12.29,42,42,0,0,1-19.36,4.47,42.82,42.82,0,0,1-38.16-22.21,46.32,46.32,0,0,1-5.78-23,45.7,45.7,0,0,1,5.94-23.1,43.25,43.25,0,0,1,16.27-16.36,47.61,47.61,0,0,1,46.06,0Zm-6.27,68.92a31.61,31.61,0,0,0,11.8-12.13,34.93,34.93,0,0,0,4.31-17.33,35,35,0,0,0-4.31-17.34,32.81,32.81,0,0,0-57.28,0,34.88,34.88,0,0,0-4.31,17.33,34.82,34.82,0,0,0,4.31,17.33,31.33,31.33,0,0,0,11.88,12.13A32.83,32.83,0,0,0,669.51,106a32.43,32.43,0,0,0,16.76-4.38Z"
						/>
						<path
							fill="#2b2b2b"
							d="M846.3,31.94a31,31,0,0,1,11.55,12.94A43.11,43.11,0,0,1,862,64.32v46.87a5.86,5.86,0,0,1-5.54,6.18,3.42,3.42,0,0,1-.64,0,6.27,6.27,0,0,1-4.56-1.71,5.85,5.85,0,0,1-1.79-4.47V64.32q0-11.55-5.94-18.31A19.73,19.73,0,0,0,828,39.26q-10.26,0-16.6,6.84T805.22,64v47.19a5.86,5.86,0,0,1-5.54,6.18,3.53,3.53,0,0,1-.65,0,6.3,6.3,0,0,1-4.55-1.71,5.85,5.85,0,0,1-1.79-4.47V64.32q0-11.55-5.94-18.31a19.75,19.75,0,0,0-15.54-6.75q-10.1,0-16.6,6.67T748.1,63v48.17a5.86,5.86,0,0,1-5.54,6.18,3.53,3.53,0,0,1-.65,0,6.3,6.3,0,0,1-4.55-1.71,5.85,5.85,0,0,1-1.79-4.47V34.7a6.13,6.13,0,0,1,5.89-6.34h.45a6,6,0,0,1,4.48,1.79,6.34,6.34,0,0,1,1.71,4.55v3.58a31.58,31.58,0,0,1,10.82-8,33.88,33.88,0,0,1,29.7,1.22A30.28,30.28,0,0,1,800,42.84a33,33,0,0,1,12.29-11.39,35.54,35.54,0,0,1,17-4.07A31.82,31.82,0,0,1,846.3,31.94Z"
						/>
						<path
							fill="#2b2b2b"
							d="M176.88,47.89a38.46,38.46,0,0,0-14.4-15.55,40.52,40.52,0,0,0-21.32-5.61,42.29,42.29,0,0,0-22,5.78A40.26,40.26,0,0,0,104,48.62a49.75,49.75,0,0,0-5.45,23.51,47,47,0,0,0,5.78,23.36,41,41,0,0,0,16,16.1,47.16,47.16,0,0,0,39.22,2.77,43.86,43.86,0,0,0,13.67-7.57,4.92,4.92,0,0,0,1.79-3.74,5.89,5.89,0,0,0-2.12-4.39,6.06,6.06,0,0,0-3.58-1.3A7.13,7.13,0,0,0,164.92,99a28.64,28.64,0,0,1-9.52,5.13,35.89,35.89,0,0,1-11.8,2q-13.66,0-22.78-8.3A31.2,31.2,0,0,1,111.41,82a39.37,39.37,0,0,1-1.54-11.11c0-8.25,2.31-15.41,5.71-19a30.61,30.61,0,0,1,5.07-6.22q8.14-7.65,20.51-7.65,12.19,0,20,7.65t9.13,20.21l-44.22-.27a5.36,5.36,0,0,0-5.4,5.32v0h0A5.36,5.36,0,0,0,126,76.25h0l50.1-.08a5.85,5.85,0,0,0,4.23-1.55A5.42,5.42,0,0,0,182,70.5,48.47,48.47,0,0,0,176.88,47.89Z"
						/>
						<path
							fill="#2b2b2b"
							d="M605.4,47.89A38.46,38.46,0,0,0,591,32.34a40.43,40.43,0,0,0-21.32-5.61,42.29,42.29,0,0,0-22,5.78A40.24,40.24,0,0,0,532.5,48.62,49.65,49.65,0,0,0,527,72.13a47,47,0,0,0,5.78,23.36,41.14,41.14,0,0,0,16,16.1A47.16,47.16,0,0,0,588,114.36a43.89,43.89,0,0,0,13.74-7.57,4.92,4.92,0,0,0,1.79-3.74,5.89,5.89,0,0,0-2.12-4.39,6.06,6.06,0,0,0-3.58-1.3A7.25,7.25,0,0,0,593.44,99a28.64,28.64,0,0,1-9.52,5.13,35.89,35.89,0,0,1-11.8,2q-13.67,0-22.78-8.3A31.11,31.11,0,0,1,539.94,82a39,39,0,0,1-1.55-11.11c0-8.25,2.31-15.41,5.71-19a30.66,30.66,0,0,1,5.08-6.22q8.13-7.65,20.5-7.65,12.21,0,20,7.65t9.13,20.21l-44.22-.27a5.36,5.36,0,0,0-5.4,5.32v0h0a5.36,5.36,0,0,0,5.37,5.37h0l50.1-.08a5.85,5.85,0,0,0,4.23-1.55,5.44,5.44,0,0,0,1.63-4.15A48.48,48.48,0,0,0,605.4,47.89Z"
						/>
						<path
							fill="#7721d1"
							d="M78.32,72.48c-3.46,0-6.27,2.33-6.27,5.2v29.91a4.58,4.58,0,0,0,1.79,3.65,6.84,6.84,0,0,0,4.56,1.48,6.62,6.62,0,0,0,4.39-1.48,4.58,4.58,0,0,0,1.79-3.65V77.68C84.58,74.81,81.78,72.48,78.32,72.48Z"
						/>
						<path
							fill="#2b2b2b"
							d="M0,106.42V57.79S.26,18.71,42.44,18.44c0,0,42.18-.26,42.18,39.35v3.6s-.9,6.3-6.3,6.3-6.2-5.45-6.2-5.45V58.71s-2-27.48-30.32-27.48S12.59,57.6,12.59,57.6v48.61s-.93,6.51-6.33,6.51S0,106.42,0,106.42Z"
						/>
					</svg>
				</i>
			);

		default:
			return null;
	}
}
