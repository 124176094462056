import { useApolloClient } from "@apollo/client";
import { Avatar, Card, Col, Empty, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../Components/Loader/Loader";
import { SubHeader } from "../../Components/SubHeaderPage";
import { GET_STORY_COMPLETE_VIEW } from "../../graphql/APIs";
import "./StoryCompleteView.css";
// const { REACT_APP_HB_URL } = process.env;
const { REACT_APP_NHOST_STORAGE_URL } = process.env;

const StoryCompleteView = () => {
  const client = useApolloClient();
  const [loaded, setLoaded] = useState(false);
  const [story, setStory] = useState({});
  const [characters, setCharacters] = useState([]);
  const [events, setEvents] = useState([]);
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const location = useLocation();
  const { storyId } = useParams();

  const getData = () => {
    client
      .query({
        query: GET_STORY_COMPLETE_VIEW,
        variables: { id: storyId },
        fetchPolicy: "network-only",
      })
      .then(({ data, loading }) => {
        setStory(data.story[0]);
        setCharacters(data.story[0].storyCharacters);
        setEvents(data.story[0].storyEvents);
        setItems(data.story[0].storyItems);
        setLocations(data.story[0].storyLocations);
        setLoaded(true);
      })
      .catch((error, result) => {
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };
  useEffect(() => {
    localStorage.setItem("last_visited_url", location.pathname);
    getData();
  }, []);

  return (
    <React.Fragment>
      <SubHeader />
      {loaded ? (
        <div className="container">
          <Row gutter={20}>
            <Col md={8}>
              <Card className="cardBox">
                <h3>{story.name}</h3>
                <div className="cardContent">{story.description}</div>
              </Card>
            </Col>
            <Col md={16}>
              <Card className="cardBox mb-4" title="Characters">
                <div>
                  {characters.length > 0 ? (
                    characters.map((obj) => (
                      <Card className="mb-2 cardGrey">
                        <div className="d-flex align-items-center mb-3">
                          <div className="col-auto p-0">
                            {obj?.character?.logo ? (
                              <Avatar
                                src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.character.logo}`}
                              />
                            ) : (
                              <Avatar>
                                {obj.character.firstname
                                  .charAt(0)
                                  .toUpperCase()
                                  .slice(0)}
                              </Avatar>
                            )}
                          </div>
                          <div className="col">
                            <div className="storyname">
                              {obj.character.firstname +
                                (obj.character.lastname !== null
                                  ? " " + obj.character.lastname
                                  : "")}
                            </div>
                          </div>
                        </div>
                        <>
                          <div>{obj.character.description}</div>
                        </>
                        {obj?.character?.associations?.length > 0
                          ? obj?.character?.associations?.map((record) => (
                              <>
                                {record.location !== null ||
                                record.event !== null ||
                                record.item !== null ||
                                record.timeline !== null ? (
                                  <div className="border-top font-medium mt-2 pt-2">
                                    {record?.location !== null ? (
                                      <div>
                                        {" "}
                                        {`Was Present at:`}{" "}
                                        {record?.location?.name}
                                      </div>
                                    ) : null}
                                    {record?.event !== null ? (
                                      <div>
                                        {" "}
                                        {`Was Present during:`}{" "}
                                        {record?.event?.name}
                                      </div>
                                    ) : null}
                                    {record?.item !== null ? (
                                      <div>
                                        {`Possessed:`} {record?.item?.name}
                                      </div>
                                    ) : null}
                                    {record?.timeline !== null ? (
                                      <div>
                                        {`From:`}{" "}
                                        {record?.timeline?.from_month ? (
                                          <>{record.timeline.from_month}</>
                                        ) : (
                                          "Day"
                                        )}{" "}
                                        {record?.timeline?.from_day && (
                                          <>{record.timeline.from_day}</>
                                        )}
                                        {record?.timeline?.from_year && (
                                          <>{"," + record.timeline.from_year}</>
                                        )}
                                        {record?.timeline?.to_day ||
                                        record?.timeline?.to_month ||
                                        record?.timeline?.to_year ? (
                                          <>{" To: "}</>
                                        ) : (
                                          ""
                                        )}{" "}
                                        {record?.timeline?.to_month ? (
                                          <>{record.timeline.to_month}</>
                                        ) : (
                                          "Day"
                                        )}{" "}
                                        {record?.timeline?.to_day && (
                                          <>{record.timeline.to_day}</>
                                        )}
                                        {record?.timeline?.to_year && (
                                          <>{"," + record.timeline.to_year}</>
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}{" "}
                              </>
                            ))
                          : null}
                      </Card>
                    ))
                  ) : (
                    <Empty description={<span>No character added</span>} />
                  )}
                </div>
              </Card>
              <Card className="cardBox mb-4" title="Locations">
                <div>
                  {locations.length > 0 ? (
                    locations.map((obj) => (
                      <Card className="mb-2 cardGrey">
                        <div className="d-flex align-items-center mb-3">
                          <div className="col-auto p-0">
                            {obj?.location?.logo ? (
                              <Avatar
                                src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.location.logo}`}
                              />
                            ) : (
                              <Avatar>
                                {obj.location.name
                                  .charAt(0)
                                  .toUpperCase()
                                  .slice(0)}
                              </Avatar>
                            )}
                          </div>
                          <div className="col">
                            <div className="storyname">{obj.location.name}</div>
                          </div>
                        </div>
                        <>
                          <div>{obj.location.description}</div>
                        </>
                      </Card>
                    ))
                  ) : (
                    <Empty description={<span>No Location added</span>} />
                  )}
                </div>
              </Card>
              <Card className="cardBox mb-4" title="Events">
                <div>
                  {events.length > 0 ? (
                    events.map((obj) => (
                      <Card className="mb-2 cardGrey">
                        <div className="d-flex align-items-center mb-3">
                          <div className="col-auto p-0">
                            {obj?.event?.logo ? (
                              <Avatar
                                src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.event.logo}`}
                              />
                            ) : (
                              <Avatar>
                                {obj.event.name
                                  .charAt(0)
                                  .toUpperCase()
                                  .slice(0)}
                              </Avatar>
                            )}
                          </div>
                          <div className="col">
                            <div className="storyname">{obj.event.name}</div>
                          </div>
                        </div>
                        <>
                          <div>{obj.event.description}</div>
                        </>
                      </Card>
                    ))
                  ) : (
                    <Empty description={<span>No event added</span>} />
                  )}
                </div>
              </Card>
              <Card className="cardBox mb-4" title="Items">
                <div>
                  {items.length > 0 ? (
                    items.map((obj) => (
                      <Card className="mb-2 cardGrey">
                        <div className="d-flex align-items-center mb-3">
                          <div className="col-auto p-0">
                            {obj?.item?.logo ? (
                              <Avatar
                                src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.item.logo}`}
                              />
                            ) : (
                              <Avatar>
                                {obj.item.name.charAt(0).toUpperCase().slice(0)}
                              </Avatar>
                            )}
                          </div>
                          <div className="col">
                            <div className="storyname">{obj.item.name}</div>
                          </div>
                        </div>
                        <>
                          <div>{obj.item.description}</div>
                        </>
                      </Card>
                    ))
                  ) : (
                    <Empty description={<span>No item added</span>} />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default StoryCompleteView;
