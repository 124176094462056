import { message, Empty, Timeline, Card, Col } from "antd";
import React, { useEffect, useState } from "react";
import { GET_ALL_TIMELINES } from "../../graphql/APIs";
import { Loader } from "../../Components/Loader/Loader";
import { SubHeader } from "../../Components/SubHeaderPage";
import { Link, useLocation, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import useStore from "../../utils/store";

const Timelines = () => {
  const [loaded, setLoaded] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const client = useApolloClient();
  const location = useLocation();
  const { id } = useParams();
  const { story } = useStore();
  const addStoryDetail = useStore((state) => state.addStoryDetail);
  const addCharacterDetail = useStore((state) => state.addCharacterDetail);
  const addLocationDetail = useStore((state) => state.addLocationDetail);
  const addEventDetail = useStore((state) => state.addEventDetail);
  const addItemDetail = useStore((state) => state.addItemDetail);
  const addHistoryDetail = useStore((state) => state.addHistoryDetail);

  const updateStore = (object, type) => {
    switch (type) {
      case "story":
        addStoryDetail(object);
        break;
      case "character":
        addCharacterDetail(object);

        break;
      case "location":
        addLocationDetail(object);

        break;
      case "event":
        addEventDetail(object);

        break;
      case "item":
        addItemDetail(object);

        break;
      case "history":
        addHistoryDetail(object);

        break;

      default:
        break;
    }
  };

  const getData = () => {
    client
      .query({
        query: GET_ALL_TIMELINES,
        variables: {
          id: parseInt(id),
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        setTimeline(data.story[0].storyTimelines);
        setLoaded(true);
      })
      .catch((error, result) => {
        setLoaded(true);
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };
  useEffect(() => {
    localStorage.setItem("last_visited_url", location.pathname);
    getData();
  }, []);

  return (
    <React.Fragment>
      <SubHeader />
      {loaded ? (
        <div className="container">
          <Card className="commonBox">
            <div className="cardTimeLine_Sec">
              {timeline.length > 0 ? (
                <Timeline mode="alternate" className="timeline_Card">
                  {timeline.map((record, index) => (
                    <Timeline.Item
                      color="red"
                      label={
                        <div className="timelineDate">
                          {record?.timeline?.from_month ? (
                            <>{record.timeline.from_month}</>
                          ) : (
                            "Day"
                          )}{" "}
                          {record?.timeline?.from_day && (
                            <>{record.timeline.from_day}</>
                          )}
                          {", "}
                          {record?.timeline?.from_year && (
                            <>{record.timeline.from_year}</>
                          )}
                          {(record?.timeline?.to_month ||
                            record?.timeline?.to_year ||
                            record?.timeline?.to_day) &&
                          (record?.timeline?.to_year !==
                            record?.timeline?.from_year ||
                            record?.timeline?.to_day !==
                              record?.timeline?.from_day ||
                            record?.timeline?.to_month !==
                              record?.timeline?.from_month) ? (
                            <>
                              {record?.timeline?.to_month ||
                              record?.timeline?.to_year ||
                              record?.timeline?.to_day ? (
                                <>{" - "}</>
                              ) : (
                                ""
                              )}
                              {record?.timeline?.to_month ? (
                                <>{record.timeline.to_month}</>
                              ) : (
                                "Day"
                              )}{" "}
                              {record?.timeline?.to_day && (
                                <>{record.timeline.to_day}</>
                              )}
                              {", "}
                              {record?.timeline?.to_year && (
                                <>{record.timeline.to_year}</>
                              )}
                            </>
                          ) : null}
                        </div>
                      }
                    >
                      {record?.timeline?.associations.length ? (
                        <Card className="cardTimeLine">
                          <div className="site-layout-content">
                            {record.timeline.associations.map((obj, index) => (
                              <>
                                {(obj?.character &&
                                  obj.location &&
                                  obj.event &&
                                  obj.item) ||
                                (obj?.character && obj.location && obj.event) ||
                                (obj?.character && obj.location && obj.item) ||
                                (obj?.character && obj.item && obj.event) ||
                                (obj?.character && obj.item) ||
                                (obj?.character && obj.location) ||
                                (obj?.character && obj.event) ||
                                obj?.character ? (
                                  <Col>
                                    <Link
                                      className=""
                                      to={{
                                        pathname: `/story/character/${obj.character.id}`,
                                      }}
                                      onClick={() =>
                                        updateStore(obj.character, "character")
                                      }
                                    >
                                      {obj?.character?.firstname}{" "}
                                      {obj?.character?.lastname}{" "}
                                    </Link>

                                    {obj?.item && (
                                      <>
                                        {obj?.character ? (
                                          <>
                                            <b>{" possessed "}</b>
                                            <Link
                                              className=""
                                              to={{
                                                pathname: `/story/item/${obj.item.id}`,
                                              }}
                                              onClick={() =>
                                                updateStore(obj.item, "item")
                                              }
                                            >
                                              {obj?.item?.name}
                                            </Link>
                                          </>
                                        ) : (
                                          <>
                                            <Link
                                              className=""
                                              to={{
                                                pathname: `/story/item/${obj.item.id}`,
                                              }}
                                              onClick={() =>
                                                updateStore(obj.item, "item")
                                              }
                                            >
                                              {obj?.item?.name}
                                            </Link>
                                          </>
                                        )}
                                      </>
                                    )}
                                    {obj?.event && (
                                      <>
                                        {obj?.character && obj?.item ? (
                                          <>
                                            <b>{" which was present at "}</b>
                                            <Link
                                              className=""
                                              to={{
                                                pathname: `/story/event/${obj.event.id}`,
                                              }}
                                              onClick={() =>
                                                updateStore(obj.event, "event")
                                              }
                                            >
                                              {obj?.event?.name}
                                            </Link>
                                          </>
                                        ) : (
                                          <>
                                            {obj?.character ? (
                                              <>
                                                <b>{" was present at "}</b>
                                                <Link
                                                  className=""
                                                  to={{
                                                    pathname: `/story/event/${obj.event.id}`,
                                                  }}
                                                  onClick={() =>
                                                    updateStore(
                                                      obj.event,
                                                      "event"
                                                    )
                                                  }
                                                >
                                                  {obj?.event?.name}
                                                </Link>
                                              </>
                                            ) : (
                                              <>
                                                <Link
                                                  className=""
                                                  to={{
                                                    pathname: `/story/event/${obj.event.id}`,
                                                  }}
                                                  onClick={() =>
                                                    updateStore(
                                                      obj.event,
                                                      "event"
                                                    )
                                                  }
                                                >
                                                  {obj?.event?.name}
                                                </Link>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                    {obj?.location && (
                                      <>
                                        {(obj?.character &&
                                          obj?.event &&
                                          obj?.item) ||
                                        (obj?.character && obj?.event) ? (
                                          <>
                                            <b>{" happened in "}</b>
                                            <Link
                                              className=""
                                              to={{
                                                pathname: `/story/location/${obj.location.id}`,
                                              }}
                                              onClick={() =>
                                                updateStore(
                                                  obj.location,
                                                  "location"
                                                )
                                              }
                                            >
                                              {obj?.location?.name}
                                            </Link>
                                          </>
                                        ) : (
                                          <>
                                            {obj?.character && obj?.item ? (
                                              <>
                                                <b>{" which was in "}</b>
                                                <Link
                                                  className=""
                                                  to={{
                                                    pathname: `/story/location/${obj.location.id}`,
                                                  }}
                                                  onClick={() =>
                                                    updateStore(
                                                      obj.location,
                                                      "location"
                                                    )
                                                  }
                                                >
                                                  {obj?.location?.name}
                                                </Link>
                                              </>
                                            ) : (
                                              <>
                                                {obj?.character ? (
                                                  <>
                                                    <b>{" was in "}</b>
                                                    <Link
                                                      className=""
                                                      to={{
                                                        pathname: `/story/location/${obj.location.id}`,
                                                      }}
                                                      onClick={() =>
                                                        updateStore(
                                                          obj.location,
                                                          "location"
                                                        )
                                                      }
                                                    >
                                                      {obj?.location?.name}
                                                    </Link>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Link
                                                      className=""
                                                      to={{
                                                        pathname: `/story/location//${obj.location.id}`,
                                                      }}
                                                      onClick={() =>
                                                        updateStore(
                                                          obj.location,
                                                          "location"
                                                        )
                                                      }
                                                    >
                                                      {obj?.location?.name}
                                                    </Link>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Col>
                                ) : (
                                  <>
                                    {(obj?.event &&
                                      obj.item &&
                                      obj?.location) ||
                                    (obj.location && obj.event) ? (
                                      <Col>
                                        {obj?.event && (
                                          <>
                                            <Link
                                              className=""
                                              to={{
                                                pathname: `/story/event/${obj.event.id}`,
                                              }}
                                              onClick={() =>
                                                updateStore(obj.event, "event")
                                              }
                                            >
                                              {obj?.event?.name}
                                            </Link>
                                          </>
                                        )}
                                        {obj?.location && (
                                          <>
                                            {obj?.event ? (
                                              <>
                                                <b>{" was occured at "}</b>
                                                <Link
                                                  className=""
                                                  to={{
                                                    pathname: `/story/location/${obj.location.id}`,
                                                  }}
                                                  onClick={() =>
                                                    updateStore(
                                                      obj.location,
                                                      "location"
                                                    )
                                                  }
                                                >
                                                  {obj?.location?.name}
                                                </Link>
                                              </>
                                            ) : (
                                              <>
                                                <Link
                                                  className=""
                                                  to={{
                                                    pathname: `/story/location/${obj.location.id}`,
                                                  }}
                                                  onClick={() =>
                                                    updateStore(
                                                      obj.location,
                                                      "location"
                                                    )
                                                  }
                                                >
                                                  {obj?.location?.name}
                                                </Link>
                                              </>
                                            )}
                                          </>
                                        )}
                                        {obj?.item && (
                                          <>
                                            {obj?.event && obj?.location ? (
                                              <>
                                                <b>{" and "}</b>
                                                <Link
                                                  className=""
                                                  to={{
                                                    pathname: `/story/item/${obj.item.id}`,
                                                  }}
                                                  onClick={() =>
                                                    updateStore(
                                                      obj.item,
                                                      "item"
                                                    )
                                                  }
                                                >
                                                  {obj?.item?.name}
                                                </Link>
                                                <b>{" was used. "}</b>
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                      </Col>
                                    ) : (
                                      <>
                                        {(obj.item && obj.event) ||
                                        (obj.item && obj.location) ? (
                                          <Col>
                                            {obj?.item && (
                                              <>
                                                <Link
                                                  className=""
                                                  to={{
                                                    pathname: `/story/item/${obj.item.id}`,
                                                  }}
                                                  onClick={() =>
                                                    updateStore(
                                                      obj.item,
                                                      "item"
                                                    )
                                                  }
                                                >
                                                  {obj?.item?.name}
                                                </Link>
                                              </>
                                            )}
                                            {obj?.event && (
                                              <>
                                                {obj?.item ? (
                                                  <>
                                                    <b>{" was used in "}</b>
                                                    <Link
                                                      className=""
                                                      to={{
                                                        pathname: `/story/event/${obj.event.id}`,
                                                      }}
                                                      onClick={() =>
                                                        updateStore(
                                                          obj.event,
                                                          "event"
                                                        )
                                                      }
                                                    >
                                                      {obj?.event?.name}
                                                    </Link>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Link
                                                      className=""
                                                      to={{
                                                        pathname: `/story/event/${obj.event.id}`,
                                                      }}
                                                      onClick={() =>
                                                        updateStore(
                                                          obj.event,
                                                          "event"
                                                        )
                                                      }
                                                    >
                                                      {obj?.event?.name}
                                                    </Link>
                                                  </>
                                                )}
                                              </>
                                            )}
                                            {obj?.location && (
                                              <>
                                                {obj?.item ? (
                                                  <>
                                                    <b>{" was at "}</b>
                                                    <Link
                                                      className=""
                                                      to={{
                                                        pathname: `/story/location/${obj.location.id}`,
                                                      }}
                                                      onClick={() =>
                                                        updateStore(
                                                          obj.location,
                                                          "location"
                                                        )
                                                      }
                                                    >
                                                      {obj?.location?.name}
                                                    </Link>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Link
                                                      className=""
                                                      to={{
                                                        pathname: `/story/location/${obj.location.id}`,
                                                      }}
                                                      onClick={() =>
                                                        updateStore(
                                                          obj.location,
                                                          "location"
                                                        )
                                                      }
                                                    >
                                                      {obj?.location?.name}
                                                    </Link>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </Col>
                                        ) : (
                                          <>
                                            {!(
                                              obj.item ||
                                              obj.event ||
                                              obj.location ||
                                              obj.character
                                            ) && null}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                        </Card>
                      ) : (
                        <div className="" style={{ height: "20px" }}>
                          {" "}
                          -- No Associations --{" "}
                        </div>
                      )}
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (
                <Card>
                  <Empty description={<span>No data available</span>} />
                </Card>
              )}
            </div>
          </Card>
        </div>
      ) : !timeline.length ? (
        <Card>
          <Loader />
        </Card>
      ) : (
        <Card>
          <Empty description={<span>No data available</span>} />
        </Card>
      )}
    </React.Fragment>
  );
};

export default Timelines;
