import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { changeEmailRequest } from "../../apis/rest";
import { nhost } from "../../utils/nhostclient";
import "./ChangeEmail.css";

const ChangeEmail = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("last_visited_url", window.location.pathname);
  });

  const cancel = () => {
    navigate("/my-profile");
  };

  const formRef = React.createRef();
  const onFinish = (values) => {
    setLoading(true);
    try {
      changeEmailRequest(values.new_email)
        .then(async () => {
          message.success(`Your Email change request has been submitted. 
									You need to click on confirmation link which you will get in new Email.`);
          setLoading(false);
          await nhost.auth.signOut();
          localStorage.clear();
          navigate("/login");
        })
        .catch((err) => {
          console.log("ERROR->", err);
          message.error(
            (err &&
              err.response &&
              err.response.data &&
              err.response.data.message) ||
              "Email change failed."
          );
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong please try again later");
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <Card className="commonBox changeEmail" title="Change Email Address">
          <Row>
            <Col lg={10} md={14} xs={24}>
              <Form
                layout="vertical"
                ref={formRef}
                className="myProfileForm"
                onFinish={onFinish}
              >
                <div className="onFinishmyProfileDetails">
                  <Form.Item
                    label="New Email"
                    tooltip={{
                      title:
                        "You can request for email change from here. Once submitted you will get a confirmation link on your new Email",
                      icon: <InfoCircleOutlined />,
                    }}
                    name="new_email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid Email!",
                      },
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter new email" />
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                  <Button key="back" onClick={cancel} size="large">
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ChangeEmail;
