import React, { useState, useEffect } from "react";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import "./Login.css";
import {
  Form,
  Input,
  Button,
  Card,
  Layout,
  Row,
  Col,
  message,
  Carousel,
} from "antd";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { login } from "../../apis/rest";
import { useAuthenticationStatus } from "@nhost/react";
import { nhost } from "../../utils/nhostclient";

export const Login = () => {
  const { isLoading, isAuthenticated } = useAuthenticationStatus();
  const [loading, setLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const formRef = React.createRef();
  let navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;

    if (isAuthenticated) {
      if (
        localStorage.last_visited_url &&
        localStorage.lastVisitedUrl !== "/myStories"
      ) {
        navigate(localStorage.last_visited_url);
        return;
      }
      navigate("/stories");
      return;
    }

    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
  }, [isLoading, isAuthenticated]);

  const updateState = (value) => {
    setShowLogin(value);
  };

  const onFinish = async (e) => {
    setLoading(true);

    let model = {
      email: e.email.trim(),
      password: e.password,
    };

    try {
      let { session, error } = await login(model);
      if (session) {
        return <Navigate to="/stories" />;
      }
      if (error) {
        message.error(error.message);
      }
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Account is not activated."
      ) {
        message.error(
          "Account is not activated. You need to click on varification link sent on email."
        );
      } else {
        message.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "Invalid username or password."
        );
      }
      setLoading(false);
    }
  };
  const providerSignIn = async (e) => {
    let { session, providerUrl, provider, error } = await login({
      provider: e,
    });
    if (error && error.message === "User is already signed in") {
      message.info(error.message);
    }
    console.log({ session });
    console.log({ providerUrl, provider, error });
  };
  const onForgotPasswordSubmit = async (values) => {
    setLoading(true);

    try {
      const { error } = await nhost.auth.resetPassword({
        email: values.email,
        options: { redirectTo: window.location.origin + "/change-password" },
      });
      if (error) {
        console.log({ error });
        message.error(error.message);
      } else {
        message.success("Please check your email to reset password!");
        setShowLogin(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong please try again later");
    }
  };
  const onKeyPress = (e) => {
    if (e.charCode === 32) {
      return false;
    }
  };
  return (
    <React.Fragment>
      <Layout className="loginPage d-flex flex-column align-items-center justify-content-center">
        <div className="container">
          <Card className="loginCard">
            <div className="loginForm_blk">
              <Row>
                <Col xs={24} sm={9} md={9}>
                  <div className="loginFormLeft">
                    {showLogin ? (
                      <div className="loginForm_blk">
                        <div className="loginLogo">
                          <img
                            alt="jebidu"
                            src={require("../../Assets/images/login_logo.png")}
                          />
                        </div>
                        <Form layout="vertical" onFinish={onFinish}>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your email address",
                              },
                              {
                                type: "email",
                                message: "Kindly enter a valid email address",
                              },
                            ]}
                          >
                            <Input placeholder="Email" />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your password",
                              },
                              {
                                min: 6,
                                max: 20,
                                message:
                                  "Password must be between 6 to 20 characters",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Password"
                              onKeyPress={onKeyPress}
                              type="password"
                            />
                          </Form.Item>
                          <Form.Item className="pt-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <Button
                                type="primary"
                                size="large"
                                loading={loading}
                                htmlType="submit"
                              >
                                Sign In
                              </Button>
                              <div className="text-right">
                                <Link
                                  to="/signup"
                                  className="forgotPasswordTxt"
                                >
                                  Sign Up
                                </Link>
                              </div>
                            </div>
                          </Form.Item>
                        </Form>

                        <span
                          onClick={() => updateState(false)}
                          className="forgotPasswordTxt"
                        >
                          Forgot Password?
                        </span>
                        <div className="text-center">
                          <h5>OR</h5>
                        </div>

                        <Row style={{ textAlign: "center" }}>
                          <Col md={12}>
                            <div className="socialbutton">
                              <GoogleLoginButton
                                onClick={() => providerSignIn("google")}
                              >
                                Google
                              </GoogleLoginButton>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="socialbutton">
                              <FacebookLoginButton
                                onClick={() => providerSignIn("facebook")}
                              >
                                Facebook
                              </FacebookLoginButton>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div className="forgotPassword_blk">
                        <div className="loginLogo">
                          <img
                            alt="Jebidu"
                            src={require("../../Assets/images/jebidu_logo.png")}
                          />
                        </div>
                        <h5>Forgot Password?</h5>
                        <p>
                          Password Reset – please enter the email you are
                          registered with
                        </p>
                        <Form
                          layout="vertical"
                          className="mb-4"
                          ref={formRef}
                          onFinish={onForgotPasswordSubmit}
                        >
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your email address",
                              },
                              {
                                type: "email",
                                message: "Kindly enter a valid email address",
                              },
                            ]}
                          >
                            <Input placeholder="Email" />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              size="large"
                              loading={loading}
                              htmlType="submit"
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>

                        <Link
                          onClick={() => updateState(true)}
                          to="/login"
                          className=" d-flex flex-row align-items-center"
                        >
                          <ArrowLeftOutlined className="mr-2" /> Go back to
                          Login
                        </Link>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={15} md={15}>
                  <div className="loginFormRight">
                    <Carousel autoplay autoplaySpeed={10000} effect="fade">
                      <div className="loginImg">
                        <img
                          alt="1"
                          src={require("../../Assets/images/slider-img1.jpg")}
                        />
                      </div>
                      <div className="loginImg">
                        <img
                          alt="2"
                          src={require("../../Assets/images/slider-img2.jpg")}
                        />
                      </div>
                      <div className="loginImg">
                        <img
                          alt="3"
                          src={require("../../Assets/images/slider-img3.jpg")}
                        />
                      </div>
                      <div className="loginImg">
                        <img
                          alt="4"
                          src={require("../../Assets/images/slider-img4.jpg")}
                        />
                      </div>
                    </Carousel>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Layout>
    </React.Fragment>
  );
};
