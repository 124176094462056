import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../../apis/rest";
import { nhost } from "../../utils/nhostclient";
import "./HeaderPage.css";

const AppHeader = () => {
  const [username, setUsername] = useState("");

  useEffect(() => {
    const user = getUser();
    if (user) setUsername(user.displayName);
  });

  const logout = async () => {
    localStorage.removeItem("last_visited_url");
    await nhost.auth.signOut();
  };
  const menu = (
    <Menu
      items={[
        {
          label: (
            <>
              <UserOutlined />
              <Link to="/my-profile"> My Account</Link>
            </>
          ),
        },
        {
          label: (
            <>
              <LogoutOutlined />
              <a rel="noopener noreferrer" onClick={logout}>
                {" "}
                Logout
              </a>
            </>
          ),
        },
      ]}
    />
  );

  return (
    <div className="topHeader">
      <div className="container">
        <div className="d-flex flex-md-row flex-column align-items-md-center justify-content-md-between">
          <div className="topLogo">
            <Link to="/stories">
              <img
                alt="JEBIDU"
                src={require("../../Assets/images/jebidu_logo.png")}
              />
            </Link>
          </div>
          <div className="d-flex topHRightNav">
            <Dropdown overlay={menu} placement="bottomRight">
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {username}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
