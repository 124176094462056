import { PlusOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  message,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { AddElementComponent } from "../../Components/AddElementComponent";
import EditElementComponent from "../../Components/EditElementComponent/EditElementComponent";
import { SubHeader } from "../../Components/SubHeaderPage";
import { CustIcon } from "../../Components/Svgs/Svgs";
import { uploadImage } from "../../apis/rest";
import {
  DELETE_CHARACTER,
  DELETE_EVENT,
  DELETE_ITEM,
  DELETE_LOCATION,
  GET_EACH_STORIES_ENTITIES_COUNTS,
  GET_STORY,
  UPDATE_STORY_DETAIL_BY_ID,
  UPDATE_STORY_LOGO,
} from "../../graphql/APIs";
import useStore from "../../utils/store";
import "./EditStory.css";

// const { REACT_APP_ENTITY_LIMIT, REACT_APP_HB_URL } = process.env;
const { REACT_APP_ENTITY_LIMIT, REACT_APP_NHOST_STORAGE_URL } = process.env;

const EditStory = () => {
  const { isSubscribed } = useOutletContext();
  const client = useApolloClient();
  const [loaded, setLoaded] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [events, setEvents] = useState([]);
  const [items, setItems] = useState([]);
  const [storyName, setStoryName] = useState({});
  const [storyDescription, setStoryDescription] = useState({});
  const [storyLogo, setStoryLogo] = useState(null);
  const [locations, setLocations] = useState([]);
  const [timelines, setTimelines] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [elementType, setElementType] = useState("");
  const [selectedElement, setSelectedElement] = useState();
  const [btnloading, setBtnLoading] = useState(false);
  const [isInvisible, setIsInvisible] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [entityCount, setEntityCount] = useState(0);
  const [allEntityCount, setAllEntityCount] = useState(0);
  const addStoryDetail = useStore((state) => state.addStoryDetail);
  const addCharacterDetail = useStore((state) => state.addCharacterDetail);
  const addLocationDetail = useStore((state) => state.addLocationDetail);
  const addEventDetail = useStore((state) => state.addEventDetail);
  const addItemDetail = useStore((state) => state.addItemDetail);
  const addHistoryDetail = useStore((state) => state.addHistoryDetail);

  const updateStore = (object, type) => {
    switch (type) {
      case "story":
        addStoryDetail(object);
        break;
      case "character":
        addCharacterDetail(object);

        break;
      case "location":
        addLocationDetail(object);

        break;
      case "event":
        addEventDetail(object);

        break;
      case "item":
        addItemDetail(object);

        break;
      case "history":
        addHistoryDetail(object);

        break;

      default:
        break;
    }
  };

  const { storyId } = useParams();

  const { Text } = Typography;

  const closeModal = () => {
    setShowModal(false);
    setEditShowModal(false);
  };

  const getData = () => {
    client
      .query({
        query: GET_STORY,
        variables: { id: parseInt(storyId) },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data?.story?.length) {
          getStoryEntityCounts();
          setStoryName(data.story[0].name);
          setStoryDescription(data.story[0].description);
          if (
            data.story[0].description === null ||
            !data.story[0].description.length
          ) {
          }
          setStoryLogo(
            data?.story[0]?.logo !== null ? data.story[0].logo : null
          );
          setCharacters(data.story[0].storyCharacters);
          setEvents(data.story[0].storyEvents);
          setItems(data.story[0].storyItems);
          setLocations(data.story[0].storyLocations);
          setTimelines(data.story[0].storyTimelines);
        } else {
          message.error("Oops! No such story exist.");
          navigate("/stories");
        }
        setLoaded(true);
      })
      .catch((error, result) => {
        setLoaded(true);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };

  const getStoryEntityCounts = () => {
    client
      .query({
        query: GET_EACH_STORIES_ENTITIES_COUNTS,
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        console.log(data);
        let storyBasedCountArr = [];
        _.filter(data.userStory, function (obj) {
          let {
            story: {
              id,
              storyCharacters_aggregate: { characters },
              storyEvents_aggregate: { events },
              storyItems_aggregate: { items },
              storyLocations_aggregate: { locations },
            },
          } = obj;
          if (id === parseInt(storyId)) {
            setEntityCount(
              ((characters && characters.count) || 0) +
                ((events && events.count) || 0) +
                ((items && items.count) || 0) +
                ((locations && locations.count) || 0)
            );
          }
          storyBasedCountArr.push(
            ((characters && characters.count) || 0) +
              ((events && events.count) || 0) +
              ((items && items.count) || 0) +
              ((locations && locations.count) || 0)
          );
        });
        setAllEntityCount(_.sum(storyBasedCountArr));
      })
      .catch((error, result) => {
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };

  useEffect(() => {
    localStorage.setItem("last_visited_url", location.pathname);
    getData();
  }, []);

  const onFinishSaveElement = () => {
    setBtnLoading(false);
    setShowModal(false);
    setLoaded(false);
    setEditShowModal(false);
    getData();
  };

  const addElement = (type) => {
    if (isSubscribed || allEntityCount < REACT_APP_ENTITY_LIMIT) {
      setElementType(type);
      setShowModal(true);
    } else
      message.warning(
        `${
          "You can create upto " +
          { REACT_APP_ENTITY_LIMIT } +
          " entities in FREE account. Upgrade to Pro for unlimited access."
        }`
      );
  };

  const setEditableTitle = async (e) => {
    if (storyName === e?.trim()) {
      message.warning("No change detected!");
      return;
    } else if (!e || !e.length || e === "") {
      message.warning("Story title can not be blank!");
      return;
    } else if (!e || e.length < 3 || e.length > 100) {
      message.warning("Story title must be between 3 to 100 characters.");
      return;
    } else {
      let storyInput = {
        id: parseInt(storyId),
        name: e ? e?.trim() : storyName,
        description: storyDescription,
        logo: storyLogo ? storyLogo : null,
        entity_type: "story",
      };
      const nameResponse = await client.mutate({
        mutation: UPDATE_STORY_DETAIL_BY_ID,
        variables: storyInput,
      });
      if (!nameResponse.error) {
        message.success("Story name updated");
        setStoryName(e?.trim());
      } else {
        message.error("something went wrong");
      }
    }
  };

  const setEditableDescription = async (e) => {
    if (storyDescription === e?.trim()) {
      message.warning("No change detected!");
      return;
    } else if (e.length > 500) {
      message.warning("Story description must be under 500 characters");
      return;
    } else if (!e.length || e === "") {
      let storyInput = {
        id: parseInt(storyId),
        name: storyName,
        description: null,
        logo: storyLogo ? storyLogo : null,
        entity_type: "story",
      };
      const descriptionResponse = await client.mutate({
        mutation: UPDATE_STORY_DETAIL_BY_ID,
        variables: storyInput,
      });
      if (!descriptionResponse.error) {
        message.success("Story description updated");
        setStoryDescription(e?.trim());
      } else {
        message.error("something went wrong");
      }
    } else {
      let storyInput = {
        id: parseInt(storyId),
        name: storyName,
        description: e ? e?.trim() : storyDescription,
        logo: storyLogo ? storyLogo : null,
        entity_type: "story",
      };
      const descriptionResponse = await client.mutate({
        mutation: UPDATE_STORY_DETAIL_BY_ID,
        variables: storyInput,
      });
      if (!descriptionResponse.error) {
        message.success("Story description updated");
        setStoryDescription(e?.trim());
      } else {
        message.error("something went wrong");
      }
    }
  };

  const editElement = (id, elementType) => {
    setElementType(elementType);
    setSelectedElement(id);
    setEditShowModal(true);
  };
  const toggleDiv = (id, elementType, type) => {
    setIsInvisible(elementType + "-" + type + "-" + id);
  };

  const deleteElement = async (id, elementType) => {
    try {
      switch (elementType) {
        case "character":
          await client.mutate({
            mutation: DELETE_CHARACTER,
            variables: {
              storyId: parseInt(storyId),
              characterId: id,
            },
          });
          onFinishSaveElement();
          break;
        case "event":
          await client.mutate({
            mutation: DELETE_EVENT,
            variables: {
              storyId: parseInt(storyId),
              eventId: id,
            },
          });
          onFinishSaveElement();
          break;
        case "item":
          await client.mutate({
            mutation: DELETE_ITEM,
            variables: { storyId: parseInt(storyId), itemId: id },
          });
          onFinishSaveElement();
          break;
        case "location":
          await client.mutate({
            mutation: DELETE_LOCATION,
            variables: {
              storyId: parseInt(storyId),
              locationId: id,
            },
          });
          onFinishSaveElement();
          break;
        default:
      }
    } catch (err) {}
  };

  const setBtnLoader = (e) => {
    console.log(e);
    setBtnLoading(e);
  };
  const handleUpload = async (props) => {
    const { onSuccess, onError, file } = props;

    try {
      if (file.size / (1024 * 1000) <= 2 && file.type.includes("image")) {
        var options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { fileMetadata } = await uploadImage(file, options);
        onSuccess(null, file);
        await client.mutate({
          mutation: UPDATE_STORY_LOGO,
          variables: {
            id: storyId,
            logo: fileMetadata.id,
          },
          // update: (store, { data }) => {
          //   let temp = store.readQuery({
          //     query: GET_STORY,
          // variables: { id: parseInt(storyId) },
          //   });
          //   let temp1 = {
          //     story: []
          //   };
          //   temp1.story.logo = fileMetadata.id;
          //   store.writeQuery({
          //     query: GET_STORY,
          //     variables: { id: parseInt(storyId) },
          //     data: temp1,
          //   });

          //   setStories(temp1.userStory);
          //   setLoaded(true);
          // },
        });
        setStoryLogo(fileMetadata.id);
      } else return message.error("Either file type or size is invalid.");
      notification.success({ message: "Logo updated" });
    } catch (err) {
      console.log({ err });
      onError(err.message, err.message, file);
    }
  };

  return (
    <React.Fragment>
      <SubHeader />

      <div className="container">
        <Row gutter={20}>
          {loaded ? (
            <>
              {" "}
              <Col md={8}>
                <Card className="cardBox">
                  <div className="cardContent w-100">
                    <div className="imageSec float-left pr-2 pb-1">
                      <Upload
                        className="editprofile-upload"
                        id="logo"
                        multiple={false}
                        showUploadList={false}
                        customRequest={(e) => handleUpload(e)}
                      >
                        {storyLogo ? (
                          <Avatar
                            size={90}
                            shape="square"
                            src={`${REACT_APP_NHOST_STORAGE_URL}/files/${storyLogo}`}
                          />
                        ) : (
                          <Avatar size={90} shape="square">
                            {storyName.charAt(0).toUpperCase().slice(0)}
                          </Avatar>
                        )}
                        <div className="uploadIcon">
                          <CustIcon type="upload-alt" />
                        </div>
                      </Upload>
                    </div>
                    <h3 className="mb-1">
                      <Text editable={{ onChange: setEditableTitle }}>
                        {storyName}
                      </Text>
                    </h3>
                    {storyDescription ? (
                      <Text editable={{ onChange: setEditableDescription }}>
                        {storyDescription}
                      </Text>
                    ) : (
                      <>
                        <span className="placeholderText text-black-50">
                          Add a description here
                        </span>
                        <Text
                          editable={{ onChange: setEditableDescription }}
                        ></Text>
                      </>
                    )}
                  </div>

                  <div className="cardButton">
                    <Link
                      to={{
                        pathname: `/story/history/selected/${storyId}`,
                      }}
                      onClick={() =>
                        updateStore({ id: storyId, type: "story" }, "history")
                      }
                      className="ant-btn ant-btn-primary"
                    >
                      History
                    </Link>
                    <Link
                      to={{
                        pathname: `/story/timeline/${storyId}`,
                      }}
                      className="ant-btn ant-btn-primary"
                    >
                      Timeline
                    </Link>
                    <Link
                      to={`/viewstory/${storyId}`}
                      className="ant-btn ant-btn-primary"
                    >
                      Complete View
                    </Link>
                  </div>
                </Card>
                <div className="mt-3 countSection_blk">
                  <div className="countSectionContainer_blk font-medium">
                    <span>Entities in story : </span>
                    <span>{entityCount}</span>
                  </div>
                </div>
              </Col>
              <Col md={16}>
                <Card
                  className="cardBox mb-4"
                  title="Characters"
                  extra={
                    <Button
                      type="link"
                      size="small"
                      onClick={() => addElement("Character")}
                    >
                      <PlusOutlined />
                      Add New
                    </Button>
                  }
                >
                  <div>
                    {characters.length > 0 ? (
                      characters.map((obj) => (
                        <Card className="mb-2 cardGrey">
                          <Row className="mb-3" gutter={20}>
                            <Col md={12}>
                              <div className="d-flex align-items-center">
                                <div className="col-auto p-0">
                                  {obj?.character?.logo ? (
                                    <Avatar
                                      src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.character.logo}`}
                                    />
                                  ) : (
                                    <Avatar>
                                      {obj.character.firstname
                                        .charAt(0)
                                        .toUpperCase()
                                        .slice(0)}
                                    </Avatar>
                                  )}
                                </div>
                                <div className="col">
                                  <Link
                                    className="storyname"
                                    onClick={() =>
                                      updateStore(obj.character, "character")
                                    }
                                    to={{
                                      pathname: `/story/character/${obj.character.id}`,
                                    }}
                                  >
                                    {obj.character.firstname}{" "}
                                    {obj.character.lastname}
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="cardBtnRight">
                                <div
                                  className={`${
                                    isInvisible ===
                                    "character-hide-" + obj.character.id
                                      ? "hideicon"
                                      : "hideicon showiconleft"
                                  }`}
                                >
                                  <Tooltip title="Edit">
                                    <Button
                                      onClick={() =>
                                        editElement(
                                          obj.character.id,
                                          "Character"
                                        )
                                      }
                                    >
                                      <CustIcon type="edit" />
                                    </Button>
                                  </Tooltip>
                                  <Popconfirm
                                    title={
                                      <>
                                        <div>
                                          This action will permanently delete
                                          your entity.{" "}
                                        </div>
                                        <div>
                                          Are you sure want to remove the
                                          character -{" "}
                                          <b>{obj.character.firstname}</b>?
                                        </div>
                                      </>
                                    }
                                    placement="topRight"
                                    onConfirm={() =>
                                      deleteElement(
                                        obj.character.id,
                                        "character"
                                      )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip title="Delete">
                                      <Button>
                                        <CustIcon
                                          className="deletepopover"
                                          type="delete"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Popconfirm>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      toggleDiv(
                                        obj.character.id,
                                        "character",
                                        "hide"
                                      )
                                    }
                                  >
                                    <CustIcon type="dots" />
                                  </Button>
                                </div>
                                <div
                                  className={`${
                                    isInvisible ===
                                    "character-hide-" + obj.character.id
                                      ? "showicon"
                                      : "hideicon  hide"
                                  }`}
                                >
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      toggleDiv(
                                        obj.character.id,
                                        "character",
                                        "show"
                                      )
                                    }
                                  >
                                    <CustIcon type="dots" />
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <>
                            <div>{obj.character.description}</div>
                          </>
                        </Card>
                      ))
                    ) : (
                      <Empty description={<span>No character added</span>} />
                    )}
                  </div>
                </Card>
                <Card
                  className="cardBox mb-4"
                  title="Locations"
                  extra={
                    <Button
                      type="link"
                      size="small"
                      onClick={() => addElement("Location")}
                    >
                      <PlusOutlined />
                      Add New
                    </Button>
                  }
                >
                  <div>
                    {locations.length > 0 ? (
                      locations.map((obj) => (
                        <Card className="mb-2 cardGrey">
                          <Row className="mb-3" gutter={20}>
                            <Col md={12}>
                              <div className="d-flex align-items-center">
                                <div className="col-auto p-0">
                                  {obj?.location?.logo ? (
                                    <Avatar
                                      src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.location.logo}`}
                                    />
                                  ) : (
                                    <Avatar>
                                      {obj.location.name
                                        .charAt(0)
                                        .toUpperCase()
                                        .slice(0)}
                                    </Avatar>
                                  )}
                                </div>
                                <div className="col">
                                  <Link
                                    className="storyname"
                                    onClick={() =>
                                      updateStore(obj.location, "location")
                                    }
                                    to={{
                                      pathname: `/story/location/${obj.location.id}`,
                                    }}
                                  >
                                    {obj.location.name}
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="cardBtnRight">
                                <div
                                  className={`${
                                    isInvisible ===
                                    "location-hide-" + obj.location.id
                                      ? "hideicon"
                                      : "hideicon showiconleft"
                                  }`}
                                >
                                  <Tooltip title="Edit">
                                    {" "}
                                    <Button
                                      onClick={() =>
                                        editElement(obj.location.id, "Location")
                                      }
                                    >
                                      <CustIcon type="edit" />
                                    </Button>
                                  </Tooltip>
                                  <Popconfirm
                                    title={
                                      <>
                                        <div>
                                          This action will permanently delete
                                          your entity.{" "}
                                        </div>
                                        <div>
                                          Are you sure want to remove the
                                          location - <b>{obj.location.name}</b>?
                                        </div>
                                      </>
                                    }
                                    placement="topRight"
                                    onConfirm={() =>
                                      deleteElement(obj.location.id, "location")
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip title="Delete">
                                      <Button>
                                        <CustIcon
                                          className="deletepopover"
                                          type="delete"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Popconfirm>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      toggleDiv(
                                        obj.location.id,
                                        "location",
                                        "hide"
                                      )
                                    }
                                  >
                                    <CustIcon type="dots" />
                                  </Button>
                                </div>
                                <div
                                  className={`${
                                    isInvisible ===
                                    "location-hide-" + obj.location.id
                                      ? "showicon"
                                      : "hideicon  hide"
                                  }`}
                                >
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      toggleDiv(
                                        obj.location.id,
                                        "location",
                                        "show"
                                      )
                                    }
                                  >
                                    <CustIcon type="dots" />
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <>
                            <div>{obj.location.description}</div>
                          </>
                        </Card>
                      ))
                    ) : (
                      <Empty description={<span>No Location added</span>} />
                    )}
                  </div>
                </Card>
                <Card
                  className="cardBox mb-4"
                  title="Events"
                  extra={
                    <Button
                      type="link"
                      size="small"
                      onClick={() => addElement("Event")}
                    >
                      <PlusOutlined />
                      Add New
                    </Button>
                  }
                >
                  <div>
                    {events.length > 0 ? (
                      events.map((obj) => (
                        <Card className="mb-2 cardGrey">
                          <Row className="mb-3" gutter={20}>
                            <Col md={12}>
                              <div className="d-flex align-items-center">
                                <div className="col-auto p-0">
                                  {obj?.event?.logo ? (
                                    <Avatar
                                      src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.event.logo}`}
                                    />
                                  ) : (
                                    <Avatar>
                                      {obj.event.name
                                        .charAt(0)
                                        .toUpperCase()
                                        .slice(0)}
                                    </Avatar>
                                  )}
                                </div>
                                <div className="col">
                                  <Link
                                    className="storyname"
                                    onClick={() =>
                                      updateStore(obj.event, "event")
                                    }
                                    to={{
                                      pathname: `/story/event/${obj.event.id}`,
                                    }}
                                  >
                                    {obj.event.name}
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="cardBtnRight">
                                <div
                                  className={`${
                                    isInvisible === "event-hide-" + obj.event.id
                                      ? "hideicon"
                                      : "hideicon showiconleft"
                                  }`}
                                >
                                  <Tooltip title="Edit">
                                    <Button
                                      onClick={() =>
                                        editElement(obj.event.id, "Event")
                                      }
                                    >
                                      <CustIcon type="edit" />
                                    </Button>
                                  </Tooltip>
                                  <Popconfirm
                                    title={
                                      <>
                                        <div>
                                          This action will permanently delete
                                          your entity.{" "}
                                        </div>
                                        <div>
                                          Are you sure want to remove the event
                                          - <b>{obj.event.name}</b>?
                                        </div>
                                      </>
                                    }
                                    placement="topRight"
                                    onConfirm={() =>
                                      deleteElement(obj.event.id, "event")
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip title="Delete">
                                      <Button>
                                        <CustIcon
                                          className="deletepopover"
                                          type="delete"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Popconfirm>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      toggleDiv(obj.event.id, "event", "hide")
                                    }
                                  >
                                    <CustIcon type="dots" />
                                  </Button>
                                </div>
                                <div
                                  className={`${
                                    isInvisible === "event-hide-" + obj.event.id
                                      ? "showicon"
                                      : "hideicon  hide"
                                  }`}
                                >
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      toggleDiv(obj.event.id, "event", "show")
                                    }
                                  >
                                    <CustIcon type="dots" />
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <>
                            <div>{obj.event.description}</div>
                          </>
                        </Card>
                      ))
                    ) : (
                      <Empty description={<span>No event added</span>} />
                    )}
                  </div>
                </Card>
                <Card
                  className="cardBox mb-4"
                  title="Items"
                  extra={
                    <Button
                      type="link"
                      size="small"
                      onClick={() => addElement("Item")}
                    >
                      <PlusOutlined />
                      Add New
                    </Button>
                  }
                >
                  <div>
                    {items.length > 0 ? (
                      items.map((obj) => (
                        <Card className="mb-2 cardGrey">
                          <Row className="mb-3" gutter={20}>
                            <Col md={12}>
                              <div className="d-flex align-items-center">
                                <div className="col-auto p-0">
                                  {obj?.item?.logo ? (
                                    <Avatar
                                      src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.item.logo}`}
                                    />
                                  ) : (
                                    <Avatar>
                                      {obj.item.name
                                        .charAt(0)
                                        .toUpperCase()
                                        .slice(0)}
                                    </Avatar>
                                  )}
                                </div>
                                <div className="col">
                                  <Link
                                    className="storyname"
                                    onClick={() =>
                                      updateStore(obj.item, "item")
                                    }
                                    to={{
                                      pathname: `/story/item/${obj.item.id}`,
                                    }}
                                  >
                                    {obj.item.name}
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="cardBtnRight">
                                <div
                                  className={`${
                                    isInvisible === "item-hide-" + obj.item.id
                                      ? "hideicon"
                                      : "hideicon showiconleft"
                                  }`}
                                >
                                  <Tooltip title="Edit">
                                    {" "}
                                    <Button
                                      onClick={() =>
                                        editElement(obj.item.id, "Item")
                                      }
                                    >
                                      <CustIcon type="edit" />
                                    </Button>
                                  </Tooltip>
                                  <Popconfirm
                                    title={
                                      <>
                                        <div>
                                          This action will permanently delete
                                          your entity.
                                        </div>
                                        <div>
                                          Are you sure want to remove the item -
                                          <b>{obj.item.name}</b>?
                                        </div>
                                      </>
                                    }
                                    placement="topRight"
                                    onConfirm={() =>
                                      deleteElement(obj.item.id, "item")
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip title="Delete">
                                      <Button>
                                        <CustIcon
                                          className="deletepopover"
                                          type="delete"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Popconfirm>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      toggleDiv(obj.item.id, "item", "hide")
                                    }
                                  >
                                    <CustIcon type="dots" />
                                  </Button>
                                </div>
                                <div
                                  className={`${
                                    isInvisible === "item-hide-" + obj.item.id
                                      ? "showicon"
                                      : "hideicon  hide"
                                  }`}
                                >
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      toggleDiv(obj.item.id, "item", "show")
                                    }
                                  >
                                    <CustIcon type="dots" />
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <>
                            <div>{obj.item.description}</div>
                          </>
                        </Card>
                      ))
                    ) : (
                      <Empty description={<span>No item added</span>} />
                    )}
                  </div>
                </Card>
              </Col>
            </>
          ) : (
            <>
              <Col md={8}>
                <Card className="mb-3">
                  <Skeleton active />
                </Card>
              </Col>
              <Col md={16}>
                <Card className="mb-3">
                  <Skeleton avatar active />
                </Card>
                <Card className="mb-3">
                  <Skeleton avatar active />
                </Card>
                <Card className="mb-3">
                  <Skeleton avatar active />
                </Card>
              </Col>
            </>
          )}
        </Row>
      </div>

      {showModal && (
        <AddElementComponent
          client={client}
          showModal={showModal}
          closeModal={closeModal}
          btnloading={btnloading}
          pagetype="story"
          setBtnLoading={(e) => setBtnLoader(e)}
          storyId={parseInt(storyId)}
          elementType={elementType}
          characters={characters.map((item) => ({
            ...item.character,
            label: item.character.firstname + " " + item.character.lastname,
            value: item.character.id,
          }))}
          locations={locations.map((item) => ({
            ...item.location,
            label: item.location.name,
            value: item.location.id,
          }))}
          events={events.map((item) => ({
            ...item.event,
            label: item.event.name,
            value: item.event.id,
          }))}
          items={items.map((item) => ({
            ...item.item,
            label: item.item.name,
            value: item.item.id,
          }))}
          timelines={timelines.map((item) => ({
            ...item.timeline,
            label:
              item.timeline.from_day +
              "," +
              item.timeline.from_month +
              "," +
              item.timeline.from_year +
              "-" +
              item.timeline.to_day +
              "," +
              item.timeline.to_month +
              "," +
              item.timeline.to_year,
            value: item.timeline.id,
          }))}
          onFinishSaveElement={(e) => onFinishSaveElement(e)}
        />
      )}
      {editShowModal && (
        <EditElementComponent
          client={client}
          editShowModal={editShowModal}
          closeModal={closeModal}
          btnloading={btnloading}
          setBtnLoading={(e) => setBtnLoader(e)}
          elementId={selectedElement}
          elementType={elementType}
          onFinishSaveElement={(e) => onFinishSaveElement(e)}
        />
      )}
    </React.Fragment>
  );
};

export default EditStory;
