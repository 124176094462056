import { useApolloClient } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deleteFile, uploadImage } from "../../apis/rest";
import { ADD_HISTORY, ADD_STORY } from "../../graphql/APIs";
import useStore from "../../utils/store";
import "./AddNewStory.css";

// const { REACT_APP_HB_URL } = process.env;
const { REACT_APP_NHOST_STORAGE_URL } = process.env;

const AddNewStory = () => {
  const client = useApolloClient();
  const [fileList, setFileList] = useState([]);
  const [uploadItems, setUploadItems] = useState([]);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const formRef = React.createRef();
  const [uploadProgress, setUploadProgress] = useState(null);

  const addStoryDetail = useStore((state) => state.addStoryDetail);

  const updateStore = (object) => {
    addStoryDetail(object);
  };

  useEffect(() => {
    localStorage.setItem("last_visited_url", window.location.pathname);
  });

  const onFinishFailed = (e) => {
    console.log(e);
  };

  const cancel = () => {
    navigate("/stories");
  };

  const onRemove = async () => {
    setUploadItems([]);
    if (fileList && fileList.length > 0) {
      let url = fileList[0].url;
      await deleteFile(url);
      setFileList([]);
    }
  };

  const onFinish = async (e) => {
    setLoading(true);
    try {
      let storyInput = {
        name: e.name ? e.name?.trim() : null,
        description: e.description ? e.description?.trim() : null,
        logo: uploadItems && uploadItems.length ? uploadItems[0].fileId : null,
      };
      const response = await client.mutate({
        mutation: ADD_STORY,
        variables: storyInput,
      });
      let historyInput = {
        entity_id: response.data.insert_story_one.id,
        name: e.name ? e.name?.trim() : null,
        entity_type: "story",
        description: e.description ? e.description?.trim() : null,
        firstname: null,
        lastname: null,
        logo: uploadItems && uploadItems.length ? uploadItems[0].fileId : null,
      };

      const result = await client.mutate({
        mutation: ADD_HISTORY,
        variables: historyInput,
      });

      if (!result.error) {
        message.success("Story added");
        setLoading(false);
        updateStore({
          id: response.data.insert_story_one.id,
          name: historyInput.name,
        });
        navigate(`/story/${response.data.insert_story_one.id}`);
      }
    } catch (error) {
      console.log("saveElement error", error);
      if (error) {
        setLoading(false);
        message.error("something went wrong");
      }
    }
  };
  const handleUpload = async (props) => {
    const { onSuccess, onError, file } = props;
    try {
      if (file.size / (1024 * 1000) <= 2 && file.type.includes("image")) {
        var formData = new FormData();
        formData.append("file", file);

        var options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: ({ total, loaded }) => {
            setUploadProgress({
              precent: Math.round((loaded / total) * 100).toFixed(2),
              file: file,
            });
          },
        };
        const { fileMetadata } = await uploadImage(file, options);

        let fileUrl = `${REACT_APP_NHOST_STORAGE_URL}/files/${fileMetadata.id}`;
        setUploadProgress(null);
        setUploadItems([
          ...uploadItems,
          { url: fileUrl, fileId: fileMetadata.id },
        ]);
        onSuccess(null, file);
        setFileList([{ url: fileUrl }]);
        notification.success({ message: "Logo added" });
      } else return message.error("Either file type or size is invalid.");
    } catch (err) {
      onError(err.message, err.message, file);
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div type="all" title="Add Story"></div>
        <Card className="commonBox">
          <Form
            ref={formRef}
            layout="vertical"
            id="myForm"
            name="myForm"
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
          >
            <Row>
              <Col span={24}>
                <Form.Item label="Logo">
                  <Upload
                    id="logo"
                    accept="image/*,.jpg, .jpeg, .png"
                    className={uploadProgress ? "jb_disabled" : "cust-upload"}
                    fileList={fileList}
                    multiple={false}
                    listType="picture-card"
                    onRemove={(e) => onRemove(e)}
                    showUploadList={{ showPreviewIcon: false }}
                    customRequest={(e) => handleUpload(e)}
                  >
                    {(!fileList.length && !uploadProgress && "Upload") ||
                      (uploadProgress && uploadProgress.precent + "%")}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Title"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter story title",
                    },
                    {
                      whitespace: true,
                      message:
                        "Story title should not contain only whitespaces!",
                    },
                    {
                      min: 3,
                      max: 100,
                      message:
                        "Story title must be between 3 to 100 characters",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      max: 500,
                      message: "Story description must be under 500 characters",
                    },
                  ]}
                >
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
            <div className="text-right">
              <Button
                loading={loading}
                form="myForm"
                htmlType="submit"
                type="primary"
                size="large"
                className="mr-3"
              >
                Submit
              </Button>
              <Button key="back" onClick={cancel} size="large">
                Cancel
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default AddNewStory;
