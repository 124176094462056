import { useAuthenticationStatus } from "@nhost/react";
import { message } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";
const Protected = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuthenticationStatus();

  if (isLoading) return;

  if (
    window.location.search &&
    window.location.search.search("error=invalid-ticket") > -1
  ) {
    return <Navigate to={`/${window.location.search}`} replace />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
export default Protected;
