import React from "react";
import { Col, Select, Row, Form, DatePicker, Popover } from "antd";
import monthlist from "../utils/months.json";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const { Option } = Select;
export const TimelineComponent = ({
  handleMonthChange,
  handleDayChange,
  daysFromArr,
  daysToArr,
  checked,
  handleTabEvent,
  selectTimeline,
  timelines,
}) => {
  const content = (
    <div>
      <p>You choose a year, year and month, year month and day.</p>
      <p>-just select "No Date" if you want to leave it unspecified</p>
    </div>
  );

  return (
    <React.Fragment>
      <Tabs
        name="displayDiv"
        className="tabpanel"
        defaultActiveKey="showCreateDate"
        onChange={handleTabEvent}
      >
        <TabPane tab="Choose a Date" key="showCreateDate" />
        <TabPane tab="Choose a Range" key="showDateRange" />

        {timelines.length && (
          <TabPane tab="Choose an Exisitng" key="showDate" />
        )}
        <TabPane tab="No Date" key="noDate" />
      </Tabs>
      {checked === "showDateRange" && (
        <div className="tabcontent">
          <div className="timeLine mb-3">
            <label>From -</label>
            <div className="row align-items-center">
              <div className="col pr-0">
                <Row gutter={[10, 10]} className="align-items-center">
                  <Col span={8}>
                    <Form.Item label="Year" name="from_year" className="mb-0">
                      <DatePicker
                        placeholder="YYYY"
                        picker="year"
                        suffixIcon={null}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Month" name="from_month" className="mb-0">
                      <Select
                        placeholder="MM"
                        onChange={(e) => handleMonthChange(e, "from")}
                        allowClear={true}
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {monthlist.map((d) => (
                          <Option key={d.name}>{d.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Day" name="from_day" className="mb-0">
                      <Select
                        placeholder="DD"
                        onChange={handleDayChange}
                        allowClear={true}
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {daysFromArr.map((d) => (
                          <Option key={d.id}>{d.id}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="col-auto mt-3 pt-1">
                <Popover content={content} trigger="hover">
                  <QuestionCircleFilled style={{ fontSize: "25px" }} />
                </Popover>
              </div>
            </div>
          </div>
          <div className="timeLine">
            <label>To -</label>
            <div className="row align-items-center">
              <div className="col pr-0">
                <Row gutter={[10, 10]} className="align-items-center">
                  <Col span={8}>
                    <Form.Item label="Year" name="to_year" className="mb-0">
                      <DatePicker
                        placeholder="YYYY"
                        picker="year"
                        suffixIcon={null}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Month" name="to_month" className="mb-0">
                      <Select
                        placeholder="MM"
                        onChange={(e) => handleMonthChange(e, "to")}
                        allowClear={true}
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {monthlist.map((d) => (
                          <Option key={d.name}>{d.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Day" name="to_day" className="mb-0">
                      <Select
                        placeholder="DD"
                        onChange={handleDayChange}
                        allowClear={true}
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {daysToArr.map((d) => (
                          <Option key={d.id}>{d.id}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="col-auto mt-3 pt-1">
                <Popover content={content} trigger="hover">
                  <QuestionCircleFilled style={{ fontSize: "25px" }} />
                </Popover>
              </div>
            </div>
          </div>
        </div>
      )}
      {checked === "showDate" && (
        <div className="tabcontent">
          <Row gutter={[10, 10]} className="align-items-center">
            <Col span={18}>
              <Select
                showSearch
                className="w-100"
                placeholder="Select a timeline"
                optionFilterProp="name"
                onChange={selectTimeline}
                filterOption={(input, option) =>
                  option.children[3].props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {timelines.map((timeline, index) => (
                  <Option key={timeline.id} value={timeline.id}>
                    {timeline?.from_month ? <>{timeline.from_month}</> : "Day"}{" "}
                    {timeline?.from_day && <>{timeline.from_day}</>}
                    {timeline?.from_year && <>{"," + timeline.from_year}</>}
                    {timeline?.to_month ||
                    timeline?.to_year ||
                    timeline?.to_day ? (
                      <>{" - "}</>
                    ) : (
                      ""
                    )}
                    {timeline?.to_month ||
                    timeline?.to_year ||
                    timeline?.to_day ? (
                      <>
                        {timeline?.to_month ? <>{timeline.to_month}</> : "Day"}{" "}
                        {timeline?.to_day && <>{timeline.to_day}</>}
                        {timeline?.to_year && <>{"," + timeline.to_year}</>}
                      </>
                    ) : (
                      ""
                    )}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
      )}
      {checked === "showCreateDate" && (
        <div className="tabcontent">
          <div className="timeLine">
            <div className="row align-items-center">
              <div className="col pr-0">
                <Row gutter={[10, 10]} className="align-items-center">
                  <Col span={8}>
                    <Form.Item label="Year" name="from_year" className="mb-0">
                      <DatePicker
                        placeholder="Select Year"
                        picker="year"
                        suffixIcon={null}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Month" name="from_month" className="mb-0">
                      <Select
                        placeholder="Select Month"
                        onChange={(e) => handleMonthChange(e, "from")}
                        allowClear={true}
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {monthlist.map((d) => (
                          <Option key={d.name}>{d.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Day" name="from_day" className="mb-0">
                      <Select
                        placeholder="Select Day"
                        onChange={handleDayChange}
                        allowClear={true}
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {daysFromArr.map((d) => (
                          <Option key={d.id}>{d.id}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="col-auto mt-3 pt-1">
                <Popover content={content} trigger="hover">
                  <QuestionCircleFilled style={{ fontSize: "25px" }} />
                </Popover>
              </div>
            </div>
          </div>
        </div>
      )}
      {checked === "noDate" && (
        <div className="tabcontent">
          <div className="nodate">No date selected</div>
        </div>
      )}
    </React.Fragment>
  );
};
