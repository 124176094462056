import { useLazyQuery } from "@apollo/client";
import { useAuthenticationStatus } from "@nhost/react";
import { Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppFooter } from "../Components/FooterPage";
import { AppHeader } from "../Components/HeaderPage";
import { GET_USER_DETAILS } from "../graphql/APIs";
import "./Template.css";

const { Content } = Layout;

export const Template = () => {
  const { isLoading, isAuthenticated } = useAuthenticationStatus();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [getUserDetails, { data }] = useLazyQuery(GET_USER_DETAILS);
  let navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;

    if (
      window.location.search &&
      window.location.search.search("error=invalid-ticket") > -1
    ) {
      message.error("The link is invalid or expired");
      navigate("/login");
      return;
    }

    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
    getUserDetails();

    if (
      window.location.hash &&
      window.location.hash.search("type=passwordReset") > -1
    ) {
      navigate("/change-password");
      return;
    }

    const lastVisitedUrl = localStorage.last_visited_url;
    if (lastVisitedUrl && lastVisitedUrl !== "/myStories") {
      navigate(lastVisitedUrl);
      return;
    }

    navigate("/stories");
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (!data) return;
    setIsSubscribed(
      !!data.users[0].userSubscription &&
        !data.users[0].userSubscription.isCancel
    );
  }, [data]);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Layout>
      <AppHeader isSubscribed={isSubscribed} />
      <Content className="wrapper">
        <Outlet context={{ isSubscribed }} />
      </Content>
      <AppFooter />
    </Layout>
  );
};
