import React, { useEffect, useState } from "react";

export const DateComponent = ({ obj }) => {
  const [dateobj, setResponse] = useState([]);

  useEffect(() => {
    const Type = obj?.type;
    switch (Type) {
          case "date":
              if(obj.to_day !== obj.from_day && obj.to_month !== obj.from_month){
                  obj.from_month = null;
                  obj.from_day = null;
                  obj.to_year = null;
                  obj.to_month = null;
                  obj.to_day = null
                  setResponse(obj)
              }
              else if(obj.to_day === obj.from_day && obj.to_month !== obj.from_month){
                  obj.from_month = null;
                  obj.to_year = null;
                  obj.to_month = null;
                  obj.to_day = null
                  setResponse(obj)
              }
              else if(obj.to_day !== obj.from_day && obj.to_month === obj.from_month){
                  obj.from_day = null;
                  obj.to_year = null;
                  obj.to_month = null;
                  obj.to_day = null
                  setResponse(obj)
              }
              else if(obj.to_day === obj.from_day && obj.to_month === obj.from_month){
                  obj.to_year = null;
                  obj.to_month = null;
                  obj.to_day = null
                  setResponse(obj)
              }
              break;
          case "range":
              if((obj.from_month === "January" && obj.to_month === "December") && (obj.to_day === "31" && obj.from_day === "01")){
                  obj.from_month = null;
                  obj.from_day = null;
                  obj.to_month = null;
                  obj.to_day = null
                  setResponse(obj)
              }
              else if((obj.from_month === "January" && obj.to_month === "December") && ((obj.to_day === "31" && obj.from_day !== "01") || (obj.to_day !== "31" && obj.from_day === "01") || (obj.to_day !== "31" && obj.from_day === "01"))){
                  obj.from_month = null;
                  obj.to_month = null
                  setResponse(obj)
              }
              else if(((obj.from_month !== "January" && obj.to_month === "December") || (obj.from_month === "January" && obj.to_month !== "December") || (obj.from_month !== "January" && obj.to_month !== "December")) && (obj.to_day === "31" && obj.from_day === "01")){
                  obj.from_day = null;
                  obj.to_day = null
                  setResponse(obj)
              }
              else if(((obj.from_month !== "January" && obj.to_month === "December") || (obj.from_month === "January" && obj.to_month !== "December") || (obj.from_month !== "January" && obj.to_month !== "December")) && ((obj.to_day === "31" && obj.from_day !== "01") || (obj.to_day !== "31" && obj.from_day === "01") || (obj.to_day !== "31" && obj.from_day !== "01"))){
                  setResponse(obj)
              }
              break;
          default:
      }
  },[obj])

  return (
    <>{dateobj ? <>{(dateobj?.from_month || dateobj?.from_year || dateobj?.from_day || dateobj?.to_month || dateobj?.to_year || dateobj?.to_day)? <>{ <div>From: {dateobj?.from_month && <>{dateobj.from_month}</> }{" "}{dateobj?.from_day && <>{dateobj.from_day + ","}</>}{dateobj?.from_year && <>{ dateobj.from_year}</>} {dateobj?.to_day || dateobj?.to_month || dateobj?.to_year ? <>{"To:"}</> : ""} {dateobj?.to_month && <>{dateobj.to_month}</> }{" "}{dateobj?.to_day && <>{dateobj.to_day + ","}</>}{dateobj?.to_year && <>{dateobj?.to_year}</>}</div>}</> : null}</> : null}</>
  );
};
