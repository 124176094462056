import React from 'react';
import './Loader.css';
import { Spin } from 'antd';

const Loader = () => {
		return (
			<div className="loaderConainer h-100 w-100 d-flex flex-row align-items-center justify-content-center">
				<Spin />
			</div>
		);
}

export { Loader };
