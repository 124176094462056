
import moment from 'moment';

export const processDate = async(to_year, to_month, to_day, from_year, from_month, from_day, radioType) => {
    const response = {
        to_year, to_month, to_day, from_year, from_month, from_day
    }
    const month31 = ["January", "March", "May", "July", "August", "October", "December"]
    const month30 = ["April", "June", "September", "November"]

    switch (radioType) {
        case "date":
            if(from_day && from_month && from_year){
                response.to_year = from_year;
                response.to_month = from_month;
                response.to_day = from_day;
                return response;
            }
            else if(!from_day && from_month && from_year){
                response.to_year = from_year;
                response.to_month = from_month;
                response.from_day = "01";
                if(month30.indexOf(from_month) > -1){
                    response.to_day = "30"; 
                }
                else if(month31.indexOf(from_month) > -1){
                    response.to_day = "31"; 
                }
                else{
                    response.to_day = "28"; 
                }
                return response;
            }
            else if(from_day && !from_month && from_year){
                var date = moment();
                response.to_year = from_year;
                response.to_day = from_day;
                response.from_month = date.format('MMMM');
                response.to_month = date.format('MMMM');
                return response;
            }
            else if(!from_day && !from_month && from_year){
                response.to_year = from_year;
                response.from_month = "January";
                response.to_month = "December";
                response.from_day = "01";
                response.to_day = "31";
                return response;
            }
            break;
        case "range":
            if(to_day && to_month && to_year && from_day && from_month && from_year){
                return response;
            }
            if(!to_day && !to_month && to_year && !from_day && !from_month && from_year){
                response.from_day = "01";
                response.from_month = "January";
                response.to_day = "31";
                response.to_month = "December";
                return response;
            }
            if(!to_day && to_month && to_year && !from_day && from_month && from_year){
                response.from_day = "01";
                if(month30.indexOf(to_month) > -1){
                    response.to_day = "30"; 
                }
                else if(month31.indexOf(to_month) > -1){
                    response.to_day = "31"; 
                }
                else{
                    response.to_day = "28"; 
                }
                return response;
            }
            if(to_day && !to_month && to_year && from_day && !from_month && from_year){
                date = moment();
                response.from_month = date.format('MMMM');
                response.to_month = date.format('MMMM');
                return response;
            }
            break;
        default:
    }
}