import { Typography } from "antd";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import "./Payment.css";

const { Title } = Typography;

const Payment = () => {
  const { status, sessionId } = useParams();
  const location = useLocation();
  console.log({ location, status, sessionId });

  return (
    <React.Fragment>
      <div className="container pt-3">
        <div className="d-flex flex-row align-items-center justify-content-between my-2">
          <Title level={4} strong="false" className="pageTitle m-0">
            Payment Status {status.toUpperCase()}
          </Title>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Payment;
