import React, { useState } from "react";
import "./Login.css";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Layout,
  Card,
  Carousel,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { signup } from "../../apis/rest";

export const SignUp = () => {
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const onFinish = async (e) => {
    setLoading(true);
    try {
      let signupModel = {
        email: e.email,
        password: e.password,
        options: {
          displayName: e.displayname,
        },
      };

      try {
        let { error } = await signup(signupModel);
        if (error) {
          message.error(error.message);
        } else {
          message.success(
            "Email verification link is sent on registered email. Please verify mail to activate Account."
          );
          navigate("/login");
        }
        setLoading(false);
      } catch (error) {
        console.log({ error });
        message.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "Unable to Signup."
        );
        setLoading(false);
      }
    } catch (error) {
      console.log({ error });
      if (error) {
        message.error("something went wrong");
      }
    }
  };

  const onClick = () => {
    navigate("/login");
  };

  return (
    <React.Fragment>
      <Layout className="loginPage d-flex flex-column align-items-center justify-content-center">
        <div className="container">
          <Card className="loginCard">
            <div className="loginForm_blk">
              <Row>
                <Col xs={24} sm={9} md={9}>
                  <div className="loginFormLeft">
                    <div className="loginForm_blk w-100">
                      <div className="loginLogo">
                        <img
                          alt="jebidu"
                          src={require("../../Assets/images/login_logo.png")}
                        />
                      </div>
                      <Form
                        ref={formRef}
                        layout="vertical"
                        id="myForm"
                        name="myForm"
                        onFinish={onFinish}
                      >
                        <Form.Item
                          label="Display Name"
                          name="displayname"
                          rules={[
                            {
                              required: true,
                              message: "Please input your display name!",
                            },
                            {
                              min: 1,
                              max: 100,
                            },
                          ]}
                        >
                          <Input placeholder="Enter display name" />
                        </Form.Item>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid Email!",
                            },
                            {
                              required: true,
                              message: "Please input your Email!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter email" />
                        </Form.Item>
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                            {
                              min: 6,
                              max: 20,
                              message:
                                "Password must be between 6 to 20 characters",
                            },
                          ]}
                        >
                          <Input.Password
                            className="border-0 p-0 passwordInput"
                            placeholder="Enter password"
                          />
                        </Form.Item>
                        <Form.Item
                          name="confirm"
                          label="Confirm Password"
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  "The two passwords that you entered do not match!"
                                );
                              },
                            }),
                          ]}
                          className="confirmPassword"
                        >
                          <Input.Password
                            className="border-0 p-0 passwordInput"
                            placeholder="Enter password again"
                          />
                        </Form.Item>
                      </Form>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button
                          loading={loading}
                          form="myForm"
                          htmlType="submit"
                          type="primary"
                          size="large"
                        >
                          Submit
                        </Button>
                        <Link
                          to="/login"
                          onClick={onClick}
                          className="forgotPasswordTxt"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={15} md={15}>
                  <div className="loginFormRight signUpRight">
                    <Carousel autoplay autoplaySpeed={10000} effect="fade">
                      <div className="loginImg">
                        <img
                          alt="1"
                          src={require("../../Assets/images/slider-img1.jpg")}
                        />
                      </div>
                      <div className="loginImg">
                        <img
                          alt="2"
                          src={require("../../Assets/images/slider-img2.jpg")}
                        />
                      </div>
                      <div className="loginImg">
                        <img
                          alt="3"
                          src={require("../../Assets/images/slider-img3.jpg")}
                        />
                      </div>
                      <div className="loginImg">
                        <img
                          alt="4"
                          src={require("../../Assets/images/slider-img4.jpg")}
                        />
                      </div>
                    </Carousel>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Layout>
    </React.Fragment>
  );
};
