import React, { useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Upload,
  Select,
  notification,
  Modal,
  Button,
  message,
} from "antd";
import {
  ADD_CHARACTER,
  ADD_EVENT,
  ADD_ITEM,
  ADD_LOCATION,
  ADD_HISTORY,
  ADD_ENTITY_TIMELINE,
} from "../../graphql/APIs";
import TextArea from "antd/lib/input/TextArea";
import "./AddElementComponent.css";
import { TimelineComponent } from "../TimelineComponent";
import { processDate } from "../../utils/modifydate";
import { uploadImage, deleteFile } from "../../apis/rest";
// const { REACT_APP_HB_URL } = process.env;
const { REACT_APP_NHOST_STORAGE_URL } = process.env;

const { Option, OptGroup } = Select;

const AddElementComponent = ({
  characters,
  locations,
  events,
  items,
  elementType,
  onFinishSaveElement,
  storyId,
  timelines,
  closeModal,
  showModal,
  setBtnLoading,
  btnloading,
  pagetype,
  characterId,
  locationId,
  eventId,
  itemId,
  character,
  location,
  event,
  item,
  client,
}) => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploadItems, setUploadItems] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [selectedTimeline, setSelectedTimeline] = useState(null);
  const [checked, setChecked] = useState("showCreateDate");

  const formRef = React.createRef();

  const days31 = [
    { id: "01" },
    { id: "02" },
    { id: "03" },
    { id: "04" },
    { id: "05" },
    { id: "06" },
    { id: "07" },
    { id: "08" },
    { id: "09" },
    { id: "10" },
    { id: "11" },
    { id: "12" },
    { id: "13" },
    { id: "14" },
    { id: "15" },
    { id: "16" },
    { id: "17" },
    { id: "18" },
    { id: "19" },
    { id: "20" },
    { id: "21" },
    { id: "22" },
    { id: "23" },
    { id: "24" },
    { id: "25" },
    { id: "26" },
    { id: "27" },
    { id: "28" },
    { id: "29" },
    { id: "30" },
    { id: "31" },
  ];
  const days30 = [
    { id: "01" },
    { id: "02" },
    { id: "03" },
    { id: "04" },
    { id: "05" },
    { id: "06" },
    { id: "07" },
    { id: "08" },
    { id: "09" },
    { id: "10" },
    { id: "11" },
    { id: "12" },
    { id: "13" },
    { id: "14" },
    { id: "15" },
    { id: "16" },
    { id: "17" },
    { id: "18" },
    { id: "19" },
    { id: "20" },
    { id: "21" },
    { id: "22" },
    { id: "23" },
    { id: "24" },
    { id: "25" },
    { id: "26" },
    { id: "27" },
    { id: "28" },
    { id: "29" },
    { id: "30" },
  ];
  const days28 = [
    { id: "01" },
    { id: "02" },
    { id: "03" },
    { id: "04" },
    { id: "05" },
    { id: "06" },
    { id: "07" },
    { id: "08" },
    { id: "09" },
    { id: "10" },
    { id: "11" },
    { id: "12" },
    { id: "13" },
    { id: "14" },
    { id: "15" },
    { id: "16" },
    { id: "17" },
    { id: "18" },
    { id: "19" },
    { id: "20" },
    { id: "21" },
    { id: "22" },
    { id: "23" },
    { id: "24" },
    { id: "25" },
    { id: "26" },
    { id: "27" },
    { id: "28" },
  ];
  const month31 = [
    "January",
    "March",
    "May",
    "July",
    "August",
    "October",
    "December",
  ];
  const month30 = ["April", "June", "September", "November"];

  const [daysToArr, setDaysToArr] = useState(days31);
  const [daysFromArr, setDaysFromArr] = useState(days31);

  const onFinish = (e) => {
    saveElement(e);
  };

  const onFinishFailed = (e) => {
    console.log(e);
  };

  const handleTabEvent = (key) => {
    setChecked(key);
  };

  const handleMonthChange = (key, type) => {
    if (type === "to") {
      if (month31.indexOf(key) > -1) setDaysToArr(days31);
      else if (month30.indexOf(key) > -1) setDaysToArr(days30);
      else setDaysToArr(days28);
    } else {
      if (month31.indexOf(key) > -1) setDaysFromArr(days31);
      else if (month30.indexOf(key) > -1) setDaysFromArr(days30);
      else setDaysFromArr(days28);
    }
  };

  const handleUpload = async (props) => {
    const { onSuccess, onError, file } = props;
    try {
      if (file.size / (1024 * 1000) <= 2 && file.type.includes("image")) {
        var formData = new FormData();
        formData.append("file", file);

        var options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: ({ total, loaded }) => {
            setUploadProgress({
              precent: Math.round((loaded / total) * 100).toFixed(2),
              file: file,
            });
          },
        };
        const { fileMetadata } = await uploadImage(file, options);

        let fileUrl = `${REACT_APP_NHOST_STORAGE_URL}/files/${fileMetadata.id}`;
        setUploadItems([
          ...uploadItems,
          { url: fileUrl, fileId: fileMetadata.id },
        ]);
        onSuccess(null, file);
        setFileList([{ url: fileUrl }]);
      } else return message.error("Either file type or size is invalid.");
      //setUploadProgress(null);
      notification.success({ message: "Logo added" });
    } catch (err) {
      onError(err.message, err.message, file);
    }
  };

  const onRemove = async () => {
    setUploadItems([]);
    if (fileList && fileList.length > 0) {
      let url = fileList[0].url;
      await deleteFile(url);
      setFileList([]);
    }
  };

  const selectTimeline = (e) => {
    setSelectedTimeline(e);
  };

  const saveElement = async (e) => {
    setBtnLoading(true);
    try {
      let timelineData = {};
      let response = {};
      let timelineObj = {
        from_year: e.from_year?.format("YYYY"),
        to_year: e.to_year?.format("YYYY"),
        to_month: e.to_month,
        from_month: e.from_month,
        from_day: e.from_day,
        to_day: e.to_day,
        type: e.type,
      };
      let radioType = "date";
      if (
        e.from_year ||
        e.to_year ||
        e.from_month ||
        e.to_month ||
        e.from_day ||
        e.to_day
      ) {
        if (checked === "showDateRange") {
          radioType = "range";
          if (
            (e.from_month || e.to_month || e.from_day || e.to_day) &&
            (!e.from_year || !e.to_year)
          ) {
            notification.warning({
              message:
                "Please select the year for both the dropdown as Year is needed",
            });
            setBtnLoading(false);
            return;
          }
          if ((e.from_year && !e.to_year) || (!e.from_year && e.to_year)) {
            notification.warning({
              message:
                "Please select the year for both the dropdown as Range is selected",
            });
            setBtnLoading(false);
            return;
          }
          if (
            e.from_year &&
            e.to_year &&
            ((e.from_month && !e.to_month) || (!e.from_month && e.to_month))
          ) {
            notification.warning({
              message:
                "Please select the month for both the dropdown as Range is selected",
            });
            setBtnLoading(false);
            return;
          }
          if (
            e.from_year &&
            e.to_year &&
            ((e.from_day && !e.to_day) || (!e.from_day && e.to_day))
          ) {
            notification.warning({
              message:
                "Please select the date for both the dropdown as Range is selected",
            });
            setBtnLoading(false);
            return;
          } else
            response = await processDate(
              e.to_year?.format("YYYY"),
              e.to_month,
              e.to_day,
              e.from_year?.format("YYYY"),
              e.from_month,
              e.from_day,
              radioType
            );

          timelineObj = {
            from_year: response.from_year,
            to_year: response.to_year,
            to_month: response.to_month,
            from_month: response.from_month,
            from_day: response.from_day,
            to_day: response.to_day,
            type: radioType,
          };
          timelineObj.storyId = storyId;
          timelineData = await client.mutate({
            mutation: ADD_ENTITY_TIMELINE,
            variables: timelineObj,
          });
        }
        if (checked === "showCreateDate") {
          radioType = "date";
          if ((e.from_month || e.from_day) && !e.from_year) {
            notification.warning({
              message: "Please select the year as Year is needed",
            });
            setBtnLoading(false);
            return;
          }
          response = await processDate(
            e.to_year,
            e.to_month,
            e.to_day,
            e.from_year?.format("YYYY"),
            e.from_month,
            e.from_day,
            radioType
          );

          timelineObj = {
            from_year: response.from_year,
            to_year: response.to_year,
            to_month: response.to_month,
            from_month: response.from_month,
            from_day: response.from_day,
            to_day: response.to_day,
            type: radioType,
          };
          timelineObj.storyId = storyId;
          timelineData = await client.mutate({
            mutation: ADD_ENTITY_TIMELINE,
            variables: timelineObj,
          });
        }
      }

      switch (pagetype) {
        case "story":
          switch (elementType) {
            case "Character":
              let characterInput = {
                firstname: e.firstname ? e.firstname?.trim() : null,
                lastname: e.lastname ? e.lastname?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
                locationId: selectedLocation,
                eventId: selectedEvent,
                itemId: selectedItem,
              };
              if (timelineData.data) {
                characterInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                characterInput.timelineId = selectedTimeline;
              }
              const character = await client.mutate({
                mutation: ADD_CHARACTER,
                variables: characterInput,
              });
              let characterHistoryInput = {
                entity_id: character.data.insert_character_one.id,
                entity_type: "character",
                description: e.description ? e.description?.trim() : null,
                firstname: e.firstname ? e.firstname?.trim() : null,
                name: null,
                lastname: e.lastname ? e.lastname?.trim() : null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: characterHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Event":
              let eventInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                locationId: selectedLocation,
                characterId: selectedCharacter,
                itemId: selectedItem,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                eventInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                eventInput.timelineId = selectedTimeline;
              }
              const event = await client.mutate({
                mutation: ADD_EVENT,
                variables: eventInput,
              });
              let eventHistoryInput = {
                entity_id: event.data.insert_event_one.id,
                entity_type: "event",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: eventHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Item":
              let itemInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                locationId: selectedLocation,
                eventId: selectedEvent,
                characterId: selectedCharacter,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                itemInput.timelineId = timelineData.data.insert_timeline_one.id;
              } else {
                itemInput.timelineId = selectedTimeline;
              }
              const item = await client.mutate({
                mutation: ADD_ITEM,
                variables: itemInput,
              });
              let itemHistoryInput = {
                entity_id: item.data.insert_item_one.id,
                entity_type: "item",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: itemHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Location":
              let locationInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                characterId: selectedCharacter,
                eventId: selectedEvent,
                itemId: selectedItem,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                locationInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                locationInput.timelineId = selectedTimeline;
              }
              const locationResponse = await client.mutate({
                mutation: ADD_LOCATION,
                variables: locationInput,
              });
              let locationHistoryInput = {
                entity_id: locationResponse.data.insert_location_one.id,
                entity_type: "location",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: locationHistoryInput,
              });
              onFinishSaveElement();
              break;
            default:
          }
          message.success(`New ${elementType} added`);
          break;
        case "character":
          switch (elementType) {
            case "Event":
              let eventInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                locationId: selectedLocation,
                characterId: characterId,
                itemId: selectedItem,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                eventInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                eventInput.timelineId = selectedTimeline;
              }
              const event = await client.mutate({
                mutation: ADD_EVENT,
                variables: eventInput,
              });
              let eventHistoryInput = {
                entity_id: event.data.insert_event_one.id,
                entity_type: "event",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: eventHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Item":
              let itemInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                locationId: selectedLocation,
                eventId: selectedEvent,
                characterId: characterId,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                itemInput.timelineId = timelineData.data.insert_timeline_one.id;
              } else {
                itemInput.timelineId = selectedTimeline;
              }
              const item = await client.mutate({
                mutation: ADD_ITEM,
                variables: itemInput,
              });
              let itemHistoryInput = {
                entity_id: item.data.insert_item_one.id,
                entity_type: "item",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: itemHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Location":
              let locationInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                characterId: characterId,
                eventId: selectedEvent,
                itemId: selectedItem,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                locationInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                locationInput.timelineId = selectedTimeline;
              }
              const locationResponse = await client.mutate({
                mutation: ADD_LOCATION,
                variables: locationInput,
              });
              let locationHistoryInput = {
                entity_id: locationResponse.data.insert_location_one.id,
                entity_type: "location",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: locationHistoryInput,
              });
              onFinishSaveElement();
              break;
            default:
          }
          message.success(`New ${elementType} added`);
          break;
        case "location":
          switch (elementType) {
            case "Event":
              let eventInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                locationId: locationId,
                characterId: selectedCharacter,
                itemId: selectedItem,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                eventInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                eventInput.timelineId = selectedTimeline;
              }
              const event = await client.mutate({
                mutation: ADD_EVENT,
                variables: eventInput,
              });
              let eventHistoryInput = {
                entity_id: event.data.insert_event_one.id,
                entity_type: "event",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: eventHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Item":
              let itemInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                locationId: locationId,
                eventId: selectedEvent,
                characterId: selectedCharacter,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                itemInput.timelineId = timelineData.data.insert_timeline_one.id;
              } else {
                itemInput.timelineId = selectedTimeline;
              }
              const item = await client.mutate({
                mutation: ADD_ITEM,
                variables: itemInput,
              });
              let itemHistoryInput = {
                entity_id: item.data.insert_item_one.id,
                entity_type: "item",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: itemHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Character":
              let characterInput = {
                firstname: e.firstname ? e.firstname?.trim() : null,
                lastname: e.lastname ? e.lastname?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
                locationId: locationId,
                eventId: selectedEvent,
                itemId: selectedItem,
              };
              if (timelineData.data) {
                characterInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                characterInput.timelineId = selectedTimeline;
              }
              const character = await client.mutate({
                mutation: ADD_CHARACTER,
                variables: characterInput,
              });
              let characterHistoryInput = {
                entity_id: character.data.insert_character_one.id,
                entity_type: "character",
                description: e.description ? e.description?.trim() : null,
                firstname: e.firstname ? e.firstname?.trim() : null,
                name: null,
                lastname: e.lastname ? e.lastname?.trim() : null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: characterHistoryInput,
              });
              onFinishSaveElement();
              break;
            default:
          }
          message.success(`New ${elementType} added`);
          break;
        case "event":
          switch (elementType) {
            case "Location":
              let locationInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                characterId: selectedCharacter,
                eventId: eventId,
                itemId: selectedItem,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                locationInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                locationInput.timelineId = selectedTimeline;
              }
              const locationResponse = await client.mutate({
                mutation: ADD_LOCATION,
                variables: locationInput,
              });
              let locationHistoryInput = {
                entity_id: locationResponse.data.insert_location_one.id,
                entity_type: "location",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: locationHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Item":
              let itemInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                locationId: selectedLocation,
                eventId: eventId,
                characterId: selectedCharacter,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                itemInput.timelineId = timelineData.data.insert_timeline_one.id;
              } else {
                itemInput.timelineId = selectedTimeline;
              }
              const item = await client.mutate({
                mutation: ADD_ITEM,
                variables: itemInput,
              });
              let itemHistoryInput = {
                entity_id: item.data.insert_item_one.id,
                entity_type: "item",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: itemHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Character":
              let characterInput = {
                firstname: e.firstname ? e.firstname?.trim() : null,
                lastname: e.lastname ? e.lastname?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
                locationId: selectedLocation,
                eventId: eventId,
                itemId: selectedItem,
              };
              if (timelineData.data) {
                characterInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                characterInput.timelineId = selectedTimeline;
              }
              const character = await client.mutate({
                mutation: ADD_CHARACTER,
                variables: characterInput,
              });
              let characterHistoryInput = {
                entity_id: character.data.insert_character_one.id,
                entity_type: "character",
                description: e.description ? e.description?.trim() : null,
                firstname: e.firstname ? e.firstname?.trim() : null,
                name: null,
                lastname: e.lastname ? e.lastname?.trim() : null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: characterHistoryInput,
              });
              onFinishSaveElement();
              break;

            default:
          }
          message.success(`New ${elementType} added`);
          break;
        case "item":
          switch (elementType) {
            case "Location":
              let locationInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                characterId: selectedCharacter,
                eventId: selectedEvent,
                itemId: itemId,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                locationInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                locationInput.timelineId = selectedTimeline;
              }
              const locationResponse = await client.mutate({
                mutation: ADD_LOCATION,
                variables: locationInput,
              });
              let locationHistoryInput = {
                entity_id: locationResponse.data.insert_location_one.id,
                entity_type: "location",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: locationHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Event":
              let eventInput = {
                name: e.name ? e.name?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                locationId: selectedLocation,
                characterId: selectedCharacter,
                itemId: itemId,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              if (timelineData.data) {
                eventInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                eventInput.timelineId = selectedTimeline;
              }
              const event = await client.mutate({
                mutation: ADD_EVENT,
                variables: eventInput,
              });
              let eventHistoryInput = {
                entity_id: event.data.insert_event_one.id,
                entity_type: "event",
                description: e.description ? e.description?.trim() : null,
                firstname: null,
                name: e.name ? e.name?.trim() : null,
                lastname: null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: eventHistoryInput,
              });
              onFinishSaveElement();
              break;
            case "Character":
              let characterInput = {
                firstname: e.firstname ? e.firstname?.trim() : null,
                lastname: e.lastname ? e.lastname?.trim() : null,
                description: e.description ? e.description?.trim() : null,
                storyId: storyId,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
                locationId: selectedLocation,
                eventId: selectedEvent,
                itemId: itemId,
              };
              if (timelineData.data) {
                characterInput.timelineId =
                  timelineData.data.insert_timeline_one.id;
              } else {
                characterInput.timelineId = selectedTimeline;
              }
              const character = await client.mutate({
                mutation: ADD_CHARACTER,
                variables: characterInput,
              });
              let characterHistoryInput = {
                entity_id: character.data.insert_character_one.id,
                entity_type: "character",
                description: e.description ? e.description?.trim() : null,
                firstname: e.firstname ? e.firstname?.trim() : null,
                name: null,
                lastname: e.lastname ? e.lastname?.trim() : null,
                logo:
                  uploadItems && uploadItems.length
                    ? uploadItems[0].fileId
                    : null,
              };
              await client.mutate({
                mutation: ADD_HISTORY,
                variables: characterHistoryInput,
              });
              onFinishSaveElement();
              break;
            default:
          }
          message.success(`New ${elementType} added`);
          break;
        default:
      }
      setBtnLoading(false);
    } catch (error) {
      console.log({ error });
      setBtnLoading(false);
    }
  };

  const onChange = (value) => {
    if (value?.indexOf("_character") >= 0) {
      setSelectedCharacter(parseInt(value.split("_character")[0]));
    }
    if (value?.indexOf("_event") >= 0) {
      setSelectedEvent(parseInt(value.split("_event")[0]));
    }
    if (value?.indexOf("_location") >= 0) {
      setSelectedLocation(parseInt(value.split("_location")[0]));
    }
    if (value?.indexOf("_item") >= 0) {
      setSelectedItem(parseInt(value.split("_item")[0]));
    }
  };

  const onClear = () => {
    setSelectedCharacter(null);
    setSelectedEvent(null);
    setSelectedLocation(null);
    setSelectedItem(null);
  };

  return (
    <Modal
      title={"Add " + elementType}
      visible={showModal}
      onCancel={closeModal}
      width={600}
      destroyOnClose={true}
      footer={[
        <Button
          key="back"
          onClick={closeModal}
          size="large"
          className="btnwidth"
        >
          Cancel
        </Button>,
        <Button
          loading={btnloading}
          form="myForm"
          htmlType="submit"
          type="primary"
          size="large"
          className="btnwidth"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        ref={formRef}
        layout="vertical"
        id="myForm"
        name="myForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={`Upload a logo for ${elementType}`}>
              <Upload
                className={uploadProgress ? "jb_disabled" : "cust-upload"}
                id="logo"
                fileList={fileList}
                multiple={false}
                listType="picture-card"
                onRemove={(e) => onRemove(e)}
                customRequest={(e) => handleUpload(e)}
              >
                {(!fileList.length && !uploadProgress && "Upload") ||
                  (uploadProgress && uploadProgress.precent + "%")}
              </Upload>
            </Form.Item>
          </Col>
          {elementType === "Character" ? (
            <>
              <Col span={12}>
                <Form.Item
                  label="First name or Only name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please enter firstname",
                    },
                    {
                      whitespace: true,
                      message: "Name should not contain only whitespaces!",
                    },
                    {
                      min: 1,
                      max: 100,
                      message: "Firstname must be between 1 to 100 characters",
                    },
                  ]}
                >
                  <Input placeholder="Enter Firstname" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last name"
                  name="lastname"
                  rules={[
                    {
                      whitespace: true,
                      message: "Lastname should not contain only whitespaces!",
                    },
                    {
                      min: 1,
                      max: 100,
                      message: "Lastname must be between 1 to 100 characters",
                    },
                  ]}
                >
                  <Input placeholder="Enter Lastname" />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                  {
                    whitespace: true,
                    message: "Name should not contain only whitespaces!",
                  },
                  {
                    min: 1,
                    max: 100,
                    message: "Name must be between 1 to 100 characters",
                  },
                ]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  whitespace: true,
                  message: "Description should not contain only whitespaces!",
                },
                {
                  min: 1,
                  max: 500,
                  message: "Description must be between 1 to 500 characters",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={24}>
            {!characters.length &&
            !locations.length &&
            !events.length &&
            !items.length ? null : (
              <>
                {pagetype === "story" &&
                characters.length &&
                elementType === "Character" &&
                !locations.length &&
                !events.length &&
                !items.length ? null : (
                  <>
                    {pagetype === "story" &&
                    locations.length &&
                    elementType === "Location" &&
                    !characters.length &&
                    !events.length &&
                    !items.length ? null : (
                      <>
                        {pagetype === "story" &&
                        events.length &&
                        elementType === "Event" &&
                        !locations.length &&
                        !characters.length &&
                        !items.length ? null : (
                          <>
                            {pagetype === "story" &&
                            items.length &&
                            elementType === "Item" &&
                            !locations.length &&
                            !characters.length &&
                            !events.length ? null : (
                              <>
                                {pagetype === "story" ? (
                                  <>
                                    <div className="ant-form-item">
                                      <div className="ant-col ant-form-item-label">
                                        <label>
                                          Attach existing story entities -
                                        </label>
                                      </div>
                                      <Select
                                        className="w-100"
                                        placeholder="Select a entity"
                                        optionFilterProp="name"
                                        onChange={(e) => onChange(e)}
                                        onClear={onClear}
                                        allowClear={true}
                                      >
                                        {pagetype !== "character" && (
                                          <>
                                            {elementType === "Character" ||
                                            !characters.length ? null : (
                                              <OptGroup label="Characters">
                                                {characters.map((character) => (
                                                  <Option
                                                    key={character.id}
                                                    value={`${character.id}_character`}
                                                  >
                                                    {character.firstname}{" "}
                                                    {character.lastname &&
                                                      character.lastname}
                                                  </Option>
                                                ))}
                                              </OptGroup>
                                            )}
                                          </>
                                        )}
                                        {pagetype !== "location" && (
                                          <>
                                            {elementType === "Location" ||
                                            !locations.length ? null : (
                                              <OptGroup label="Locations">
                                                {locations.map((location) => (
                                                  <Option
                                                    key={location.id}
                                                    value={`${location.id}_location`}
                                                  >
                                                    {location.name}
                                                  </Option>
                                                ))}
                                              </OptGroup>
                                            )}
                                          </>
                                        )}
                                        {pagetype !== "event" && (
                                          <>
                                            {elementType === "Event" ||
                                            !events.length ? null : (
                                              <OptGroup label="Events">
                                                {events.map((event) => (
                                                  <Option
                                                    key={event.id}
                                                    value={`${event.id}_event`}
                                                  >
                                                    {event.name}
                                                  </Option>
                                                ))}
                                              </OptGroup>
                                            )}
                                          </>
                                        )}
                                        {pagetype !== "item" && (
                                          <>
                                            {elementType === "Item" ||
                                            !items.length ? null : (
                                              <OptGroup label="Items">
                                                {items.map((item) => (
                                                  <Option
                                                    key={item.id}
                                                    value={`${item.id}_item`}
                                                  >
                                                    {item.name}
                                                  </Option>
                                                ))}
                                              </OptGroup>
                                            )}
                                          </>
                                        )}
                                      </Select>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="popupNewlabel">
                                      {pagetype === "character" && (
                                        <>
                                          {` This new ${elementType} will attach to '${pagetype}'`}{" "}
                                          <b>{`${
                                            character.firstname +
                                            (character.lastname
                                              ? " " + character.lastname
                                              : "")
                                          }`}</b>
                                        </>
                                      )}
                                      {pagetype === "location" && (
                                        <>
                                          {` This new ${elementType} will attach to '${pagetype}'`}{" "}
                                          <b>{` ${location.name}`}</b>
                                        </>
                                      )}
                                      {pagetype === "event" && (
                                        <>
                                          {` This new ${elementType} will attach to '${pagetype}'`}{" "}
                                          <b>{` ${event.name}`}</b>
                                        </>
                                      )}
                                      {pagetype === "item" && (
                                        <>
                                          {` This new ${elementType} will attach to '${pagetype}'`}{" "}
                                          <b>{` ${item.name}`}</b>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                                <TimelineComponent
                                  handleMonthChange={handleMonthChange}
                                  daysFromArr={daysFromArr}
                                  daysToArr={daysToArr}
                                  checked={checked}
                                  handleTabEvent={handleTabEvent}
                                  selectTimeline={selectTimeline}
                                  timelines={timelines}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddElementComponent;
