import { Button, Form, Modal, notification } from "antd";
import React, { useState } from "react";
import { ADD_ASSOCIATION, ADD_TIMELINE } from "../../graphql/APIs";
import { processDate } from "../../utils/modifydate";
import { TimelineComponent } from "../TimelineComponent";

const AddTimeline = ({
  elementType,
  selectedElement,
  onFinishSaveElement,
  storyId,
  pageType,
  timelines,
  characterId,
  locationId,
  eventId,
  itemId,
  showTimelineModal,
  closeModal,
  closeTimelineModal,
  client,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedTimeline, setSelectedTimeline] = useState("");
  const [checked, setChecked] = useState("showCreateDate");

  const formRef = React.createRef();

  const days31 = [
    { id: "01" },
    { id: "02" },
    { id: "03" },
    { id: "04" },
    { id: "05" },
    { id: "06" },
    { id: "07" },
    { id: "08" },
    { id: "09" },
    { id: "10" },
    { id: "11" },
    { id: "12" },
    { id: "13" },
    { id: "14" },
    { id: "15" },
    { id: "16" },
    { id: "17" },
    { id: "18" },
    { id: "19" },
    { id: "20" },
    { id: "21" },
    { id: "22" },
    { id: "23" },
    { id: "24" },
    { id: "25" },
    { id: "26" },
    { id: "27" },
    { id: "28" },
    { id: "29" },
    { id: "30" },
    { id: "31" },
  ];
  const days30 = [
    { id: "01" },
    { id: "02" },
    { id: "03" },
    { id: "04" },
    { id: "05" },
    { id: "06" },
    { id: "07" },
    { id: "08" },
    { id: "09" },
    { id: "10" },
    { id: "11" },
    { id: "12" },
    { id: "13" },
    { id: "14" },
    { id: "15" },
    { id: "16" },
    { id: "17" },
    { id: "18" },
    { id: "19" },
    { id: "20" },
    { id: "21" },
    { id: "22" },
    { id: "23" },
    { id: "24" },
    { id: "25" },
    { id: "26" },
    { id: "27" },
    { id: "28" },
    { id: "29" },
    { id: "30" },
  ];
  const days28 = [
    { id: "01" },
    { id: "02" },
    { id: "03" },
    { id: "04" },
    { id: "05" },
    { id: "06" },
    { id: "07" },
    { id: "08" },
    { id: "09" },
    { id: "10" },
    { id: "11" },
    { id: "12" },
    { id: "13" },
    { id: "14" },
    { id: "15" },
    { id: "16" },
    { id: "17" },
    { id: "18" },
    { id: "19" },
    { id: "20" },
    { id: "21" },
    { id: "22" },
    { id: "23" },
    { id: "24" },
    { id: "25" },
    { id: "26" },
    { id: "27" },
    { id: "28" },
  ];
  const month31 = [
    "January",
    "March",
    "May",
    "July",
    "August",
    "October",
    "December",
  ];
  const month30 = ["April", "June", "September", "November"];

  const [daysToArr, setDaysToArr] = useState(days31);
  const [daysFromArr, setDaysFromArr] = useState(days31);

  const handleMonthChange = (key, type) => {
    if (type === "to") {
      if (month31.indexOf(key) > -1) setDaysToArr(days31);
      else if (month30.indexOf(key) > -1) setDaysToArr(days30);
      else setDaysToArr(days28);
    } else {
      if (month31.indexOf(key) > -1) setDaysFromArr(days31);
      else if (month30.indexOf(key) > -1) setDaysFromArr(days30);
      else setDaysFromArr(days28);
    }
  };

  const handleTabEvent = (key) => {
    setChecked(key);
  };

  const onFinish = (e) => {
    saveElement(e);
    setBtnLoading(false);
  };

  const onFinishFailed = (e) => {
    console.log(e);
  };

  const selectTimeline = (e) => {
    setSelectedTimeline(e);
  };

  const saveElement = async (e) => {
    setBtnLoading(true);
    try {
      let response = {};
      let associationInput = {};
      let timelineObj = {
        from_year: e.from_year?.format("YYYY"),
        to_year: e.to_year?.format("YYYY"),
        to_month: e.to_month,
        from_month: e.from_month,
        from_day: e.from_day,
        to_day: e.to_day,
        type: e.type,
      };
      let radioType = "date";
      if (
        e.from_year ||
        e.to_year ||
        e.from_month ||
        e.to_month ||
        e.from_day ||
        e.to_day
      ) {
        if (checked === "showDateRange") {
          radioType = "range";
          if (
            (e.from_month || e.to_month || e.from_day || e.to_day) &&
            (!e.from_year || !e.to_year)
          ) {
            notification.warning({
              message:
                "Please select the year for both the dropdown as Year is needed",
            });
            setBtnLoading(false);
            return;
          }
          if ((e.from_year && !e.to_year) || (!e.from_year && e.to_year)) {
            notification.warning({
              message:
                "Please select the year for both the dropdown as Range is selected",
            });
            setBtnLoading(false);
            return;
          }
          if (
            e.from_year &&
            e.to_year &&
            ((e.from_month && !e.to_month) || (!e.from_month && e.to_month))
          ) {
            notification.warning({
              message:
                "Please select the month for both the dropdown as Range is selected",
            });
            setBtnLoading(false);
            return;
          }
          if (
            e.from_year &&
            e.to_year &&
            ((e.from_day && !e.to_day) || (!e.from_day && e.to_day))
          ) {
            notification.warning({
              message:
                "Please select the date for both the dropdown as Range is selected",
            });
            setBtnLoading(false);
            return;
          } else
            response = await processDate(
              e.to_year?.format("YYYY"),
              e.to_month,
              e.to_day,
              e.from_year?.format("YYYY"),
              e.from_month,
              e.from_day,
              radioType
            );

          timelineObj = {
            from_year: response.from_year,
            to_year: response.to_year,
            to_month: response.to_month,
            from_month: response.from_month,
            from_day: response.from_day,
            to_day: response.to_day,
            type: radioType,
          };
        }
        if (checked === "showCreateDate") {
          radioType = "date";
          if ((e.from_month || e.from_day) && !e.from_year) {
            notification.warning({
              message: "Please select the year as Year is needed",
            });
            setBtnLoading(false);
            return;
          }
          response = await processDate(
            e.to_year,
            e.to_month,
            e.to_day,
            e.from_year?.format("YYYY"),
            e.from_month,
            e.from_day,
            radioType
          );

          timelineObj = {
            from_year: response.from_year,
            to_year: response.to_year,
            to_month: response.to_month,
            from_month: response.from_month,
            from_day: response.from_day,
            to_day: response.to_day,
            type: radioType,
          };
        }
      }
      associationInput = { timelineId: selectedTimeline, storyId: storyId };

      switch (pageType) {
        case "CharacterPage":
          timelineObj.characterId = characterId;
          timelineObj.storyId = storyId;
          associationInput.characterId = characterId;
          switch (elementType) {
            case "Location":
              if (e.from_year || e.to_year) {
                timelineObj.locationId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.locationId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            case "Event":
              if (e.from_year || e.to_year) {
                timelineObj.eventId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.eventId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            case "Item":
              if (e.from_year || e.to_year) {
                timelineObj.itemId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.itemId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            default:
          }
          break;
        case "LocationPage":
          timelineObj.locationId = locationId;
          timelineObj.storyId = storyId;
          associationInput.locationId = locationId;
          switch (elementType) {
            case "Character":
              if (e.from_year || e.to_year) {
                timelineObj.characterId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.characterId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            case "Event":
              if (e.from_year || e.to_year) {
                timelineObj.eventId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.eventId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            case "Item":
              if (e.from_year || e.to_year) {
                timelineObj.itemId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.itemId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            default:
          }
          break;
        case "EventPage":
          timelineObj.eventId = eventId;
          timelineObj.storyId = storyId;
          associationInput.eventId = eventId;
          switch (elementType) {
            case "Character":
              if (e.from_year || e.to_year) {
                timelineObj.characterId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.characterId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            case "Location":
              if (e.from_year || e.to_year) {
                timelineObj.locationId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.locationId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            case "Item":
              if (e.from_year || e.to_year) {
                timelineObj.itemId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.itemId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            default:
          }
          break;
        case "ItemPage":
          timelineObj.itemId = itemId;
          timelineObj.storyId = storyId;
          associationInput.itemId = itemId;
          switch (elementType) {
            case "Character":
              if (e.from_year || e.to_year) {
                timelineObj.characterId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.characterId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            case "Location":
              if (e.from_year || e.to_year) {
                timelineObj.locationId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.locationId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            case "Event":
              if (e.from_year || e.to_year) {
                timelineObj.eventId = selectedElement;
                await client.mutate({
                  mutation: ADD_TIMELINE,
                  variables: timelineObj,
                });
              } else if (selectedTimeline) {
                associationInput.eventId = selectedElement;
                await client.mutate({
                  mutation: ADD_ASSOCIATION,
                  variables: associationInput,
                });
              }

              onFinishSaveElement();
              break;
            default:
          }
          break;
        default:
      }
      setBtnLoading(false);
    } catch (error) {
      console.log({ error });
      setBtnLoading(false);
    }
  };

  return (
    <React.Fragment>
      {" "}
      <Modal
        title="Select or Create a Date"
        visible={showTimelineModal}
        width={600}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={[
          <Button
            key="back"
            onClick={closeTimelineModal}
            className="btnwidth"
            size="large"
          >
            Cancel
          </Button>,
          <Button
            loading={btnLoading}
            form="myForm"
            htmlType="submit"
            type="primary"
            size="large"
            className="btnwidth"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          ref={formRef}
          layout="vertical"
          id="myForm"
          name="myForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <TimelineComponent
            handleMonthChange={handleMonthChange}
            daysFromArr={daysFromArr}
            daysToArr={daysToArr}
            checked={checked}
            handleTabEvent={handleTabEvent}
            selectTimeline={selectTimeline}
            timelines={timelines}
          />
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AddTimeline;
