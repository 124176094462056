import {
  Button,
  Form,
  Input,
  message,
  Modal,
  notification,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { deleteFile, uploadImage } from "../../apis/rest";
import {
  GET_CHARACTER_DETAIL_BY_ID,
  GET_EVENT_DETAIL_BY_ID,
  GET_ITEM_DETAIL_BY_ID,
  GET_LOCATION_DETAIL_BY_ID,
  UPDATE_CHARACTER_DETAIL_BY_ID,
  UPDATE_EVENT_DETAIL_BY_ID,
  UPDATE_ITEM_DETAIL_BY_ID,
  UPDATE_LOCATION_DETAIL_BY_ID,
} from "../../graphql/APIs";
// const { REACT_APP_HB_URL } = process.env;
const { REACT_APP_NHOST_STORAGE_URL } = process.env;

const EditElementComponent = ({
  elementType,
  elementId,
  onFinishSaveElement,
  setBtnLoading,
  editShowModal,
  closeModal,
  btnloading,
  client,
}) => {
  const [form] = Form.useForm();
  const [character, setCharacter] = useState(null);
  const [logo, setLogo] = useState(undefined);
  const [fileList, setFileList] = useState([]);
  const [event, setEvent] = useState(null);
  const [uploadItems, setUploadItems] = useState([]);
  const [item, setItem] = useState(null);
  const [location, setLocation] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);

  const getData = () => {
    try {
      switch (elementType) {
        case "Character":
          client
            .query({
              query: GET_CHARACTER_DETAIL_BY_ID,
              variables: { id: elementId },
              fetchPolicy: "network-only",
            })
            .then(({ data }) => {
              setCharacter(data.character_by_pk, "Character");
              if (data.character_by_pk.logo !== null) {
                let fileUrl = `${REACT_APP_NHOST_STORAGE_URL}/files/${data.character_by_pk.logo}`;
                setUploadItems(
                  data.character_by_pk.logo !== null
                    ? [{ url: fileUrl, fileId: data.character_by_pk.logo }]
                    : []
                );
                setFileList([{ url: fileUrl }]);
              }
            })
            .catch((error, result) => {
              console.log("ERROR", result);
              if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                message.destroy();
                message.error(error.graphQLErrors[0].message);
              } else {
                console.log(error);
                message.destroy();
                message.error("Something went wrong please try again later");
              }
            });
          break;
        case "Location":
          client
            .query({
              query: GET_LOCATION_DETAIL_BY_ID,
              variables: { id: elementId },
              fetchPolicy: "network-only",
            })
            .then(({ data }) => {
              setLocation(data.location_by_pk, "Location");
              if (data.location_by_pk.logo !== null) {
                let fileUrl = `${REACT_APP_NHOST_STORAGE_URL}/files/${data.location_by_pk.logo}`;
                setUploadItems(
                  data.location_by_pk.logo !== null
                    ? [{ url: fileUrl, fileId: data.location_by_pk.logo }]
                    : []
                );
                setFileList([{ url: fileUrl }]);
              }
            })
            .catch((error, result) => {
              console.log("ERROR", result);
              if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                message.destroy();
                message.error(error.graphQLErrors[0].message);
              } else {
                console.log(error);
                message.destroy();
                message.error("Something went wrong please try again later");
              }
            });
          break;
        case "Event":
          client
            .query({
              query: GET_EVENT_DETAIL_BY_ID,
              variables: { id: elementId },
              fetchPolicy: "network-only",
            })
            .then(({ data }) => {
              setEvent(data.event_by_pk, "Event");
              if (data.event_by_pk.logo !== null) {
                let fileUrl = `${REACT_APP_NHOST_STORAGE_URL}/files/${data.event_by_pk.logo}`;
                setUploadItems(
                  data.event_by_pk.logo !== null
                    ? [{ url: fileUrl, fileId: data.event_by_pk.logo }]
                    : []
                );
                setFileList([{ url: fileUrl }]);
              }
            })
            .catch((error, result) => {
              console.log("ERROR", result);
              if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                message.destroy();
                message.error(error.graphQLErrors[0].message);
              } else {
                console.log(error);
                message.destroy();
                message.error("Something went wrong please try again later");
              }
            });

          break;
        case "Item":
          client
            .query({
              query: GET_ITEM_DETAIL_BY_ID,
              variables: { id: elementId },
              fetchPolicy: "network-only",
            })
            .then(({ data }) => {
              setItem(data.item_by_pk, "Item");
              if (data.item_by_pk.logo !== null) {
                let fileUrl = `${REACT_APP_NHOST_STORAGE_URL}/files/${data.item_by_pk.logo}`;
                setUploadItems(
                  data.item_by_pk.logo !== null
                    ? [{ url: fileUrl, fileId: data.item_by_pk.logo }]
                    : []
                );
                setFileList([{ url: fileUrl }]);
              }
            })
            .catch((error, result) => {
              console.log("ERROR", result);
              if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                message.destroy();
                message.error(error.graphQLErrors[0].message);
              } else {
                console.log(error);
                message.destroy();
                message.error("Something went wrong please try again later");
              }
            });
          break;
        default:
      }
    } catch (err) {}
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (character) {
      form.setFieldsValue({ ...character });
    }
    if (location) {
      form.setFieldsValue({ ...location });
    }
    if (event) {
      form.setFieldsValue({ ...event });
    }
    if (item) {
      form.setFieldsValue({ ...item });
    }
  }, [character, location, event, item]);

  const handleUpload = async (props) => {
    const { onSuccess, onError, file } = props;
    try {
      if (file.size / (1024 * 1000) <= 2 && file.type.includes("image")) {
        var formData = new FormData();
        formData.append("file", file);

        var options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: ({ total, loaded }) => {
            setUploadProgress({
              precent: Math.round((loaded / total) * 100).toFixed(2),
              file: file,
            });
          },
        };
        const { fileMetadata } = await uploadImage(file, options);

        let fileUrl = `${REACT_APP_NHOST_STORAGE_URL}/files/${fileMetadata.id}`;
        setUploadProgress(null);
        setUploadItems([
          ...uploadItems,
          { url: fileUrl, fileId: fileMetadata.id },
        ]);
        setUploadProgress(null);
        onSuccess(null, file);
        setFileList([{ url: fileUrl }]);
        notification.success({ message: "Logo updated" });
      } else return message.error("Either file type or size is invalid.");
    } catch (err) {
      onError(err.message, err.message, file);
    }
  };

  const onFinish = async (e) => {
    saveElement(e);
  };

  const onFinishFailed = (e) => {
    console.log(e);
  };

  const onRemove = async () => {
    setUploadItems([]);
    setFileList([]);
    if (fileList && fileList.length > 0) {
      let url = fileList[0].url;
      await deleteFile(url);
    }
  };

  const saveElement = async (e) => {
    setBtnLoading(true);
    try {
      switch (elementType) {
        case "Character":
          if (
            character.firstname ===
              (e.firstname ? e.firstname?.trim() : null) &&
            character.lastname === (e.lastname ? e.lastname?.trim() : null) &&
            character.description ===
              (e.description ? e.description?.trim() : null) &&
            (uploadItems.length ? uploadItems[0].fileId : null) ===
              character.logo
          ) {
            message.warning("No change detected!");
            setBtnLoading(false);
            return;
          } else {
            let characterInput = {
              id: elementId,
              firstname: e.firstname ? e.firstname?.trim() : null,
              lastname: e.lastname ? e.lastname?.trim() : null,
              description: e.description ? e.description?.trim() : null,
              entity_type: "character",
              logo:
                uploadItems && uploadItems.length
                  ? uploadItems[0].fileId
                  : null,
            };
            await client.mutate({
              mutation: UPDATE_CHARACTER_DETAIL_BY_ID,
              variables: characterInput,
            });
            message.success(`${elementType} updated successfully`);
            onFinishSaveElement();
          }
          break;
        case "Event":
          if (
            event.name === (e.name ? e.name?.trim() : null) &&
            event.description ===
              (e.description ? e.description?.trim() : null) &&
            (uploadItems.length ? uploadItems[0].fileId : null) === event.logo
          ) {
            message.warning("No change detected!");
            setBtnLoading(false);
            return;
          } else {
            let eventInput = {
              id: elementId,
              name: e.name ? e.name?.trim() : null,
              description: e.description ? e.description?.trim() : null,
              entity_type: "event",
              logo:
                uploadItems && uploadItems.length
                  ? uploadItems[0].fileId
                  : null,
            };
            await client.mutate({
              mutation: UPDATE_EVENT_DETAIL_BY_ID,
              variables: eventInput,
            });
            message.success(`${elementType} updated successfully`);
            onFinishSaveElement();
          }
          break;
        case "Item":
          if (
            item.name === (e.name ? e.name?.trim() : null) &&
            item.description ===
              (e.description ? e.description?.trim() : null) &&
            (uploadItems.length ? uploadItems[0].fileId : null) === item.logo
          ) {
            message.warning("No change detected!");
            setBtnLoading(false);
            return;
          } else {
            let itemInput = {
              id: elementId,
              name: e.name ? e.name?.trim() : null,
              description: e.description ? e.description?.trim() : null,
              entity_type: "item",
              logo:
                uploadItems && uploadItems.length
                  ? uploadItems[0].fileId
                  : null,
            };
            await client.mutate({
              mutation: UPDATE_ITEM_DETAIL_BY_ID,
              variables: itemInput,
            });
            message.success(`${elementType} updated successfully`);
            onFinishSaveElement();
          }
          break;
        case "Location":
          if (
            location.name === (e.name ? e.name?.trim() : null) &&
            location.description ===
              (e.description ? e.description?.trim() : null) &&
            (uploadItems.length ? uploadItems[0].fileId : null) ===
              location.logo
          ) {
            message.warning("No change detected!");
            setBtnLoading(false);
            return;
          } else {
            let locationInput = {
              id: elementId,
              name: e.name ? e.name?.trim() : null,
              description: e.description ? e.description?.trim() : null,
              entity_type: "location",
              logo:
                uploadItems && uploadItems.length
                  ? uploadItems[0].fileId
                  : null,
            };
            await client.mutate({
              mutation: UPDATE_LOCATION_DETAIL_BY_ID,
              variables: locationInput,
            });
            message.success(`${elementType} updated successfully`);
            onFinishSaveElement();
          }
          break;
        default:
      }
      setBtnLoading(false);
    } catch (error) {
      console.log({ error });
      setBtnLoading(false);
    }
  };

  return (
    <Modal
      title={"Edit " + elementType}
      visible={editShowModal}
      onCancel={closeModal}
      width={600}
      destroyOnClose={true}
      footer={[
        <Button
          key="back"
          onClick={closeModal}
          size="large"
          className="btnwidth"
        >
          Cancel
        </Button>,
        <Button
          form="myForm"
          htmlType="submit"
          type="primary"
          size="large"
          className="btnwidth"
          loading={btnloading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        id="myForm"
        name="myForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label={`Upload a logo for ${elementType}`}>
          <Upload
            className={uploadProgress ? "jb_disabled" : "cust-upload"}
            fileList={fileList}
            multiple={false}
            listType="picture-card"
            onRemove={(e) => onRemove(e)}
            customRequest={(e) => handleUpload(e)}
          >
            {(!fileList.length && !uploadProgress && "Upload") ||
              (uploadProgress && uploadProgress.precent + "%")}
          </Upload>
          {/* )} */}
        </Form.Item>
        {elementType === "Character" ? (
          <>
            <Form.Item
              label="First name or Only name"
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "Please enter firstname",
                },
                {
                  whitespace: true,
                  message: "Name should not contain only whitespaces!",
                },
                {
                  min: 1,
                  max: 100,
                },
              ]}
            >
              <Input placeholder="Enter Firstname" />
            </Form.Item>
            <Form.Item
              label="Last name"
              name="lastname"
              rules={[
                {
                  whitespace: true,
                  message: "Lastname should not contain only whitespaces!",
                },
                {
                  min: 1,
                  max: 100,
                },
              ]}
            >
              <Input placeholder="Enter Lastname" />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
              {
                whitespace: true,
                message: "Name should not contain only whitespaces!",
              },
              {
                min: 1,
                max: 100,
              },
            ]}
          >
            <Input placeholder="Enter Name" />
          </Form.Item>
        )}
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              whitespace: true,
              message: "Description should not contain only whitespaces!",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditElementComponent;
