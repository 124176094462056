import { PlusOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  message,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Tooltip,
} from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { AddElementComponent } from "../../Components/AddElementComponent";
import { AddTimeline } from "../../Components/AddTimeline";
import { DateComponent } from "../../Components/DateComponent";
import EditElementComponent from "../../Components/EditElementComponent/EditElementComponent";
import { SubHeader } from "../../Components/SubHeaderPage";
import { CustIcon } from "../../Components/Svgs/Svgs";
import {
  ADD_ASSOCIATION,
  DELETE_CHARACTER,
  DELETE_EVENT,
  DELETE_EVENT_CHARACTER_ASSOCIATION,
  DELETE_EVENT_ITEM_ASSOCIATION,
  DELETE_EVENT_LOCATION_ASSOCIATION,
  DELETE_ITEM,
  DELETE_LOCATION,
  GET_EACH_STORIES_ENTITIES_COUNTS,
  GET_EVENT_DETAIL,
  GET_STORY_ENTITIES,
  UPDATE_EVENT_DETAIL_BY_ID,
} from "../../graphql/APIs";
import useStore from "../../utils/store";
import "./EventDetail.css";

// const { REACT_APP_ENTITY_LIMIT, REACT_APP_HB_URL } = process.env;
const { REACT_APP_ENTITY_LIMIT, REACT_APP_NHOST_STORAGE_URL } = process.env;

const { Option } = Select;
const EventDetail = () => {
  const { isSubscribed } = useOutletContext();
  const client = useApolloClient();
  const [loaded, setLoaded] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [event, setEvent] = useState({});
  const [eventDescription, setEventDescription] = useState({});
  const [eventName, setEventName] = useState({});
  const [eventLogo, setEventLogo] = useState([]);
  const [events, setEvents] = useState({});
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [elementType, setElementType] = useState("");
  const [displayElement, setDisplayElement] = useState(false);
  const [characterlist, setCharacterlist] = useState([]);
  const [characterslist, setCharacterslist] = useState([]);
  const [locationlist, setLocationlist] = useState([]);
  const [locationslist, setLocationslist] = useState([]);
  const [itemlist, setItemlist] = useState([]);
  const [itemslist, setItemslist] = useState([]);
  const [timelinelist, setTimelinelist] = useState([]);
  const [showTimelineModal, setShowTimelineModal] = useState(false);
  const [selectedElement, setSelectedElement] = useState();
  const [btnloading, setBtnLoading] = useState(false);
  const [isInvisible, setIsInvisible] = useState("");
  const [allEntityCount, setAllEntityCount] = useState(0);
  const location = useLocation();
  const { eventid } = useParams();
  const navigate = useNavigate();
  const { story, eventStore } = useStore();
  const addStoryDetail = useStore((state) => state.addStoryDetail);
  const addCharacterDetail = useStore((state) => state.addCharacterDetail);
  const addLocationDetail = useStore((state) => state.addLocationDetail);
  const addEventDetail = useStore((state) => state.addEventDetail);
  const addItemDetail = useStore((state) => state.addItemDetail);
  const addHistoryDetail = useStore((state) => state.addHistoryDetail);

  const updateStore = (object, type) => {
    switch (type) {
      case "story":
        addStoryDetail(object);
        break;
      case "character":
        addCharacterDetail(object);

        break;
      case "location":
        addLocationDetail(object);

        break;
      case "event":
        addEventDetail(object);

        break;
      case "item":
        addItemDetail(object);

        break;
      case "history":
        addHistoryDetail(object);

        break;

      default:
        break;
    }
  };
  const closeModal = () => {
    setShowModal(false);
    setEditShowModal(false);
    setShowTimelineModal(false);
  };

  const closeTimelineModal = () => {
    setShowModal(false);
    setShowTimelineModal(false);
  };

  const setBtnLoader = (e) => {
    console.log(e);
    setBtnLoading(e);
  };

  const getData = () => {
    client
      .query({
        query: GET_EVENT_DETAIL,
        variables: { id: parseInt(eventid) },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data?.event?.length) {
          getStoryEntityCounts();
          setEvent(data.event[0]);
          setEventName(data.event[0].name);
          setEventDescription(data.event[0].description);
          if (
            data.event[0].description === null ||
            !data.event[0].description.length
          ) {
          }
          setEventLogo(data.event[0].logo !== null ? data.event[0].logo : []);
          setEvents(data.event);
          getAllList(data.event[0].associations);
          var clone = _.cloneDeep(data.event[0].associations);
          var temp = _.filter(clone, function (obj) {
            if (obj.character) obj.character.timeline = obj.timeline;
            if (obj.location) obj.location.timeline = obj.timeline;
            if (obj.item) obj.item.timeline = obj.timeline;
            return obj;
          });
          setCharacters(
            _.map(
              _.uniqBy(
                _.map(_.reject(temp, ["character", null]), "character"),
                "id"
              ),
              (o) => _.extend({ type: "character" }, o)
            )
          );
          setLocations(
            _.map(
              _.uniqBy(
                _.map(_.reject(temp, ["location", null]), "location"),
                "id"
              ),
              (o) => _.extend({ type: "location" }, o)
            )
          );
          setItems(
            _.map(
              _.uniqBy(_.map(_.reject(temp, ["item", null]), "item"), "id"),
              (o) => _.extend({ type: "item" }, o)
            )
          );
        } else {
          message.error("Oops! No such event exist.");
          navigate("/stories");
        }
        setLoaded(true);
      })
      .catch((error, result) => {
        console.log("ERROR", result);
        setLoaded(true);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };

  const getAllList = (list) => {
    client
      .query({
        query: GET_STORY_ENTITIES,
        variables: { id: parseInt(story.id) },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        let arrCharacter = [],
          arrLocation = [],
          arrItem = [],
          arrTimeline = [];
        data.story[0].storyCharacters.forEach((x) =>
          arrCharacter.push(x.character)
        );
        data.story[0].storyLocations.forEach((x) =>
          arrLocation.push(x.location)
        );
        data.story[0].storyItems.forEach((x) => arrItem.push(x.item));
        data.story[0].storyTimelines.forEach((x) =>
          arrTimeline.push(x.timeline)
        );
        setTimelinelist(arrTimeline);
        let characterResult = _.xorBy(
          arrCharacter,
          _.compact(_.map(list, "character")),
          "id"
        );
        let charactersResult = _.uniqWith(
          arrCharacter,
          _.compact(_.map(list, "character"), "id"),
          (o) => _.extend({ type: "character" }, o)
        );
        let locationResult = _.xorBy(
          arrLocation,
          _.compact(_.map(list, "location")),
          "id"
        );
        let locationsResult = _.uniqWith(
          arrLocation,
          _.compact(_.map(list, "location"), "id"),
          (o) => _.extend({ type: "location" }, o)
        );
        let itemResult = _.xorBy(arrItem, _.compact(_.map(list, "item")), "id");
        let itemsResult = _.uniqWith(
          arrItem,
          _.compact(_.map(list, "item"), "id"),
          (o) => _.extend({ type: "item" }, o)
        );
        setCharacterlist(characterResult);
        setCharacterslist(charactersResult);
        setLocationlist(locationResult);
        setLocationslist(locationsResult);
        setItemlist(itemResult);
        setItemslist(itemsResult);
      })
      .catch((error, result) => {
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };

  useEffect(() => {
    localStorage.setItem("last_visited_url", location.pathname);
    getData();
  }, []);

  const getStoryEntityCounts = () => {
    client
      .query({
        query: GET_EACH_STORIES_ENTITIES_COUNTS,
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        let storyBasedCountArr = [];
        _.filter(data.userStory, function (obj) {
          let {
            story: {
              storyCharacters_aggregate: { characters },
              storyEvents_aggregate: { events },
              storyItems_aggregate: { items },
              storyLocations_aggregate: { locations },
            },
          } = obj;
          storyBasedCountArr.push(
            ((characters && characters.count) || 0) +
              ((events && events.count) || 0) +
              ((items && items.count) || 0) +
              ((locations && locations.count) || 0)
          );
        });
        setAllEntityCount(_.sum(storyBasedCountArr));
      })
      .catch((error, result) => {
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };

  const save = async (type) => {
    try {
      switch (type) {
        case "Character":
          await client.mutate({
            mutation: ADD_ASSOCIATION,
            variables: {
              storyId: parseInt(story.id),
              eventId: parseInt(eventStore.id),
              characterId: selectedElement,
            },
          });
          setDisplayElement(false);
          onFinishSaveElement();
          break;
        case "Location":
          await client.mutate({
            mutation: ADD_ASSOCIATION,
            variables: {
              storyId: parseInt(story.id),
              eventId: parseInt(eventStore.id),
              locationId: selectedElement,
            },
          });
          setDisplayElement(false);
          onFinishSaveElement();
          break;
        case "Item":
          await client.mutate({
            mutation: ADD_ASSOCIATION,
            variables: {
              storyId: parseInt(story.id),
              eventId: parseInt(eventStore.id),
              itemId: selectedElement,
            },
          });
          setDisplayElement(false);
          onFinishSaveElement();
          break;
        default:
      }
    } catch (error) {}
  };

  const deleteElement = async (id, elementType) => {
    try {
      switch (elementType) {
        case "character":
          await client.mutate({
            mutation: DELETE_CHARACTER,
            variables: {
              storyId: parseInt(story.id),
              characterId: id,
            },
          });
          onFinishSaveElement();
          break;
        case "event":
          await client.mutate({
            mutation: DELETE_EVENT,
            variables: {
              storyId: parseInt(story.id),
              eventId: id,
            },
          });
          onFinishSaveElement();
          break;
        case "item":
          await client.mutate({
            mutation: DELETE_ITEM,
            variables: { storyId: parseInt(story.id), itemId: id },
          });
          onFinishSaveElement();
          break;
        case "location":
          await client.mutate({
            mutation: DELETE_LOCATION,
            variables: {
              storyId: parseInt(story.id),
              locationId: id,
            },
          });
          onFinishSaveElement();
          break;
        default:
      }
    } catch (err) {}
  };

  const removeAssociation = async (id, elementType) => {
    try {
      switch (elementType) {
        case "character":
          await client.mutate({
            mutation: DELETE_EVENT_CHARACTER_ASSOCIATION,
            variables: {
              eventId: parseInt(eventStore.id),
              characterId: id,
            },
          });
          onFinishSaveElement();
          break;
        case "location":
          await client.mutate({
            mutation: DELETE_EVENT_LOCATION_ASSOCIATION,
            variables: {
              eventId: parseInt(eventStore.id),
              locationId: id,
            },
          });
          onFinishSaveElement();
          break;
        case "item":
          await client.mutate({
            mutation: DELETE_EVENT_ITEM_ASSOCIATION,
            variables: {
              eventId: parseInt(eventStore.id),
              itemId: id,
            },
          });
          onFinishSaveElement();
          break;
        default:
      }
    } catch (err) {}
  };

  const setEditableTitle = async (e) => {
    if (eventName === e?.trim()) {
      message.warning("No change detected!");
      return;
    } else if (!e || !e.length || e === "") {
      message.warning("Name can not be blank!");
      return;
    } else if (!e || e.length < 1 || e.length > 100) {
      message.warning("Event title must be between 1 to 100 characters.");
      return;
    } else {
      let eventInput = {
        id: parseInt(eventStore.id),
        name: e ? e?.trim() : eventName,
        description: eventDescription,
        logo: eventLogo.length ? eventLogo : null,
        entity_type: "event",
      };
      const nameResponse = await client.mutate({
        mutation: UPDATE_EVENT_DETAIL_BY_ID,
        variables: eventInput,
      });
      if (!nameResponse.error) {
        message.success("Event name updated");
        setEventName(e?.trim());
      } else {
        message.error("something went wrong");
      }
    }
  };

  const setEditableDescription = async (e) => {
    if (eventDescription === e?.trim()) {
      message.warning("No change detected!");
      return;
    } else if (e.length > 500) {
      message.warning("Event description must be under 500 characters");
      return;
    } else if (!e || !e.length || e === "") {
      let eventInput = {
        id: parseInt(eventStore.id),
        name: eventName,
        description: null,
        logo: eventLogo.length ? eventLogo : null,
        entity_type: "event",
      };
      const descriptionResponse = await client.mutate({
        mutation: UPDATE_EVENT_DETAIL_BY_ID,
        variables: eventInput,
      });
      if (!descriptionResponse.error) {
        message.success("Event description updated");
        setEventDescription(e?.trim());
      } else {
        message.error("something went wrong");
      }
    } else {
      let eventInput = {
        id: parseInt(eventStore.id),
        name: eventName,
        description: e ? e?.trim() : eventDescription,
        logo: eventLogo.length ? eventLogo : null,
        entity_type: "event",
      };
      const descriptionResponse = await client.mutate({
        mutation: UPDATE_EVENT_DETAIL_BY_ID,
        variables: eventInput,
      });
      if (!descriptionResponse.error) {
        message.success("Event description updated");
        setEventDescription(e?.trim());
      } else {
        message.error("something went wrong");
      }
    }
  };

  const onFinishSaveElement = () => {
    setDisplayElement(false);
    setShowModal(false);
    setEditShowModal(false);
    setShowTimelineModal(false);
    setLoaded(false);
    setBtnLoading(false);
    setSelectedElement();
    getData();
  };

  const onChange = (value, type) => {
    if (value) {
      setSelectedElement(value);
      setElementType(type);
    }
  };

  const onClear = () => {
    setSelectedElement(null);
  };

  const selectDate = () => {
    setShowTimelineModal(true);
  };

  const addElement = (type) => {
    if (isSubscribed || allEntityCount < REACT_APP_ENTITY_LIMIT) {
      setDisplayElement(false);
      setShowModal(true);
      setElementType(type);
    } else
      message.warning(
        `${
          "You can create upto " +
          { REACT_APP_ENTITY_LIMIT } +
          " entities in FREE account. Upgrade to Pro for unlimited access."
        }`
      );
  };

  const showElement = (type) => {
    setSelectedElement(null);
    if (
      (type === "Character" && !characterlist.length) ||
      (type === "Location" && !locationlist.length) ||
      (type === "Item" && !itemlist.length)
    ) {
      addElement(type);
    } else {
      setDisplayElement(true);
    }
    setElementType(type);
  };

  const onEnd = () => {
    setEditableTitle(false);
  };

  const editElement = (id, elementType) => {
    setDisplayElement(false);
    setEditShowModal(true);
    setElementType(elementType);
    setSelectedElement(id);
  };
  const toggleDiv = (id, elementType, type) => {
    setIsInvisible(elementType + "-" + type + "-" + id);
  };

  return (
    <React.Fragment>
      <SubHeader />

      <div className="container">
        <Row gutter={20}>
          {loaded ? (
            <>
              <Col md={8}>
                <Card className="cardBox">
                  <h3>
                    <Text
                      editable={{ onChange: setEditableTitle, onEnd: onEnd }}
                    >
                      {eventName}
                    </Text>
                  </h3>
                  <div className="cardContent">
                    {eventDescription ? (
                      <Text editable={{ onChange: setEditableDescription }}>
                        {eventDescription}
                      </Text>
                    ) : (
                      <>
                        <span className="placeholderText text-black-50">
                          Add an event description here
                        </span>
                        <Text
                          editable={{ onChange: setEditableDescription }}
                        ></Text>
                      </>
                    )}
                  </div>
                  <div className="cardButton">
                    <Link
                      to={{
                        pathname: `/story/history/event/${eventStore.id}`,
                      }}
                      onClick={() =>
                        updateStore(
                          { id: eventStore.id, type: "event" },
                          "history"
                        )
                      }
                      className="ant-btn ant-btn-primary"
                    >
                      History
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col md={16}>
                <Card
                  className="cardBox mb-4"
                  title="Locations"
                  extra={
                    <Button
                      type="link"
                      size="small"
                      onClick={() => showElement("Location")}
                    >
                      <PlusOutlined />
                      Add or Link New
                    </Button>
                  }
                >
                  {displayElement && elementType === "Location" ? (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <div className="cardSelect">
                          <Select
                            showSearch
                            placeholder="Select an existing location"
                            optionFilterProp="name"
                            onChange={(e) => onChange(e, "Location")}
                            allowClear={true}
                            onClear={onClear}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {locationlist.map((location, index) => (
                              <Option key={location.id} value={location.id}>
                                {location.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="cardBtnRight">
                          {selectedElement ? (
                            <>
                              <Tooltip title="Link with No Date">
                                <Button onClick={() => save("Location")}>
                                  <CustIcon type="link"></CustIcon>
                                </Button>
                              </Tooltip>
                              <Tooltip title="Link with Date">
                                <Button onClick={selectDate}>
                                  <CustIcon type="date"></CustIcon>
                                </Button>
                              </Tooltip>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <Button
                        type="primary"
                        onClick={() => addElement("Location")}
                      >
                        New
                      </Button>
                    </div>
                  ) : null}
                  <div>
                    {locations.length > 0 ? (
                      <>
                        <div className="mb-2 font-medium">
                          {event.name} was happened at:
                        </div>
                        {locations.map((obj) => (
                          <Card className="mb-2 cardGrey">
                            <Row className="mb-3" gutter={20}>
                              <Col md={12}>
                                <div className="d-flex align-items-center">
                                  <div className="col-auto p-0">
                                    {obj?.logo ? (
                                      <Avatar
                                        src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.logo}`}
                                      />
                                    ) : (
                                      <Avatar>
                                        {obj.name
                                          .charAt(0)
                                          .toUpperCase()
                                          .slice(0)}
                                      </Avatar>
                                    )}
                                  </div>
                                  <div className="col">
                                    <Link
                                      className="storyname"
                                      onClick={() =>
                                        updateStore(obj, "location")
                                      }
                                      to={{
                                        pathname: `/story/location/${obj.id}`,
                                      }}
                                    >
                                      {obj.name}
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="cardBtnRight">
                                  <div
                                    className={`${
                                      isInvisible === "location-hide-" + obj.id
                                        ? "hideicon"
                                        : "hideicon showiconleft"
                                    }`}
                                  >
                                    <Tooltip title="Edit">
                                      <Button
                                        onClick={() =>
                                          editElement(obj.id, "Location")
                                        }
                                      >
                                        <CustIcon type="edit" />
                                      </Button>
                                    </Tooltip>
                                    <Popconfirm
                                      title={
                                        <>
                                          <div>
                                            This action will permanently delete
                                            your entity.
                                          </div>
                                          <div>
                                            Are you sure want to remove the
                                            location - <b>{obj.name}</b>?
                                          </div>
                                        </>
                                      }
                                      placement="topRight"
                                      onConfirm={() =>
                                        deleteElement(obj.id, "location")
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip title="Delete">
                                        <Button>
                                          <CustIcon
                                            className="deletepopover"
                                            type="delete"
                                          />
                                        </Button>
                                      </Tooltip>
                                    </Popconfirm>
                                    <Popconfirm
                                      title={
                                        <div>
                                          Are you sure want to unlink the
                                          location - <b>{obj.name}</b>?
                                        </div>
                                      }
                                      placement="topRight"
                                      onConfirm={() =>
                                        removeAssociation(obj.id, "location")
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip title="Unlink">
                                        <Button>
                                          <CustIcon type="unlink" />
                                        </Button>
                                      </Tooltip>
                                    </Popconfirm>
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        toggleDiv(obj.id, "location", "hide")
                                      }
                                    >
                                      <CustIcon type="dots" />
                                    </Button>
                                  </div>
                                  <div
                                    className={`${
                                      isInvisible === "location-hide-" + obj.id
                                        ? "showicon"
                                        : "hideicon  hide"
                                    }`}
                                  >
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        toggleDiv(obj.id, "location", "show")
                                      }
                                    >
                                      <CustIcon type="dots" />
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <>
                              <div>{obj.description}</div>
                              <div className="mt-2 font-medium">
                                <DateComponent obj={obj.timeline} />
                              </div>
                            </>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <Empty description={<span>No Location added</span>} />
                    )}
                  </div>
                </Card>
                <Card
                  className="cardBox mb-4"
                  title="Characters"
                  extra={
                    <Button
                      type="link"
                      size="small"
                      onClick={() => showElement("Character")}
                    >
                      <PlusOutlined />
                      Add or Link New
                    </Button>
                  }
                >
                  {displayElement && elementType === "Character" ? (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <div className="cardSelect">
                          <Select
                            showSearch
                            placeholder="Select an existing character"
                            optionFilterProp="name"
                            onChange={(e) => onChange(e, "Character")}
                            allowClear={true}
                            onClear={onClear}
                            value={selectedElement}
                            filterOption={(input, option) =>
                              option.children[0]
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {characterlist.map((character, index) => (
                              <Option key={character.id} value={character.id}>
                                {character.firstname} {character.lastname}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="cardBtnRight">
                          {selectedElement ? (
                            <>
                              <Tooltip title="Link with No Date">
                                <Button onClick={() => save("Character")}>
                                  <CustIcon type="link"></CustIcon>
                                </Button>
                              </Tooltip>
                              <Tooltip title="Link with Date">
                                <Button onClick={selectDate}>
                                  <CustIcon type="date"></CustIcon>
                                </Button>
                              </Tooltip>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <Button
                        type="primary"
                        onClick={() => addElement("Character")}
                      >
                        New
                      </Button>
                    </div>
                  ) : null}

                  <div>
                    {characters.length > 0 ? (
                      <>
                        <div className="mb-2 font-medium">
                          Characters who were present at {event.name}:
                        </div>
                        {characters.map((obj) => (
                          <Card className="mb-2 cardGrey">
                            <Row className="mb-3" gutter={20}>
                              <Col md={12}>
                                <div className="d-flex align-items-center">
                                  <div className="col-auto p-0">
                                    {obj?.logo ? (
                                      <Avatar
                                        src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.logo}`}
                                      />
                                    ) : (
                                      <Avatar>
                                        {obj.firstname
                                          .charAt(0)
                                          .toUpperCase()
                                          .slice(0)}
                                      </Avatar>
                                    )}
                                  </div>
                                  <div className="col">
                                    <Link
                                      className="storyname"
                                      onClick={() =>
                                        updateStore(obj, "character")
                                      }
                                      to={{
                                        pathname: `/story/character/${obj.id}`,
                                      }}
                                    >
                                      {obj.firstname} {obj.lastname}
                                    </Link>{" "}
                                  </div>
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="cardBtnRight">
                                  <div
                                    className={`${
                                      isInvisible === "character-hide-" + obj.id
                                        ? "hideicon"
                                        : "hideicon showiconleft"
                                    }`}
                                  >
                                    <Tooltip title="Edit">
                                      <Button
                                        onClick={() =>
                                          editElement(obj.id, "Character")
                                        }
                                      >
                                        <CustIcon type="edit" />
                                      </Button>
                                    </Tooltip>
                                    <Popconfirm
                                      title={
                                        <>
                                          <div>
                                            This action will permanently delete
                                            your entity.
                                          </div>
                                          <div>
                                            Are you sure want to remove the
                                            character - <b>{obj.firstname}</b>?
                                          </div>
                                        </>
                                      }
                                      placement="topRight"
                                      onConfirm={() =>
                                        deleteElement(obj.id, "character")
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip title="Delete">
                                        <Button>
                                          <CustIcon
                                            className="deletepopover"
                                            type="delete"
                                          />
                                        </Button>
                                      </Tooltip>
                                    </Popconfirm>
                                    <Popconfirm
                                      title={
                                        <div>
                                          Are you sure want to unlink the
                                          character - <b>{obj.firstname}</b>?
                                        </div>
                                      }
                                      placement="topRight"
                                      onConfirm={() =>
                                        removeAssociation(obj.id, "character")
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip title="Unlink">
                                        <Button>
                                          <CustIcon type="unlink" />
                                        </Button>
                                      </Tooltip>
                                    </Popconfirm>
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        toggleDiv(obj.id, "character", "hide")
                                      }
                                    >
                                      <CustIcon type="dots" />
                                    </Button>
                                  </div>
                                  <div
                                    className={`${
                                      isInvisible === "character-hide-" + obj.id
                                        ? "showicon"
                                        : "hideicon  hide"
                                    }`}
                                  >
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        toggleDiv(obj.id, "character", "show")
                                      }
                                    >
                                      <CustIcon type="dots" />
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <>
                              <div>{obj.description}</div>
                              <div className="mt-2 font-medium">
                                <DateComponent obj={obj.timeline} />
                              </div>
                            </>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <Empty description={<span>No character added</span>} />
                    )}
                  </div>
                </Card>
                <Card
                  className="cardBox mb-4"
                  title="Items"
                  extra={
                    <Button
                      type="link"
                      size="small"
                      onClick={() => showElement("Item")}
                    >
                      <PlusOutlined />
                      Add or Link New
                    </Button>
                  }
                >
                  {displayElement && elementType === "Item" ? (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <div className="cardSelect">
                          <Select
                            showSearch
                            placeholder="Select an existing item"
                            allowClear={true}
                            onClear={onClear}
                            optionFilterProp="name"
                            onChange={(e) => onChange(e, "Item")}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {itemlist.map((item, index) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="cardBtnRight">
                          {selectedElement ? (
                            <>
                              <Tooltip title="Link with No Date">
                                <Button onClick={() => save("Item")}>
                                  <CustIcon type="link"></CustIcon>
                                </Button>
                              </Tooltip>
                              <Tooltip title="Link with Date">
                                <Button onClick={selectDate}>
                                  <CustIcon type="date"></CustIcon>
                                </Button>
                              </Tooltip>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <Button type="primary" onClick={() => addElement("Item")}>
                        New
                      </Button>
                    </div>
                  ) : null}
                  <div>
                    {items.length > 0 ? (
                      <>
                        <div className="mb-2 font-medium">
                          Items used in {event.name}:
                        </div>
                        {items.map((obj) => (
                          <Card className="mb-2 cardGrey">
                            <Row className="mb-3" gutter={20}>
                              <Col md={12}>
                                <div className="d-flex align-items-center">
                                  <div className="col-auto p-0">
                                    {obj?.logo ? (
                                      <Avatar
                                        src={`${REACT_APP_NHOST_STORAGE_URL}/files/${obj.logo}`}
                                      />
                                    ) : (
                                      <Avatar>
                                        {obj.name
                                          .charAt(0)
                                          .toUpperCase()
                                          .slice(0)}
                                      </Avatar>
                                    )}
                                  </div>
                                  <div className="col">
                                    <Link
                                      className="storyname"
                                      onClick={() => updateStore(obj, "item")}
                                      to={{
                                        pathname: `/story/item/${obj.id}`,
                                      }}
                                    >
                                      {obj.name}
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="cardBtnRight">
                                  <div
                                    className={`${
                                      isInvisible === "item-hide-" + obj.id
                                        ? "hideicon"
                                        : "hideicon showiconleft"
                                    }`}
                                  >
                                    <Tooltip title="Edit">
                                      <Button
                                        onClick={() =>
                                          editElement(obj.id, "Item")
                                        }
                                      >
                                        <CustIcon type="edit" />
                                      </Button>
                                    </Tooltip>
                                    <Popconfirm
                                      title={
                                        <>
                                          <div>
                                            This action will permanently delete
                                            your entity.
                                          </div>
                                          <div>
                                            Are you sure want to remove the item
                                            - <b>{obj.name}</b>?
                                          </div>
                                        </>
                                      }
                                      placement="topRight"
                                      onConfirm={() =>
                                        deleteElement(obj.id, "item")
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip title="Delete">
                                        <Button>
                                          <CustIcon
                                            className="deletepopover"
                                            type="delete"
                                          />
                                        </Button>
                                      </Tooltip>
                                    </Popconfirm>
                                    <Popconfirm
                                      title={
                                        <div>
                                          Are you sure want to unlink the item -
                                          <b>{obj.name}</b>?
                                        </div>
                                      }
                                      placement="topRight"
                                      onConfirm={() =>
                                        removeAssociation(obj.id, "item")
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip title="Unlink">
                                        <Button>
                                          <CustIcon type="unlink" />
                                        </Button>
                                      </Tooltip>
                                    </Popconfirm>
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        toggleDiv(obj.id, "item", "hide")
                                      }
                                    >
                                      <CustIcon type="dots" />
                                    </Button>
                                  </div>
                                  <div
                                    className={`${
                                      isInvisible === "item-hide-" + obj.id
                                        ? "showicon"
                                        : "hideicon  hide"
                                    }`}
                                  >
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        toggleDiv(obj.id, "item", "show")
                                      }
                                    >
                                      <CustIcon type="dots" />
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <>
                              <div>{obj.description}</div>
                              <div className="mt-2 font-medium">
                                {" "}
                                <DateComponent obj={obj.timeline} />
                              </div>
                              {obj?.timeline ? (
                                <div>
                                  From:{" "}
                                  {obj?.timeline?.from_month ? (
                                    <>{obj.timeline.from_month}</>
                                  ) : (
                                    "Day"
                                  )}{" "}
                                  {obj?.timeline?.from_day && (
                                    <>{obj.timeline.from_day}</>
                                  )}
                                  {obj?.timeline?.from_year && (
                                    <>{", " + obj.timeline.from_year}</>
                                  )}{" "}
                                  {obj?.timeline?.to_day ||
                                  obj?.timeline?.to_month ||
                                  obj?.timeline?.to_year ? (
                                    <>{"To:"}</>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {obj?.timeline?.to_month ? (
                                    <>{obj.timeline.to_month}</>
                                  ) : (
                                    "Day"
                                  )}{" "}
                                  {obj?.timeline?.to_day && (
                                    <>{obj.timeline.to_day}</>
                                  )}
                                  {obj?.timeline?.to_year && (
                                    <>{", " + obj.timeline?.to_year}</>
                                  )}
                                </div>
                              ) : null}
                            </>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <Empty description={<span>No item added</span>} />
                    )}
                  </div>
                </Card>
              </Col>
            </>
          ) : (
            <>
              <Col md={8}>
                <Card className="mb-3">
                  <Skeleton active />
                </Card>
              </Col>
              <Col md={16}>
                <Card className="mb-3">
                  <Skeleton avatar active />
                </Card>
                <Card className="mb-3">
                  <Skeleton avatar active />
                </Card>
                <Card className="mb-3">
                  <Skeleton avatar active />
                </Card>
              </Col>
            </>
          )}
        </Row>
      </div>

      {showModal && (
        <AddElementComponent
          client={client}
          showModal={showModal}
          closeModal={closeModal}
          btnloading={btnloading}
          setBtnLoading={(e) => setBtnLoader(e)}
          storyId={parseInt(story.id)}
          eventId={parseInt(eventStore.id)}
          elementType={elementType}
          pagetype="event"
          characters={characterslist.map((item) => ({
            ...item,
            label: item.firstname + " " + item.lastname,
            value: item.id,
          }))}
          locations={locationslist.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))}
          event={event}
          events={events.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))}
          items={itemslist.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))}
          timelines={timelinelist.map((item) => ({
            ...item,
            label: (
              <>
                {item?.from_day && <>{item.from_day}</>}
                {item?.from_month && <>{" " + item.from_month}</>}
                {item?.from_year && <>{"," + item.from_year}</>}{" "}
                {item?.to_day || item?.to_month || item?.to_year ? (
                  <>{" - "}</>
                ) : (
                  ""
                )}{" "}
                {item?.to_day && <>{item.to_day}</>}
                {item?.to_month && <>{" " + item.to_month}</>}
                {item?.to_year && <>{"," + item.to_year}</>}
              </>
            ),
            value: item.id,
          }))}
          onFinishSaveElement={(e) => onFinishSaveElement(e)}
        />
      )}
      {editShowModal && (
        <EditElementComponent
          client={client}
          editShowModal={editShowModal}
          closeModal={closeModal}
          btnloading={btnloading}
          setBtnLoading={(e) => setBtnLoader(e)}
          elementId={selectedElement}
          elementType={elementType}
          onFinishSaveElement={(e) => onFinishSaveElement(e)}
        />
      )}
      {showTimelineModal && (
        <AddTimeline
          client={client}
          showTimelineModal={showTimelineModal}
          closeModal={closeModal}
          closeTimelineModal={closeTimelineModal}
          storyId={parseInt(story.id)}
          eventId={parseInt(eventStore.id)}
          elementType={elementType}
          pageType="EventPage"
          timelines={timelinelist}
          selectedElement={selectedElement}
          onFinishSaveElement={(e) => onFinishSaveElement(e)}
        />
      )}
    </React.Fragment>
  );
};

export default EventDetail;
