import create from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set, get) => ({
      story: { id: 0, name: "" },
      characterStore: { id: 0, firstname: "", lastname: "" },
      locationStore: { id: 0, name: "" },
      eventStore: { id: 0, name: "" },
      itemStore: { id: 0, name: "" },
      historyStore: { id: 0, type: "" },
      addStoryDetail: (story) =>
        set((state) => ({
          story: story,
        })),
      addCharacterDetail: (character) =>
        set((state) => ({
          characterStore: character,
        })),
      addLocationDetail: (location) =>
        set((state) => ({
          locationStore: location,
        })),
      addEventDetail: (event) =>
        set((state) => ({
          eventStore: event,
        })),
      addItemDetail: (item) =>
        set((state) => ({
          itemStore: item,
        })),
      addHistoryDetail: (history) =>
        set((state) => ({
          historyStore: history,
        })),
    }),
    {
      name: "jebidu-storage", // unique name
      getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    }
  )
);
export default useStore;
