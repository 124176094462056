import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import { Row, Col, Form, Input, Button, Card, message } from "antd";
import { changePassword } from "../../apis/rest";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("last_visited_url", window.location.pathname);
  });

  const cancel = () => {
    navigate("/my-profile");
  };
  const formRef = React.createRef();
  const onFinish = (values) => {
    setLoading(true);
    try {
      let changePwdModel = {
        newPassword: values.new_password,
      };

      changePassword(changePwdModel)
        .then(() => {
          message.success("Password changes successfully.");
          setLoading(false);

          navigate("/my-profile");
        })
        .catch((err) => {
          console.log("ERROR->", err);
          message.error(
            (err &&
              err.response &&
              err.response.data &&
              err.response.data.message) ||
              "Password change failed."
          );
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong please try again later");
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <Card className="commonBox changePassword" title="Change Password">
          <Row>
            <Col lg={10} md={14} xs={24}>
              <Form
                layout="vertical"
                ref={formRef}
                className="myProfileForm"
                onFinish={onFinish}
              >
                <div className="onFinishmyProfileDetails">
                  <Form.Item
                    type="password"
                    label="New Password"
                    name="new_password"
                    rules={[
                      { required: true, message: "Please enter password" },
                      {
                        min: 6,
                        max: 20,
                        message:
                          "New password must be between 6 to 20 characters",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter New Password"
                      className="border-0 p-0 passwordInput"
                    />
                  </Form.Item>
                  <Form.Item
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={["new_password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter confirm password",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue("new_password") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The passwords are not matching"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter New Password"
                      className="border-0 p-0 passwordInput"
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                  <Button key="back" onClick={cancel} size="large">
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
