import React from "react";
import { Layout } from "antd";
import "./FooterPage.css";
import { Link } from "react-router-dom";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ textAlign: "center" }}>
      <div className="container">
        <div>
          Copyright © 2022, Jebidu | Version 1.0.1 |{" "}
          <a
            href="https://www.jebidu.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy |{" "}
          </a>
          <a
            href="https://www.jebidu.com/tos"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
