import { HistoryOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import { Button, Card, Col, Empty, message, Popconfirm, Row, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "../../Components/Loader/Loader";
import { SubHeader } from "../../Components/SubHeaderPage";
import {
  GET_ALL_HISTORIES,
  UPDATE_CHARACTER_HISTORY_BY_ID,
  UPDATE_EVENT_HISTORY_BY_ID,
  UPDATE_ITEM_HISTORY_BY_ID,
  UPDATE_LOCATION_HISTORY_BY_ID,
  UPDATE_STORY_HISTORY_BY_ID,
} from "../../graphql/APIs";
import useStore from "../../utils/store";
import "./History.css";

const History = () => {
  const [loaded, setLoaded] = useState(false);
  const [history, setHistory] = useState([]);
  const [entityType, setEntityType] = useState("");
  const client = useApolloClient();
  const location = useLocation();
  const { historyStore } = useStore();

  const getData = () => {
    client
      .query({
        query: GET_ALL_HISTORIES,
        variables: {
          id: parseInt(historyStore.id),
          entity_type: historyStore.type,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        setHistory(data.history);
        setLoaded(true);
      })
      .catch((error, result) => {
        setLoaded(true);
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };
  useEffect(() => {
    localStorage.setItem("last_visited_url", location.pathname);
    setEntityType(historyStore.type);
    getData();
  }, []);

  const handleRestore = async (record) => {
    setLoaded(true);
    try {
      switch (historyStore.type) {
        case "story":
          await client.mutate({
            mutation: UPDATE_STORY_HISTORY_BY_ID,
            variables: {
              id1: record.id,
              id: parseInt(historyStore.id),
              entity_type: historyStore.type,
              logo: record.logo,
              description: record.description,
              name: record.name,
            },
          });
          break;
        case "character":
          await client.mutate({
            mutation: UPDATE_CHARACTER_HISTORY_BY_ID,
            variables: {
              id1: record.id,
              id: parseInt(historyStore.id),
              entity_type: historyStore.type,
              logo: record.logo,
              description: record.description,
              firstname: record.firstname,
              lastname: record.lastname,
            },
          });
          break;
        case "location":
          await client.mutate({
            mutation: UPDATE_LOCATION_HISTORY_BY_ID,
            variables: {
              id1: record.id,
              id: parseInt(historyStore.id),
              entity_type: historyStore.type,
              logo: record.logo,
              description: record.description,
              name: record.name,
            },
          });
          break;
        case "event":
          await client.mutate({
            mutation: UPDATE_EVENT_HISTORY_BY_ID,
            variables: {
              id1: record.id,
              id: parseInt(historyStore.id),
              entity_type: historyStore.type,
              logo: record.logo,
              description: record.description,
              name: record.name,
            },
          });
          break;
        case "item":
          await client.mutate({
            mutation: UPDATE_ITEM_HISTORY_BY_ID,
            variables: {
              id1: record.id,
              id: parseInt(historyStore.id),
              entity_type: historyStore.type,
              logo: record.logo,
              description: record.description,
              name: record.name,
            },
          });
          break;
        default:
      }
      setHistory([]);
      getData();
      setLoaded(false);
      message.success("Version Restored!");
    } catch (error) {
      if (error) {
        message.error("Something went wrong!");
        setLoaded(false);
      }
    }
  };
  return (
    <React.Fragment>
      <SubHeader />
      {loaded ? (
        <div className="container">
          <div type="all" title="History"></div>
          <Card className="cardBox" title="History">
            {history.length > 0 ? (
              <>
                {history.map((record, index) => (
                  <Card className="mb-2 cardGrey">
                    <Row className="mb-2" gutter={20}>
                      <Col md={12}>
                        {entityType === "character" ? (
                          <>
                            <div className="storyname">
                              {record.firstname +
                                " " +
                                (record.lastname ? record.lastname : "")}
                            </div>
                          </>
                        ) : (
                          <div className="storyname">{record.name}</div>
                        )}
                      </Col>
                      <Col md={12}>
                        <div className="d-flex align-items-center justify-content-end">
                          {!index ? (
                            <Tag className="tagGreen">Latest</Tag>
                          ) : (
                            <>
                              {entityType === "character" ? (
                                <Popconfirm
                                  title={`Are you sure want to restore the character - ${
                                    record.firstname +
                                    (record.lastname
                                      ? " " + record.lastname
                                      : "")
                                  }?`}
                                  placement="topRight"
                                  onConfirm={() => handleRestore(record)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button size="small">
                                    <HistoryOutlined className="mr-1" />
                                    <span>Restore</span>
                                  </Button>
                                </Popconfirm>
                              ) : (
                                <Popconfirm
                                  title={`Are you sure want to restore the ${entityType} - ${record.name}?`}
                                  placement="topRight"
                                  onConfirm={() => handleRestore(record)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button size="small">
                                    <HistoryOutlined className="mr-1" />
                                    <span>Restore</span>
                                  </Button>
                                </Popconfirm>
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="">{record.description}</div>
                    <div className="mt-2 font-medium">
                      <span>
                        {moment(new Date(record.updated_at)).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </span>
                    </div>
                  </Card>
                ))}
              </>
            ) : (
              <Card>
                <Empty description={<span>No data available</span>} />
              </Card>
            )}
          </Card>
        </div>
      ) : !history.length ? (
        <Card>
          <Loader />
        </Card>
      ) : (
        <Card>
          <Empty description={<span>No data available</span>} />
        </Card>
      )}
    </React.Fragment>
  );
};

export default History;
