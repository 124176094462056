import { Breadcrumb, Button, message } from "antd";
import React from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import useStore from "../../utils/store";
import "./SubHeaderPage.css";
const { REACT_APP_STORY_LIMIT } = process.env;

const SubHeader = ({ count }) => {
  const { isSubscribed } = useOutletContext();
  let navigate = useNavigate();
  let location = useLocation();
  const { story, characterStore, locationStore, eventStore, itemStore } =
    useStore();

  const checkStoryCount = () => {
    if (isSubscribed || count < REACT_APP_STORY_LIMIT) {
      navigate("/add-story");
    } else
      message.warning(
        "You can create upto 2 stories in FREE account. Upgrade to Pro for unlimited access."
      );
  };

  const redirectTo = (link) => {
    link && navigate(link);
  };

  return (
    <React.Fragment>
      <div className="topMenu">
        <div className="container">
          <div className="topMenu_blk d-flex flex-xl-row flex-column align-items-xl-center justify-content-xl-between">
            <div className="d-flex justify-content-between align-items-center w-100">
              <Breadcrumb separator=">">
                <Breadcrumb.Item onClick={() => redirectTo("/stories")}>
                  My Stories
                </Breadcrumb.Item>
                {location.pathname.search("story") > -1 && (
                  <>
                    <Breadcrumb.Item
                      onClick={() => redirectTo(`/story/${story.id}`)}
                    >
                      {story.name}
                    </Breadcrumb.Item>
                    {location.pathname.search("character") > -1 && (
                      <Breadcrumb.Item
                        onClick={() =>
                          redirectTo(
                            location.pathname.search("history") > -1
                              ? `/story/character/${characterStore.id}`
                              : null
                          )
                        }
                      >
                        {characterStore.firstname +
                          (characterStore.lastname
                            ? " " + characterStore.lastname
                            : "")}
                      </Breadcrumb.Item>
                    )}
                    {location.pathname.search("event") > -1 && (
                      <Breadcrumb.Item
                        onClick={() =>
                          redirectTo(
                            location.pathname.search("history") > -1
                              ? `/story/event/${eventStore.id}`
                              : null
                          )
                        }
                      >
                        {eventStore.name}
                      </Breadcrumb.Item>
                    )}
                    {location.pathname.search("location") > -1 && (
                      <Breadcrumb.Item
                        onClick={() =>
                          redirectTo(
                            location.pathname.search("history") > -1
                              ? `/story/location/${locationStore.id}`
                              : null
                          )
                        }
                      >
                        {locationStore.name}
                      </Breadcrumb.Item>
                    )}
                    {location.pathname.search("item") > -1 && (
                      <Breadcrumb.Item
                        onClick={() =>
                          redirectTo(
                            location.pathname.search("history") > -1
                              ? `/story/item/${itemStore.id}`
                              : null
                          )
                        }
                      >
                        {itemStore.name}
                      </Breadcrumb.Item>
                    )}
                    {location.pathname.search("history") > -1 && (
                      <Breadcrumb.Item>History</Breadcrumb.Item>
                    )}
                    {location.pathname.search("timeline") > -1 && (
                      <Breadcrumb.Item>Timeline</Breadcrumb.Item>
                    )}
                  </>
                )}
              </Breadcrumb>

              {location.pathname === "/stories" && (
                <Button
                  onClick={checkStoryCount}
                  type="primary"
                  size="large"
                  className="tour-navbar-add-new-request"
                >
                  Add New Story
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubHeader;
