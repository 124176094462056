import { useApolloClient } from "@apollo/client";
import {
  Card,
  Col,
  Empty,
  Input,
  message,
  notification,
  Pagination,
  Popconfirm,
  Row,
  Skeleton,
  Table,
  Tooltip,
  Upload,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { uploadImage } from "../../apis/rest";
import { SubHeader } from "../../Components/SubHeaderPage";
import { CustIcon } from "../../Components/Svgs/Svgs";
import {
  DELETE_STORY,
  GET_ALL_STORIES,
  GET_STORIES_ENTITIES_COUNTS,
  UPDATE_STORY_LOGO,
} from "../../graphql/APIs";
import useStore from "../../utils/store";
import "./MyStories.css";

// const { REACT_APP_STORY_LIMIT, REACT_APP_HB_URL, REACT_APP_ENTITY_LIMIT } =
//   process.env;
const { REACT_APP_STORY_LIMIT, REACT_APP_NHOST_STORAGE_URL, REACT_APP_ENTITY_LIMIT } = process.env;

const { Column } = Table;
const Search = Input.Search;

const MyStories = () => {
  const { isSubscribed } = useOutletContext();
  const [loaded, setLoaded] = useState(false);
  const [searchText, setSearchText] = useState("%%");
  const [totalStories, setTotalStories] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [stories, setStories] = useState([]);
  const [userStory_aggregate, setUserStory_aggregate] = useState([]);
  const [entityCounts, setEntityCounts] = useState(0);
  const pageSize = 10;
  const storyLimit = REACT_APP_STORY_LIMIT;
  const entityLimit = REACT_APP_ENTITY_LIMIT;
  const client = useApolloClient();
  const addStoryDetail = useStore((state) => state.addStoryDetail);

  const updateStore = (story) => {
    addStoryDetail({ id: story.id, name: story.name });
  };

  const getData = () => {
    client
      .query({
        query: GET_ALL_STORIES,
        variables: {
          search: `%${searchText}%`,
          offset: (currentPage - 1) * pageSize,
          limit: pageSize,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        setStories(data.userStory);
        setUserStory_aggregate(data.userStory_aggregate.aggregate);
        setTotalStories(data.userStory_aggregate.aggregate.count);
        setLoaded(true);

        let storyIds = data.userStory.map((m) => m.story.id);
        getStoryEntityCounts(storyIds);
      })
      .catch((error, result) => {
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };

  const getStoryEntityCounts = (storyIds) => {
    client
      .query({
        query: GET_STORIES_ENTITIES_COUNTS,
        variables: {
          storyId: storyIds,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        let {
          storyCharacters_aggregate: { characters },
          storyEvents_aggregate: { events },
          storyItems_aggregate: { items },
          storyLocations_aggregate: { locations },
        } = data;
        setEntityCounts(
          ((characters && characters.count) || 0) +
            ((events && events.count) || 0) +
            ((items && items.count) || 0) +
            ((locations && locations.count) || 0)
        );
      })
      .catch((error, result) => {
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };

  useEffect(() => {
    localStorage.setItem("last_visited_url", window.location.pathname);
    setLoaded(false);
    getData();
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [searchText]);

  const handleDelete = (record) => {
    setLoaded(false);
    try {
      client.mutate({
        mutation: DELETE_STORY,
        variables: { id: record.story.id },

        update: (store, { data }) => {
          const deletedStoryId = data.delete_story_by_pk.id;

          let temp = store.readQuery({
            query: GET_ALL_STORIES,
            variables: {
              search: `%${searchText}%`,
              offset: (currentPage - 1) * pageSize,
              limit: pageSize,
            },
          });
          let temp1 = {
            userStory: [],
            userStory_aggregate: temp.userStory_aggregate,
            totalStories: temp.totalStories,
          };
          temp1.userStory = temp.userStory.filter((record) => {
            return record.story.id !== deletedStoryId;
          });
          temp1.totalStories.aggregate.count =
            temp.totalStories.aggregate.count - 1;
          temp1.userStory_aggregate.aggregate.count =
            temp.userStory_aggregate.aggregate.count - 1;
          store.writeQuery({
            query: GET_ALL_STORIES,
            variables: {
              search: `%${searchText}%`,
              offset: (currentPage - 1) * pageSize,
              limit: pageSize,
            },
            data: temp1,
          });
          setStories(temp1.userStory);
          setTotalStories(temp1.userStory_aggregate.aggregate.count);
          setLoaded(true);
          let storyIds = temp1.userStory.map((m) => m.story.id);
          getStoryEntityCounts(storyIds);
        },
      });
      message.success("Story Deleted Successfully");
    } catch (error) {
      if (error) {
        message.error("Something went wrong!");
      }
    }
  };

  const onSearchText = (value) => {
    setSearchText("%" + value.trim() + "%");
  };

  const handlePageChange = (pagenumber) => {
    setSearchText(`%${searchText}%`);
    setCurrentPage(pagenumber);
  };
  const handleUpload = async (props, record) => {
    const { onSuccess, onError, file } = props;

    try {
      if (file.size / (1024 * 1000) <= 2 && file.type.includes("image")) {
        var options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { fileMetadata } = await uploadImage(file, options);
        onSuccess(null, file);
        await client.mutate({
          mutation: UPDATE_STORY_LOGO,
          variables: {
            id: record.story.id,
            logo: fileMetadata.id,
          },
          update: (store, { data }) => {
            let temp = store.readQuery({
              query: GET_ALL_STORIES,
              variables: {
                search: `%${searchText}%`,
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
              },
            });
            let temp1 = {
              userStory: [],
              userStory_aggregate: temp.userStory_aggregate,
              totalStories: temp.totalStories,
            };
            temp1.userStory = temp.userStory.filter((obj) => {
              if (record.story.id === obj.story.id) {
                record.story.logo = fileMetadata.id;
              }
              return record;
            });
            store.writeQuery({
              query: GET_ALL_STORIES,
              variables: {
                search: `%${searchText}%`,
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
              },
              data: temp1,
            });

            setStories(temp1.userStory);
            setTotalStories(temp1.userStory_aggregate.aggregate.count);
            setLoaded(true);
          },
        });
      } else return message.error("Either file type or size is invalid.");
      notification.success({ message: "Logo updated" });
    } catch (err) {
      console.log({ err });
      onError(err.message, err.message, file);
    }
  };

  return (
    <React.Fragment>
      <SubHeader count={totalStories} />

      <div className="container">
        {loaded ? (
          <Card className="commonBox">
            <div type="all" title="My Stories"></div>
            <Row className="align-items-center mb-3" gutter={20}>
              <Col sm={12}>
                <div className="countSection_blk pt-1">
                  <p className="mb-0">
                    <strong className="pr-1">Stories:</strong>
                    {isSubscribed ? (
                      <span>{totalStories}</span>
                    ) : (
                      <span>
                        {totalStories}/{storyLimit}
                      </span>
                    )}
                  </p>
                  <p className="mb-0">
                    <strong className="pr-1">Total Entities:</strong>
                    {isSubscribed ? (
                      <span>{entityCounts}</span>
                    ) : (
                      <span>
                        {entityCounts}/{entityLimit}
                      </span>
                    )}
                  </p>
                </div>
              </Col>
              <Col sm={12}>
                <div className="myStorySearch_blk">
                  <Search
                    placeholder="Search Stories"
                    onSearch={onSearchText}
                    onChange={(e) => onSearchText(e.target.value.toLowerCase())}
                    allowClear
                    enterButton="Search"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {stories.length > 0 ? (
                  <>
                    <Table
                      pagination={false}
                      bordered={true}
                      dataSource={stories}
                      loading={!loaded}
                    >
                      <Column
                        title="Name"
                        key="name"
                        render={(name, record) => (
                          <React.Fragment>
                            <Row
                              className="align-items-center"
                              gutter={10}
                              wrap={false}
                            >
                              <Col flex="none">
                                <Upload
                                  className="profile-upload"
                                  id="logo"
                                  multiple={false}
                                  showUploadList={false}
                                  customRequest={(e) => handleUpload(e, record)}
                                >
                                  {record?.story?.logo ? (
                                    <Avatar
                                      src={`${REACT_APP_NHOST_STORAGE_URL}/files/${record?.story?.logo}`}
                                    />
                                  ) : (
                                    <Avatar>
                                      {record.story.name
                                        .charAt(0)
                                        .toUpperCase()
                                        .slice(0)}
                                    </Avatar>
                                  )}
                                  <CustIcon type="upload-alt" />
                                </Upload>
                              </Col>
                              <Col flex="auto">
                                <Link
                                  className="textellipsis"
                                  onClick={() => updateStore(record.story)}
                                  to={{
                                    pathname: `/story/${record.story.id}`,
                                  }}
                                >
                                  {record.story.name}
                                </Link>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      />
                      <Column
                        title="Last Edited"
                        key="updatedDate"
                        render={(updatedDate, record) => (
                          <React.Fragment>
                            {moment(new Date(record.story.updated_at)).format(
                              "MMM DD, YYYY"
                            )}
                          </React.Fragment>
                        )}
                      />
                      <Column
                        width="20%"
                        title="Actions"
                        key="action"
                        render={(action, record) => (
                          <div className="d-flex align-items-center tableBtn">
                            <Tooltip title="View Story">
                              <Link
                                className="ant-btn"
                                onClick={() => updateStore(record.story)}
                                to={{
                                  pathname: `/story/${record.story.id}`,
                                }}
                              >
                                <CustIcon type="eye"></CustIcon>
                              </Link>
                            </Tooltip>
                            <Popconfirm
                              title={
                                <>
                                  <div>
                                    This action will permanently delete your
                                    story.{" "}
                                  </div>
                                  <div>
                                    Are you sure want to remove the story -
                                    <b>{record.story.name}</b>?
                                  </div>
                                </>
                              }
                              placement="topRight"
                              onConfirm={() => handleDelete(record)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title="Delete Story">
                                <span className="ant-btn">
                                  <CustIcon type="delete" />
                                </span>
                              </Tooltip>
                            </Popconfirm>
                          </div>
                        )}
                      />
                    </Table>
                    {userStory_aggregate.count > pageSize && (
                      <Pagination
                        className="float-right mt-3"
                        defaultCurrent={currentPage}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        total={userStory_aggregate.count}
                      />
                    )}
                  </>
                ) : (
                  <Card className="mt-3">
                    <Empty description={<span>No Stories Available</span>} />
                  </Card>
                )}
              </Col>
            </Row>
          </Card>
        ) : (
          <Skeleton active />
        )}
      </div>
    </React.Fragment>
  );
};
export default MyStories;
