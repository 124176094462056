import { NhostReactProvider } from "@nhost/react";
import { NhostApolloProvider } from "@nhost/react-apollo";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { nhost } from "../src/Jebidu/utils/nhostclient";
import "./App.css";
import { Login } from "./Jebidu/Auth/Login/Login";
import { SignUp } from "./Jebidu/Auth/Login/SignUp";
import AddNewStory from "./Jebidu/Pages/AddStory/AddNewStory";
import ChangeEmail from "./Jebidu/Pages/ChangeEmail/ChangeEmail";
import ChangePassword from "./Jebidu/Pages/ChangePassword/ChangePassword";
import CharacterDetail from "./Jebidu/Pages/CharacterDetail/CharacterDetail";
import EditStory from "./Jebidu/Pages/EditStory/EditStory";
import EventDetail from "./Jebidu/Pages/EventDetail/EventDetail";
import History from "./Jebidu/Pages/History/History";
import ItemDetail from "./Jebidu/Pages/ItemDetail/ItemDetail";
import LocationDetail from "./Jebidu/Pages/LocationDetail/LocationDetail";
import MyProfile from "./Jebidu/Pages/MyProfile/MyProfile";
import MyStories from "./Jebidu/Pages/MyStories/MyStories";
import Payment from "./Jebidu/Pages/Payment/Payment";
import StoryCompleteView from "./Jebidu/Pages/StoryCompleteView/StoryCompleteView";
import Timelines from "./Jebidu/Pages/Timeline/Timeline";
import NotFound from "./Jebidu/Pages/NotFound/NotFound";
import { Template } from "./Jebidu/Template/Template";
import Protected from "./Protected";

function App() {
  return (
    <div className="App">
      <NhostReactProvider nhost={nhost}>
        <NhostApolloProvider nhost={nhost}>
          <AppRouter />
        </NhostApolloProvider>
      </NhostReactProvider>
    </div>
  );
}

function AppRouter() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Template />}>
        <Route
          path="stories"
          element={
            <Protected>
              <MyStories />
            </Protected>
          }
        />
        <Route
          path="add-story"
          element={
            <Protected>
              <AddNewStory />
            </Protected>
          }
        />
        <Route
          path="my-profile"
          element={
            <Protected>
              <MyProfile />
            </Protected>
          }
        />
        <Route path="story">
          <Route
            path=":storyId"
            element={
              <Protected>
                <EditStory />
              </Protected>
            }
          />
          <Route path="character">
            <Route
              path=":charid"
              element={
                <Protected>
                  <CharacterDetail />
                </Protected>
              }
            />
          </Route>
          <Route path="event">
            <Route
              path=":eventid"
              element={
                <Protected>
                  <EventDetail />
                </Protected>
              }
            />
          </Route>
          <Route path="location">
            <Route
              path=":locationid"
              element={
                <Protected>
                  <LocationDetail />
                </Protected>
              }
            />
          </Route>
          <Route path="item">
            <Route
              path=":itemid"
              element={
                <Protected>
                  <ItemDetail />
                </Protected>
              }
            />
          </Route>
          <Route path="timeline">
            <Route
              path=":id"
              element={
                <Protected>
                  <Timelines />
                </Protected>
              }
            />
          </Route>
          <Route path="history">
            <Route path=":type">
              <Route
                path=":typeid"
                element={
                  <Protected>
                    <History />
                  </Protected>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route path="viewstory">
          <Route
            path=":storyId"
            element={
              <Protected>
                <StoryCompleteView />
              </Protected>
            }
          />
        </Route>
        <Route path="payment">
          <Route path=":status">
            <Route path=":sessionId" element={<Payment />} />
          </Route>
        </Route>
        <Route
          path="/change-password"
          element={
            <Protected>
              <ChangePassword />
            </Protected>
          }
        />
        <Route
          path="/change-email"
          element={
            <Protected>
              <ChangeEmail />
            </Protected>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
