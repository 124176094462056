import { InfoCircleOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Empty,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  cancelSubscriptionOnEnd,
  createPaymentSession,
  getStripeProducts,
  getSubscription,
} from "../../apis/rest";
import { GET_USER_DETAILS } from "../../graphql/APIs";
import "./MyProfile.css";

const { ENV } = process.env;
const MyProfile = () => {
  const [user, setUser] = useState({});
  const client = useApolloClient();

  const [isProductLoading, setIsProductLoading] = useState(false);
  const [stripeProducts, setStripeProducts] = useState(null);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isCancelLoader, setIsCancelLoader] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const [isSubscriptionload, setisSubscriptionload] = useState(true);

  const getSubscriptionDetail = async (id) => {
    setisSubscriptionload(true);
    let { data } = await getSubscription(id);
    setSubscriptionData(data?.data);
    setisSubscriptionload(false);
  };

  const getData = () => {
    client
      .query({
        query: GET_USER_DETAILS,
        fetchPolicy: "cache-first",
      })
      .then(({ data }) => {
        setUser(data.users[0]);
        if (
          data.users[0].userSubscription &&
          !data.users[0].userSubscription.isCancel
        ) {
          getSubscriptionDetail(data.users[0].userSubscription.subscriptionId);
        } else setisSubscriptionload(false);
      })
      .catch((error, result) => {
        console.log("ERROR", result);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          message.destroy();
          message.error(error.graphQLErrors[0].message);
        } else {
          console.log(error);
          message.destroy();
          message.error("Something went wrong please try again later");
        }
      });
  };

  const getProducts = async () => {
    let { data } = await getStripeProducts();
    setIsProductLoading(false);
    setStripeProducts(data.data);
  };

  const createStripeSession = async (item) => {
    let resp = await createPaymentSession({
      priceId: item.id,
      env: ENV,
    });
    console.log(resp);
    if (resp && resp.data && resp.data.data) {
      window.location = resp.data.data.url;
    }

    setIsCreatingSession(false);
  };
  const onCheckBoxChange = (e) => {};

  const onSubscribe = (e) => {
    setIsProductLoading(true);
    getProducts();
    setIsCreatingSession(false);
  };

  const onSelectProduct = (e, item) => {
    setIsCreatingSession(item.id);
    createStripeSession(item);
  };

  const cancelSubscription = async (subscriptionId) => {
    setIsCancelLoader(true);
    const response = await cancelSubscriptionOnEnd({
      subscriptionId: subscriptionId,
    });
    setSubscriptionData(response.data.data);
    setIsCancelLoader(false);
    message.success(
      "Subscription is updated with cancel request at the end of current period."
    );
  };

  useEffect(() => {
    localStorage.setItem("last_visited_url", window.location.pathname);
    getData();
  }, []);

  return (
    <React.Fragment>
      <div className="container">
        <Card className="myProfile_blk commonBox" title="My Account">
          <Row gutter={20}>
            <Col md={12}>
              <div className="d-flex mb-4">
                Display Name :{" "}
                <strong className="ml-1">{user.displayName}</strong>
              </div>
            </Col>
            {user && (
              <Col md={12} className="text-right">
                <Link to="/change-password" className="profilelink">
                  Change password
                </Link>
              </Col>
            )}
          </Row>
          <Row gutter={20}>
            <Col md={12}>
              <div className="d-flex mb-4">
                Email Address :<strong className="ml-1">{user?.email}</strong>
                {user?.userProviders?.length ? (
                  <span className="ml-1">
                    <Tooltip
                      title={`Change Email Address is not available for the users logged-in using ${user?.userProviders[0]?.providerId}`}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                ) : null}
              </div>
            </Col>
            {!user?.userProviders?.length ? (
              <Col md={12} className="text-right">
                <Link to="/change-email" className="profilelink">
                  Change Email Address
                </Link>
              </Col>
            ) : null}
          </Row>
          <div className="mb-4">
            <Checkbox onChange={onCheckBoxChange}>
              Email me updates about Jebidu
            </Checkbox>
          </div>
          {user &&
          !isSubscriptionload &&
          (!user.userSubscription || user.userSubscription.isCancel) ? (
            <React.Fragment>
              <div className="mb-4">
                <h3>Want More?</h3>
                <p className="mb-5">
                  Do you want to create unlimited stories and entities hassle
                  free. Upgrade your free account now to access the amazing
                  features and unravel the experience.
                </p>
                <Button
                  type="primary"
                  size="large"
                  loading={isProductLoading}
                  onClick={onSubscribe}
                >
                  Upgrade to Pro!
                </Button>
              </div>
              {stripeProducts && (
                <div>
                  <Row gutter={20}>
                    {stripeProducts.map((item, i) => {
                      return (
                        item.active && (
                          <Col
                            className="mb-3"
                            md={12}
                            key={item.productDetail.name + i}
                          >
                            <Card className="subscribecard">
                              <div className="row align-items-center">
                                <div className="col">
                                  <h5>{item.productDetail.name}</h5>
                                  <p>{item.productDetail.description}</p>
                                </div>
                                <div className="col-auto">
                                  <Button
                                    type="success"
                                    size="large"
                                    disabled={isCreatingSession}
                                    loading={isCreatingSession === item.id}
                                    onClick={(e) => onSelectProduct(e, item)}
                                  >
                                    Subscribe
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        )
                      );
                    })}
                  </Row>
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3>Subscription Information</h3>
              {subscriptionData ? (
                <Card className="subscribecard">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5>
                        {subscriptionData.plan.interval}ly{" "}
                        {subscriptionData.status === "active" ? (
                          <Tag className="tagGreen ml-2">Active</Tag>
                        ) : (
                          <>
                            {subscriptionData.status === "trialing" ? (
                              <Tag className="tagBlue ml-2">
                                Trial ends{" "}
                                {moment
                                  .unix(subscriptionData.trial_end)
                                  .format("MMM DD")}
                              </Tag>
                            ) : (
                              <Tag className="tagGray ml-2">Cancelled</Tag>
                            )}
                          </>
                        )}
                      </h5>
                      <p>
                        Billing {subscriptionData.plan.interval}ly •
                        {subscriptionData.status === "canceled" && (
                          <span> No Future Invoices</span>
                        )}
                        {subscriptionData.status !== "canceled" ? (
                          subscriptionData.cancel_at_period_end ? (
                            <span>
                              {" "}
                              Cancels on{" "}
                              {moment
                                .unix(subscriptionData.current_period_end)
                                .format("MMM DD, YYYY")}
                            </span>
                          ) : (
                            <span>
                              {" "}
                              Next Invoice on{" "}
                              {moment
                                .unix(subscriptionData.current_period_end)
                                .format("MMM DD, YYYY")}
                            </span>
                          )
                        ) : null}
                      </p>
                    </div>
                    <div className="col-auto">
                      {!subscriptionData.cancel_at_period_end &&
                        (subscriptionData.status === "active" ||
                          subscriptionData.status === "trialing") && (
                          <Popconfirm
                            title={
                              <>
                                <div>
                                  The subscription is going to cancel at the end
                                  of billing cycle.
                                </div>
                                <div>Are you sure want to proceed?</div>
                              </>
                            }
                            placement="topRight"
                            onConfirm={() =>
                              cancelSubscription(
                                user.userSubscription.subscriptionId
                              )
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              type="default"
                              size="large"
                              className="mr-2"
                              loading={isCancelLoader}
                            >
                              Cancel
                            </Button>
                          </Popconfirm>
                        )}
                    </div>
                  </div>
                </Card>
              ) : isSubscriptionload ? (
                <Skeleton active />
              ) : (
                <Empty description={<span>No data available</span>} />
              )}
            </React.Fragment>
          )}
        </Card>
      </div>
    </React.Fragment>
  );
};

export default MyProfile;
