import axios from "axios";
import { nhost } from "../utils/nhostclient";

const { REACT_APP_API_URL } = process.env;

const headers = () => {
  let token = getAccessToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
export const getAuthenticationStatus = () =>
  nhost.auth.getAuthenticationStatus();

export const getAccessToken = () => nhost.auth.getAccessToken();

export const getUser = () => nhost.auth.getUser();

export const getRefreshToken = (refreshToken) =>
  nhost.auth.getAccessToken({ refreshToken });

export const signup = (body) => nhost.auth.signUp(body);

export const login = (body) => nhost.auth.signIn(body);

export const changePassword = (body) => nhost.auth.changePassword(body);

export const changeEmailRequest = (email) =>
  nhost.auth.changeEmail({ newEmail: email });

export const uploadImage = (file, options) => nhost.storage.upload({ file });

export const deleteFile = (filePath) => axios.delete(filePath, headers());

//Sections Nhost BackEnd Apis
export const getStripeProducts = () =>
  axios.get(`${REACT_APP_API_URL}/stripe/get_products`, headers());

export const createPaymentSession = (body) =>
  axios.post(`${REACT_APP_API_URL}/stripe/create_session`, body, headers());

export const cancelSubscriptionOnEnd = (body) =>
  axios.post(
    `${REACT_APP_API_URL}/stripe/cancel_subscription`,
    body,
    headers()
  );

export const upgradeSubscription = (body) =>
  axios.post(
    `${REACT_APP_API_URL}/stripe/update_subscription`,
    body,
    headers()
  );

export const getSubscription = (id) =>
  axios.get(`${REACT_APP_API_URL}/stripe/get_subscription?id=${id}`, headers());
